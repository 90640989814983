import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { Button, Grid, Breadcrumbs, Typography,Paper,TextField} from '@material-ui/core';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { AssemblySelection, AirframeInitiate, APUInitiate, LGInitiate, EngineInitiate, RegionCustomVariable, LicenseFailModal } from '../components';
import { PageLoader, Map, GooglePlaces, CountryStateSelector } from '../../../shared_elements';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getAPUTypesAc, getAircraftTypesAc, getEngineTypesAc, getPlatformConstantsAc} from '../../../shared_elements/actionCreators';
import mapIcon from '../../../assets/images/map_icon.png';
import { getMntEventsApi, initiateMrLogApi, getRegionCustomVariablesApi, updateRegionMapsApi, geMRCalculatorUsageApi, getApuLgLimitApi , getEngineDerates} from '../apiServices';
import { aircraftGenInfo, engineGenInfo, lgGenInfo, apuGenInfo } from '../';
import { getLocalStorageInfo, checkApiStatus, setGlobalCookie, getGlobalCookie } from '../../../utils'
import { trackActivity } from '../../../utils/mixpanel'
import Skeleton from '@material-ui/lab/Skeleton';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { relativeLength } from 'highcharts';
class Inititate extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      licenseModal:false,
      licenseDesc:'',
      mntGroups:[],
      utilizationRatioList:[],
      stateFlagCheck:false,
      videoDialog:false,
      mrLogInfo:{
        metricsInfo:['airframe'],
        use_acumen_algorithm:true,
        airframeAndGen:aircraftGenInfo,
        apuAndGen:apuGenInfo,
        lgAndGen:lgGenInfo,
        engine: engineGenInfo
      },
      regionCondition:null,
      adjustRegionVar:false,
      error:{},
      address: '',
      location:{ lat: 12.9642373, lng: 77.6392131 },
      showMarker: true,
      regionDetails: null,
      mrCalculatorUsage:null,
      apuLgLimit:{
        apu:{limit:7000},
        lg:{limit:20000}
      },
      overhaulDateFlag:false,
      radioCheck:'dom',
      radioCheckLg:'dom',
      deRates:[]
    }
    this.getMntEventsApi = getMntEventsApi.bind(this);
    this.initiateMrLogApi = initiateMrLogApi.bind(this);
    this.getApuLgLimitApi = getApuLgLimitApi.bind(this);
    this.getRegionCustomVariablesApi = getRegionCustomVariablesApi.bind(this);
    this.updateRegionMapsApi = updateRegionMapsApi.bind(this);
    this.geMRCalculatorUsageApi = geMRCalculatorUsageApi.bind(this);
    this.getEngineDerates = getEngineDerates.bind(this);
  }
  componentDidMount(){
    this.props.getAircraftTypes(this.props, {'mr-calculator':'mr-calculator'})
    this.props.getEngineTypes({'mr-calculator':'mr-calculator'})
    this.props.getPlatformConstants();
    // this.props.emptyEnginesTyeps();
    if(getLocalStorageInfo().user.permission.mr_calculator && getLocalStorageInfo().user.permission.mr_calculator.is_saas){
      this.geMRCalculatorUsageApi(this.props);
    }
    trackActivity('Page Visited',{page_title: 'Generate MR Log'})
  }
  onChangeRegionCond = (value, index) => {
    if(this.state.adjustRegionVar){
      this.setState(prevState => ({
        ...prevState,
        regionCondition:{
          ...prevState.regionCondition,
          variable_condition:{
            ...prevState.regionCondition.variable_condition,
            [index]:{
              ...prevState.regionCondition.variable_condition[index],
              default:value
            }
          }
        }
      }))
    }
  }
  overhualFn = (e, val, assumedValue, tableType, limit) => {
    // commenting this as we might require this in future
    // let csnValue;
    // if(val > limit){
    //   csnValue = parseInt(val % limit)
    // }else {
    //   csnValue = val
    // }
    // let value = parseInt(csnValue/assumedValue * 30.44)
    // let currentDate = new Date()
    // let dateOfManufacture = currentDate.setDate(currentDate.getDate() - value)
    // this.onFieldChange(e, 'dateOfManufacture', moment(dateOfManufacture).format(backendDateFormat), tableType)
  }
  onFieldChange = (e, keyParam, value, tableType) => {
    const { mrLogInfo, regionDetails } = this.state;
    if(tableType){
      this.updateValueField(keyParam, value, tableType);
      if(keyParam === 'aircraftType'){
        this.updateValueField('maintenanceProgram', null, tableType);
        if(mrLogInfo.metricsInfo.includes('apu')){
          this.updateValueField('apuType', null, 'apuAndGen');
        }
        if(mrLogInfo.metricsInfo.includes('engine')){
          // this.updateValueField('engineType', null, 'engine');
          // this.updateValueField('utlizationRatio', null, 'engine');
          this.updateValueField('noOfEngine', 1, 'engine');
          this.updateValueField('regionOfOperation', null, 'engine');
          this.updateValueField('variable_condition_id', [], 'engine');
        }
        if(value){
          this.getMntEventsApi(this.props, value.id);
          this.props.getAPUTypes({'aircraft-type':value.id});
          this.getApuLgLimitApi(value.id)
          if(mrLogInfo.metricsInfo.includes('engine')){
            this.props.getEngineTypes({aircraft_type:value.id, 'mr-calculator':'mr-calculator'});
          }
        }else{
          this.setState({mntGroups:[]});
          if(mrLogInfo.metricsInfo.includes('engine')){
            // this.props.emptyEnginesTyeps();
            this.props.getEngineTypes({'mr-calculator':'mr-calculator'})
          }
        }
      }
    // if(!mrLogInfo.metricsInfo.includes('engine')){
    //   this.setState({
    //     regionCondition:null,
    //   })
    //   this.updateValueField('regionOfOperation', null, 'engine');
    // }
      if(keyParam == "utilization_hours"){
        this.updateValueField('utilization_hours', value, tableType)
        this.updateValueField('utilization_cycles', parseInt(value / mrLogInfo.engine.utlizationRatio.value), tableType)
      }
      if(tableType == "airframeAndGen" &&  keyParam == "dateOfManufacture") {
        if(!mrLogInfo.apuAndGen.autopopulate){
          this.updateValueField('dateOfLastOverhual', moment(value).format(backendDateFormat), 'apuAndGen')
        }
        if(!mrLogInfo.lgAndGen.autopopulate){
          this.updateValueField('dateOfLastOverhual', moment(value).format(backendDateFormat), 'lgAndGen')
        }
      }
      if(keyParam == "utlizationRatio") {
        this.updateValueField('utlizationRatio', value, tableType)
        this.updateValueField('utilization_cycles', parseInt(mrLogInfo.engine.utilization_hours / value.value), tableType)
      }
      if(keyParam === 'engineType'){
        this.updateValueField('variable_condition_id', [], 'engine');
        this.setState(prevState => ({
          ...prevState,
          regionCondition:null
        }))
        if(value){
          this.props.getAircraftTypes(this.props,{'mr-calculator': 'mr-calculator', engine_type:value.id})
          let ratioList = [];
          for(let ratio = value.utilization_min_ratio; ratio <= value.utilization_max_ratio; ratio = ratio + 0.1){
            ratioList.push({value: ratio.toFixed(1), label: ratio.toFixed(1) + ':1' });
          }
          this.setState({utilizationRatioList:ratioList});
          this.updateValueField('utlizationRatio', ratioList.find(item => item.value == value.utilization_default_ratio), 'engine');
          this.updateValueField('utilization_cycles', parseInt(mrLogInfo.engine.utilization_hours /ratioList.find(item => item.value == value.utilization_default_ratio).value), 'engine' )
          if(regionDetails){
            this.getRegionOfOperation({location:regionDetails, engine_type:value.id})
          }
        }else{
          this.updateValueField('regionOfOperation', null, 'engine');
          this.updateValueField('utlizationRatio', null, 'engine')
          this.updateValueField('utilization_hours', 300, 'engine')
          this.updateValueField('noOfEngine', 1, 'engine')
          this.props.getAircraftTypes(this.props, {'mr-calculator':'mr-calculator'})
          this.setState({utilizationRatioList:[]});
        }
      }
      if(tableType == "airframeAndGen" && (keyParam === 'hourlyUtlization' || keyParam === 'cycleUtlization')){
        if(keyParam === 'hourlyUtlization'){
          if(mrLogInfo && mrLogInfo[tableType] && mrLogInfo[tableType].cycleUtlization){
            let utlizationRatio = value ? (value/mrLogInfo[tableType].cycleUtlization).toFixed(1) : 0
            this.updateValueField('utlizationRatio', value ? utlizationRatio ? utlizationRatio :0 :0, tableType)
          }
          if(mrLogInfo[tableType].hourlyUtlization == "0" || mrLogInfo[tableType].cycleUtlization == "0" ){
            this.updateValueField('utlizationRatio', 0, tableType)
          }
        }else{
          if(mrLogInfo[tableType].hourlyUtlization){
            let utlizationRatio = value ? (value/mrLogInfo[tableType].cycle_utilization).toFixed(1):0
            this.updateValueField('utlizationRatio', value ? utlizationRatio ? utlizationRatio : 0 : 0, tableType)
          }
        }
      }
    }else{
      // Call the Engine Type apiServices
      const { mrLogInfo } = this.state;
      if(value === 'airframe'){
        if(mrLogInfo.metricsInfo.includes('airframe')){
          if(mrLogInfo.metricsInfo.includes('engine')){
            this.props.getEngineTypes({'mr-calculator':'mr-calculator'});
            this.updateValueField('noOfEngine',1, 'engine');
          }
        }else{
          if(mrLogInfo.metricsInfo.includes('engine') && mrLogInfo.airframeAndGen.aircraftType ){
            this.props.getEngineTypes({'aircraft_type':mrLogInfo.airframeAndGen.aircraftType.id,'mr-calculator':'mr-calculator'});
          }
        }
      }
      if(['reference_number', 'remarks'].includes(keyParam)){
        this.setState(prevState => ({
          ...prevState,
          mrLogInfo:{
            ...prevState.mrLogInfo,
            [keyParam]:value
          }
        }))
      }
      if(value === 'engine'){
        this.setState({
          regionCondition:null
        })
        this.updateValueField('regionOfOperation', null, 'engine');
        if(mrLogInfo.metricsInfo.includes('airframe')){
          if(mrLogInfo.airframeAndGen.aircraftType && mrLogInfo.airframeAndGen.aircraftType.id){
            this.props.getEngineTypes({aircraft_type: mrLogInfo.airframeAndGen.aircraftType.id,'mr-calculator':'mr-calculator'});
          }
        }else{
          this.props.getEngineTypes({'mr-calculator':'mr-calculator'});
        }
      }
      this.setState(prevState => ({
        ...prevState,
        mrLogInfo: {
          ...prevState.mrLogInfo,
          metricsInfo: prevState.mrLogInfo.metricsInfo.includes(value) ? prevState.mrLogInfo.metricsInfo.filter(label => label !== value):[...prevState.mrLogInfo.metricsInfo, value]
        }
      }));
    }
  }
  updateValueField = (keyParam, value, tableType) => {
      this.setState(prevState => ({
        ...prevState,
        mrLogInfo: {
          ...prevState.mrLogInfo,
          [tableType]: {
            ...prevState.mrLogInfo[tableType],
            [keyParam]:value
          }
        }
      }));
  }
  updateDateOfManufacture = (keyParam, value, tableType) => {
      this.setState(prevState => ({
        ...prevState,
        overhaulDateFlag:true,
        mrLogInfo: {
          ...prevState.mrLogInfo,
          [tableType]: {
            ...prevState.mrLogInfo[tableType],
            [keyParam]:value,
            overhaul_date:true
          }
        }
      }));
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:message
      }
    }))
  }
  updateRegionErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:message
      }
    }))
  }
  updateRegionVariables = () => {
    const { regionCondition } = this.state;
    let variable_condition_id = [];
    Object.keys(regionCondition.variable_condition).map((label) => {
      if(regionCondition.variable_condition[label].default){
        variable_condition_id.push(regionCondition.variable_condition[label].default);
      }
    });
    this.setState(prevState => ({
      ...prevState,
      mrLogInfo:{
        ...prevState.mrLogInfo,
        engine: {
          ...prevState.mrLogInfo.engine,
          variable_condition_id:variable_condition_id
        }
      },
      adjustRegionVar:false

    }));
    trackActivity('Region Variables Customized', {page_title: 'Generate MR Log'});
    this.props.enqueueSnackbar('Applied Successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  }

  getAddressDetails = (address) => {
    if(address){
      this.setState(prevState => ({
        ...prevState,
        error:{
          ...prevState.error,
          regionOfOperation:''
        }
      }))
      const { mrLogInfo } = this.state;
      let regionObj = {};
      address.address_components.map(location => {
        let types = location.types[0];
        switch (types) {
          case 'country':
            regionObj = {
              ...regionObj,
              country_name: location.long_name,
              country_iso_2: location.short_name
            }
            return
          case "administrative_area_level_1":
            regionObj = {
              ...regionObj,
              state_name: location.long_name,
              state_code: location.short_name
            }
            return
          case "administrative_area_level_2":
            regionObj = {
              ...regionObj,
              city_name: location.long_name,
              city_code: location.short_name
            }
            return
          default:
            return
        }
      });
      regionObj = {
        ...regionObj,
        address: `${regionObj.city_name ? regionObj.city_name: ''}${regionObj.city_name ? ', ' : ''}${regionObj.state_name ? regionObj.state_name : ''}${regionObj.state_name ? ', ' :''}${regionObj.country_name}`
      }
      if(regionObj.country_iso_2 ){
        if(mrLogInfo.metricsInfo.includes('engine')){
          if(mrLogInfo.engine.engineType && mrLogInfo.engine.engineType.id){
            this.getRegionOfOperation({location:{...regionObj,...this.state.location}, engine_type:mrLogInfo.engine.engineType.id})
          }else {
          }
        }
        this.setState(prevState => ({
          ...prevState,
          regionDetails:{...regionObj, google_api: true},
          error:{
            ...prevState.error,
            regionOfOperation:''
          }
        }));
      }else{
        // this.props.enqueueSnackbar('Not able to identify the location, please select again a', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    }else{
      this.props.enqueueSnackbar('Not able to identify the location, please select agains', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  }

  updateLocationWithoutGoogle = (key, value, stateFlag) => {
    let regionObj = this.state.regionDetails ? Object.assign({}, this.state.regionDetails) : {}
    this.setState({stateFlagCheck:stateFlag })
    this.updateErrorField('regionOfOperation', '')
    if(value){
      switch (key) {
        case 'country':
          regionObj = {
            ...regionObj,
            country_name: value ? value.name : '',
            country_iso_2: value ? value.alpha_code_two : ''
          }
          this.setState(prevState => ({
            ...prevState,
            regionCondition:null,
            adjustRegionVar:false,
            mrLogInfo:{
              ...prevState.mrLogInfo,
              engine:{
                ...prevState.mrLogInfo.engine,
                variable_condition_id:[]
              }
            }
          }))
          break
        case 'state':
          regionObj = {
            ...regionObj,
            state_name: value ? value.name : '',
            state_code: value ? value.state_code : ''
          }
          break
        case 'city':
          regionObj = {
            ...regionObj,
            city_name: value ? value.city_name : '',
            city_code: value ? value.city_name : ''
          }
          break
          default:
          regionObj = {
            ...regionObj,
            country_name: "",
            country_iso_2: "",
            state_name: "",
            state_code: "",
            city_name: "",
            city_code: ""
          }
      }
    }else {
      if(key == "country" || key == "state"){
        this.setState(prevState => ({
          ...prevState,
          regionCondition:null,
          mrLogInfo:{
            ...prevState.mrLogInfo,
            engine:{
              ...prevState.mrLogInfo.engine,
              variable_condition_id:[]
            }
          }
        }))
        regionObj = {
          ...regionObj,
          country_name: "",
          country_iso_2: "",
          state_name: "",
          state_code: "",
          city_name: "",
          city_code: ""
        }
      }
    }
    trackActivity('Region Selected', {source: 'Custom Dropdowns', page_title: 'Generate MR Log'})
    this.setState(prevState => ({
      ...prevState,
      regionDetails:{...regionObj, google_api: false, address: stateFlag ? `${regionObj.city_name ? regionObj.city_name + ', ' : ''}${regionObj.state_name ? regionObj.state_name + ', ' : ''}${regionObj.country_name ? regionObj.country_name : ''}` :`${regionObj.city_name ? regionObj.city_name + ', ' : ''}${regionObj.country_name ? regionObj.country_name : ''}`},
    }), () => {
      if(regionObj.country_name && key !== 'city'){
        if(!stateFlag){
          this.getRegionOfOperation({location:this.state.regionDetails, engine_type:this.state.mrLogInfo.engine.engineType.id})
        }else {
          if(regionObj.state_name){
            this.getRegionOfOperation({location:this.state.regionDetails, engine_type:this.state.mrLogInfo.engine.engineType.id})
          }
        }
      }
    })
  }

  handleChange = address => {
    const { mrLogInfo } = this.state;
    this.setState({ address, location: address.trim() === '' ? { lat: 12.9642373, lng: 77.6392131 } : this.state.location , showMarker: address.trim() === '' ? false : true}, () => this.setState({showMarker: true}));
    if(address.trim() === '' && mrLogInfo.metricsInfo.includes('engine')){
      this.setState(prevState => ({
        ...prevState,
        regionCondition:null,
        adjustRegionVar:false,
        regionDetails:null,
        mrLogInfo:{
          ...prevState.mrLogInfo,
          engine:{
            ...prevState.mrLogInfo.engine,
            regionOfOperation:null,
            variable_condition_id:[]
          }
        }
      }));
    }
  };
  radioChange=(value) => {
    this.setState(prevState => ({
      ...prevState,
      radioCheck:value
    }))
  }
  getAddressFromLatLng = (map) =>{
    trackActivity('Region Selected', {source: 'Google Map Click', page_title: 'Generate MR Log'})
    var google = new window.google.maps.Geocoder()
    let geocode = google.geocode({latLng: {lat: map.latLng.lat(), lng: map.latLng.lng()}}, (results) => {
      if(results.length){
        this.getAddressDetails(results[0])
        let regionObj = {};
        results[0].address_components.map(location => {
          let types = location.types[0];
          switch (types) {
            case 'country':
              regionObj = {
                ...regionObj,
                country_name: location.long_name,
                country_iso_2: location.short_name
              }
              return
            case "administrative_area_level_1":
              regionObj = {
                ...regionObj,
                state_name: location.long_name,
                state_code: location.short_name
              }
              return
            case "administrative_area_level_2":
              regionObj = {
                ...regionObj,
                city_name: location.long_name,
                city_code: location.short_name
              }
              return
            default:
              return
          }
        });
        regionObj = {
          ...regionObj,
          address: `${regionObj.city_name ? regionObj.city_name  + ', ' : ''}${regionObj.state_name ? regionObj.state_name  + ', ' : ''}${regionObj.country_name ? regionObj.country_name : ''}`
        }
        this.setState({address: `${regionObj.address.includes('undefined') ? '' : regionObj.address}`, showMarker: false}, () =>
          this.setState({
            location:{lat: map.latLng.lat(), lng: map.latLng.lng()}
          }, () => this.setState({showMarker: this.state.address.includes('undefined') ? false : true}))
        )
        if(regionObj.city_name == 'undefined' || regionObj.state_name === 'undefined' || regionObj.country_name === 'undefined' || regionObj.city_name == undefined || regionObj.state_name === undefined || regionObj.country_name === undefined){
          this.props.enqueueSnackbar('Not able to identify the location, please select again ', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      }else{
        this.props.enqueueSnackbar('Not able to identify the location, please select again', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }

  handleSelect = address => {
    trackActivity('Region Selected', {source: 'Google Places', page_title: 'Generate MR Log'})
    this.setState({address, showMarker: false}, () => {
      geocodeByAddress(address)
        .then(results => this.getAddressDetails(results[0]))
    })
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.setState({location: latLng, showMarker: true}))
      .catch(error => console.error('Error', error));
  };

  getRegionOfOperation = (data) => {
    this.setState({pageLoader:true});
    this.updateRegionMapsApi(this.props, data)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.getEngineDerates({variable:1 , engine_type: data?.engine_type , region_id:response.data.data?.current_region?.id})
        this.setState(prevState => ({
          ...prevState,
          mrLogInfo:{
            ...prevState.mrLogInfo,
            engine: {
              ...prevState.mrLogInfo.engine,
              regionOfOperation:response.data.data.current_region,
              derates: response.data.data?.variable_condition[1]?.ranges.find(item => item.order == response.data.data?.variable_condition[1].default )
            }
          },
          regionCondition:response.data.data
        }));
      }else{
        this.props.enqueueSnackbar(response.data.message,{variant:'error',anchorOrigin:{vertical: 'top',horizontal: 'right'}})
        this.setState({ address:'', location: { lat: 12.9642373, lng: 77.6392131 }, showMarker: false}, () => this.setState({showMarker: true}));
      }
    })
  }
  render(){
    const { mrLogInfo, mntGroups, error, pageLoader, licenseModal, licenseDesc, regionCondition, utilizationRatioList, adjustRegionVar, regionDetails, apuLgLimit, overhaulDateFlag, radioCheck, radioCheckLg } = this.state;
    const { aircraftTypes, engineTypes, apuTypes, pltConstants } = this.props
    return(
      <div className="mr-calculator-section">
        <div className="mr-calculator-initiate">
          <Grid container alignItems="flex-end" spacing={1} className="assembly-icons">
            <Grid item xs={12} md={8} sm={8} lg={8}>
              <AssemblySelection
                mrLogInfo={mrLogInfo}
                onFieldChange={this.onFieldChange}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={4} lg={4}>
              <Button onClick={() => this.initiateMrLogApi(this.props, {...mrLogInfo, location:regionDetails})} disabled={!mrLogInfo.metricsInfo.length} style={{float:'right'}} color="primary" variant="contained">Generate LOG</Button>
               {!mrLogInfo.metricsInfo.length ?
                 <span style={{fontSize: '11px', color:'#ff0000', clear:'both', display:'block', float:'right'}}>Please select a component</span> : null
               }
            </Grid>
          </Grid>
          <form>
            <Grid container spacing={1}>

              <Grid item md={7} sm={12} xs={12}>
                <div style={{maxHeight:`${window.innerHeight-200}px`,overflow:'auto'}}>
                
                  { mrLogInfo.metricsInfo.includes('airframe') ?
                    <AirframeInitiate
                      mrLogInfo={mrLogInfo}
                      onFieldChange={(e, keyParam, value) => this.onFieldChange(e, keyParam, value, 'airframeAndGen')}
                      onFieldAutopulate ={this.onFieldChange}
                      updateErrorField={this.updateErrorField}
                      aircraftTypes={aircraftTypes}
                      mntGroups={mntGroups}
                      error={error}
                    />:null
                  }
                  { mrLogInfo.metricsInfo.includes('engine') ?
                    <EngineInitiate
                      deRates={this.state.deRates}
                      mrLogInfo={mrLogInfo}
                      engineTypes={engineTypes}
                      loader={this.props.loader}
                      utilizationRatioList={utilizationRatioList}
                      updateErrorField={this.updateErrorField}
                      onFieldChange={(e, keyParam, value) => this.onFieldChange(e, keyParam, value, 'engine')}
                      error={error}
                    />:null
                  }
                  { mrLogInfo.metricsInfo.includes('apu') ?
                    <APUInitiate
                      mrLogInfo={mrLogInfo}
                      apuTypes={apuTypes}
                      updateErrorField={this.updateErrorField}
                      onFieldChange={(e, keyParam, value) => this.onFieldChange(e, keyParam, value, 'apuAndGen')}
                      overhualFn={this.overhualFn}
                      limit={apuLgLimit && apuLgLimit.apu && apuLgLimit.apu.limit ? apuLgLimit.apu.limit : 7000}
                      error={error}
                      overhaulDateFlag={overhaulDateFlag}
                      updateDateOfManufacture={this.updateDateOfManufacture}
                      radioCheck={radioCheck}
                      radioDom={(dom) => this.setState({radioCheck:dom})}
                      radioDol={(dol) => this.setState({radioCheck:dol})}
                    />:null
                  }
                  { mrLogInfo.metricsInfo.includes('lg') ?
                    <LGInitiate
                      mrLogInfo={mrLogInfo}
                      pltConstants={pltConstants}
                      updateErrorField={this.updateErrorField}
                      onFieldChange={(e, keyParam, value) => this.onFieldChange(e, keyParam, value, 'lgAndGen')}
                      overhualFn={this.overhualFn}
                      limit={apuLgLimit && apuLgLimit.lg && apuLgLimit.lg.limit ? apuLgLimit.lg.limit  : 20000 }
                      updateDateOfManufacture={this.updateDateOfManufacture}
                      overhaulDateFlag={overhaulDateFlag}
                      error={error}
                      radioCheckLg={radioCheckLg}
                      radioDom={(dom) => this.setState({radioCheckLg:dom})}
                      radioDol={(dol) => this.setState({radioCheckLg:dol})}

                    />:null
                  }
                  <Paper style={{padding:'10px 15px', marginBottom:'10px'}}>
                    <Grid container spacing={1}>
                      <Grid item md={6} xs={6}>
                        <TextField
                          id="reference_number"
                          label="Reference Number"
                          fullWidth
                          margin="normal"
                          inputProps={{ maxLength: 20 }}
                          InputLabelProps={{shrink: true}}
                          value={mrLogInfo.reference_number ? mrLogInfo.reference_number:''}
                          onChange={(e) => this.onFieldChange(e, 'reference_number', e.target.value)}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={12} xs={6}>
                        <TextField
                          id="remarks"
                          multiline
                          row={1}
                          label="Remarks"
                          fullWidth
                          margin="normal"
                          inputProps={{ maxLength: 255 }}
                          InputLabelProps={{shrink: true}}
                          value={mrLogInfo.remarks ? mrLogInfo.remarks:''}
                          onChange={(e) => this.onFieldChange(e, 'remarks', e.target.value)}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                  
                </div>
              </Grid>
              <Grid item md={5} sm={12} xs={12} className="map-section" direction= "row-reverse" style={{position:'relative', maxHeight: window.google !== undefined && window.google !== null ? 'auto' : '145px', borderRadius: error.regionOfOperation ? '4px' : 'auto', border: error.regionOfOperation ? window.google == undefined && window.google == null && mrLogInfo.metricsInfo.includes('engine') && mrLogInfo.engine.engineType ?'1px solid #ff0000': 'none':'none'}}>
                {this.state.showMarker && window.google !== undefined && window.google !== null ? <label style={{color: error.regionOfOperation ? '#ff0000':'rgba(0, 0, 0, 0.54)', fontSize:'16px', paddingBottom:'5px'}}>Region of Operation {mrLogInfo.metricsInfo.includes('engine') ? '*':''}</label>:null}
                {
                  window.google !== undefined && window.google !== null ?
                    this.state.showMarker ?
                  <div>
                    <div style={{clear:'both', overflow:'hidden'}}>
                      {window.google !== undefined && window.google !== null ?
                        <GooglePlaces
                          address={this.state.address}
                          handleChange={this.handleChange}
                          handleSelect={this.handleSelect}
                        />
                      :null
                      }
                      <Map
                        getAddressFromLatLng={(map) => this.getAddressFromLatLng(map)}
                        location={this.state.location}
                        address={this.state.address}
                        showMarker={this.state.showMarker}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `400px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                      />
                    </div>
                    { this.state.address ?
                      <div style={{ backgroundColor: `#fff`, opacity: 0.75, padding: `5px 12px` }}>
                        <p style={{ fontSize: `14px`, fontColor: `#f2f2f2` }}>
                          {this.state.regionDetails && this.state.regionDetails.city_name ? `${this.state.regionDetails.city_name}, ` : ''}
                          {this.state.regionDetails && this.state.regionDetails.state_name ? `${this.state.regionDetails.state_name}, ` : ''}
                          {this.state.regionDetails && this.state.regionDetails.country_name ? `${this.state.regionDetails.country_name}` : ''}
                        </p>
                      </div>:null
                    }
                    <h6 className="error-msg" style={{position:'relative', top: '10px'}}>{error.regionOfOperation}</h6>
                  </div>
                  :null
                  : mrLogInfo.metricsInfo.includes('engine') && mrLogInfo.engine.engineType ?
                  <div className="country-selector">
                    <CountryStateSelector
                      regionDetails={this.state.regionDetails}
                      updateLocationWithoutGoogle={(key, value,stateFlag) => this.updateLocationWithoutGoogle(key, value, stateFlag)}
                      error={error}
                      updateRegionErrorField={this.updateRegionErrorField}
                      updateErrorField={this.updateErrorField}
                    />
                    <h6 className="error-msg" style={{position:'relative', bottom: '15px'}}>{error.regionOfOperation}</h6>
                  </div>
                  : null
                }
                { regionCondition && regionCondition.variable_condition && mrLogInfo.engine.engineType ?
                  <div className="custom-region-variables">
                    <RegionCustomVariable
                      regionCondition={regionCondition.variable_condition}
                      onChangeRegionCond={this.onChangeRegionCond}
                      updateRegionVariables={this.updateRegionVariables}
                      adjustRegionVar={adjustRegionVar}
                      toggleAdjustRagionVarFn ={() => {this.setState(prevState => ({...prevState, adjustRegionVar: !prevState.adjustRegionVar}));trackActivity('Adjust Region Variables', {page_title: 'Generate MR Log'}); adjustRegionVar && this.getRegionCustomVariablesApi({region_id:regionCondition.current_region.id,engine_type:mrLogInfo.engine.engineType.id})}}
                    />
                  </div>:null
                }
              </Grid>
            </Grid>
          </form>
        </div>
        <LicenseFailModal
          licenseModal={licenseModal}
          licenseDesc={licenseDesc}
          toggleModalFn={() => this.setState({licenseModal:false, licenseDesc:''})}
        />

        { pageLoader ? <PageLoader />:null }

      </div>
    )
  }
}
const mapStateToProps = state => ({
  pltConstants: state.shareReducer.pltConstants,
  apuTypes: state.shareReducer.apuTypes,
  aircraftTypes: state.shareReducer.aircraftTypes,
  engineTypes: state.shareReducer.engineTypes,
  loader: state.shareReducer.loader
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAircraftTypes: (props, query) => dispatch(getAircraftTypesAc(props, query)),
    getEngineTypes: (query) => dispatch(getEngineTypesAc(query)),
    getAPUTypes: (query) => dispatch(getAPUTypesAc(query)),
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({constant_types:['lg_position']})),
    emptyEnginesTyeps:() => dispatch({
      type: 'platform/engine_types',
      payload: []
    })
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Inititate))
