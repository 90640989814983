import React, { useState, useEffect } from 'react'
import { TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { globalGetService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
import { useSnackbar } from 'notistack';
export default function ApuLgFilter({setFilter, filter, processingLoader, getResponseBack }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [apuType, setApuType] = useState([])
    const [aircraftTypeList, setAircraftTypeList] = useState([])
    useEffect(() => {
        getAircraftTypeAPi()
        getApuTypeList()
    }, [])
    const changeGenericFilter = (key, value) => {
        if(value?.length <= 5){
            setFilter(prevState => ({
                ...prevState,
                [key]: value
            }))
        }
        else{
            enqueueSnackbar('Max 5 values can be selected.', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
        if (key == 'aircraft_type') {
            if (value?.length <= 1) {
                getApuTypeList(value[0] ? value[0] : null)
            }
            setApuType([])
            setFilter(prevState => ({
                ...prevState,
                apu_type: []
            }))
        }
        else  {
            if (value[0] && value?.length == 1) {
                getAircraftTypeAPi({apu_type:value[0]?.id})
            }
        }
    }
    const getAircraftTypeAPi = (query) => {
        globalGetService(`console/aircraft-types/`, query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setAircraftTypeList(response.data.data?.aircraftTypes)
                }
            })
    }
    const getApuTypeList = (aircraftType) => {
        globalGetService(`console/apu-types/`, { 'aircraft-type': aircraftType?.id })
            .then(response => {
                if (checkApiStatus(response)) {
                    setApuType(response.data.data?.apu_types && response.data.data?.apu_types[0] ? response.data.data?.apu_types : []);
                }
            })
    }
    return (
        <div style={{ position: 'relative' }}>
            {processingLoader ?
                <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', zIndex: 10, background: 'rgba(255,255,255,.8)' }}>
                    <p style={{ textAlign: 'center', fontSize: '15px', padding: '7px 0' }}><span style={{ padding: '4px', background: '#fff', fontWeight: '500', fontFamily: 'Conv_IBMPlexSans-Medium' }}>Processing the Graph Data...</span></p>
                </div> : null
            }
            <ul className='list-inline flex-centred' style={{display:'inline-flex', alignItems:'center'}}>
                <li className='list-inline-item margin-right'>
                    <Autocomplete
                        options={aircraftTypeList}
                        multiple
                        disabled={filter?.apu_type?.length > 1}
                        getOptionLabel={option => option.name}
                        value={filter?.aircraft_type || []}
                        style={{ width: 375 }}
                        id="aircraft_type"
                        onChange={(e, value) => changeGenericFilter('aircraft_type', value)}
                        renderInput={params => <TextField {...params} InputLabelProps={{ shrink: 'true' }} label="Aircraft Type" placeholder="Select Aircraft Type" variant="outlined" />}
                    />
                </li>
                <li className='list-inline-item margin-right'>
                    <Autocomplete
                        options={apuType}
                        multiple
                        disabled={filter?.aircraft_type?.length > 1}
                        getOptionLabel={option => option.name}
                        value={filter?.apu_type || []}
                        style={{ width: 375 }}
                        id="apu_type"
                        onChange={(e, value) => changeGenericFilter('apu_type', value)}
                        renderInput={params => <TextField {...params} InputLabelProps={{ shrink: 'true' }} label="Apu Type" placeholder="Select Apu Type" variant="outlined" />}
                    />
                </li>
                <li className='list-inline-item margin-right apply-btn'>
                    <Button variant='contained' color='primary' size='small' onClick={()=> getResponseBack(filter)} >
                        Apply
                    </Button>
                </li>
            </ul>
        </div>
    )
}
