import React from 'react';
import { withRouter } from 'react-router-dom';
import { TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';
import { displayDateFormatShort } from '../../../constants';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import { onCheckPermission } from '../../../utils';
const Portfolio = ({ item, history, onEdit, onDelete }) => {
    return (
        <TableRow onClick={() => history.push(`/portfolio/${item.slug}`)}>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.no_of_assets}</TableCell>
            <TableCell>{item.remarks}</TableCell>
            <TableCell>{item?.created_by?.name ? item.created_by.name : '--'}</TableCell>
            <TableCell>{item?.created_at ? moment(item.created_at).format(displayDateFormatShort) : '--'}</TableCell>
            <TableCell>{item?.updated_by?.name ? item.updated_by.name : '--'}</TableCell>
            <TableCell>{item?.updated_at ? moment(item.updated_at).format(displayDateFormatShort) : '--'}</TableCell>
            <TableCell>{item?.status?.label ? item.status.label : '--'}</TableCell>
            <TableCell align='right'>
                <ul className='list-inline'>
                    <li className='list-inline-item'>
                        <VisibilityIcon color='primary' fontSize='small' />
                    </li>
                    {
                        onCheckPermission('mr_calculator', 'portfolio', 'U') ?
                            <li className='list-inline-item' onClick={(e) => { e.preventDefault(); e.stopPropagation(); onEdit() }}>
                                <EditIcon color='primary' fontSize='small' />
                            </li> : null}
                    {
                        onCheckPermission('mr_calculator', 'portfolio', 'D') ?
                            <li className='list-inline-item' onClick={(e) => { e.preventDefault(); e.stopPropagation(); onDelete() }}>
                                <DeleteOutlineIcon color='secondary' fontSize='small' />
                            </li>
                            : null}
                </ul>
            </TableCell>
        </TableRow>
    )
}
export default withRouter(Portfolio);