import React, { Fragment, useState, useEffect } from "react";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { Tooltip, TableRow, InputAdornment, TableCell, Button, Grid, Dialog, DialogContent, DialogTitle, DialogActions, TextField, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { getCurrencyFormat } from '../../../utils';
import NumericLabel from 'react-pretty-numbers';
import MomentUtils from '@date-io/moment';
import { ExpandMore } from "@material-ui/icons";
import moment from "moment";
import InfoIcon from '@material-ui/icons/Info';
import { PageLoader, TableListComp } from "../../../shared_elements";
import { LabelValueCard } from "../../../shared_elements";
import { regexConstants } from '../../../constants/regEx'
import EolLlpModalDetails from "./EolLlpModalDetails";
import Checkbox from '@material-ui/core/Checkbox';


export default function EolOutputConditions({ editMode, simulationType, onFieldChange, eolIndex, conditionIndex, eolItem, eol, simulatedEol, simulatedEolItem, openAllAcc, setOpenAllAcc, onChangeAccordian, accordianArr }) {
    const [llpModal, setLLpModal] = useState(false)
    let llpReturnCondition = simulationType == 3 ?  simulatedEolItem?.analysis?.llp_detail_analysis?.filter(item => item.status == false).length :  eolItem?.analysis?.llp_detail_analysis?.filter(item => item.status == false).length
    let itemReturnCondition =  simulationType == 3 ? simulatedEolItem?.analysis?.status == false : eolItem?.analysis?.status == false
    let tableData = eolItem?.event_details?.length && Object.keys(eolItem?.event_details[0]).includes("is_sv_event") ? simulationType == 3? simulatedEolItem?.event_details?.filter(item => item.is_sv_event == true) : eolItem?.event_details?.filter(item => item.is_sv_event == true) : eolItem?.event_details 
    return (
        <>
            <Accordion style={{ display: 'inherit' }} expanded={accordianArr.includes(conditionIndex) || openAllAcc}>
                <AccordionSummary className={conditionIndex % 2 == 0 ? 'even-row-eol' : 'odd-row-eol'} expandIcon={<ExpandMore />} onClick={() => onChangeAccordian(conditionIndex, eolIndex)}>
                    <Grid className="label-value-card" item xs={2} style={{marginRight:'5px'}}> <p>Event Name</p> <h6 style={itemReturnCondition || llpReturnCondition ? { color: '#ff0000' } : {}}>{eolItem?.component_name?.name ? eolItem?.component_name?.name : '--'}</h6>  {itemReturnCondition || llpReturnCondition ?
                        <span className="notice-info" style={{ marginTop: '3px', position: 'relative', top: '4px' }}> Does not meet return conditions</span> :
                        <span style={{ position: 'relative', top: '4px', borderRadius: '4px', color: 'green', border: eol?.lease_type.value != 3? '1px solid green' :null , padding: '2px 5px', fontSize: '11px', background: '#cef1a5' }}> Meets the return Condition</span>}
                    </Grid>
                    {eolItem?.component_name?.name.includes('LLP') ?
                        <Grid item xs={6} label='Event Name' align="center" ><span onClick={() => setLLpModal({ flag: true, data: eolItem, eol: eol })} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>View LLP</span></Grid> :
                        <Fragment>
                            <LabelValueCard style={itemReturnCondition || llpReturnCondition ? { color: '#ff0000' } : {}} md={3} label='Expected EOL Condition' value={
                                eolItem?.expected_mint_condition?.label ?
                                    `${eolItem?.expected_mint_condition?.label} ${eolItem?.value ?
                                        `(${eolItem?.value} ` : ''} ${eolItem?.component_name?.value_type ?
                                            `${eolItem?.component_name?.value_type})` : ''}` : ''
                            }>
                            </LabelValueCard>
                            <LabelValueCard style={itemReturnCondition || llpReturnCondition ? { color: '#ff0000' } : {}} md={3} label='Green Time Remaining at EOL'
                                value={simulationType === '3' ? simulatedEolItem.actual_condition ?
                                    <>
                                        {Math.round(simulatedEolItem?.actual_condition)}  {eolItem?.component_name?.value_type} ({simulatedEolItem?.analysis?.percentage} %)
                                        {
                                            simulatedEolItem.actual_condition !== eolItem.actual_condition ?
                                                <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                                    {Math.round(eolItem?.actual_condition)} {eolItem?.component_name?.value_type} ({eolItem?.analysis?.percentage} %)
                                                    <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                                </p> : null
                                        }
                                    </>
                                    : '0'
                                    : eolItem?.actual_condition ? `${Math.round(eolItem?.actual_condition)} ${eolItem?.component_name?.value_type ? `${eolItem?.component_name?.value_type}` : ''}  (${eolItem?.analysis?.percentage} %)` : `0 ${eolItem?.component_name?.value_type} (${eolItem?.analysis?.percentage}%)`}></LabelValueCard>
                        </Fragment>
                    }
                    { eol?.lease_type.value != 2 ? 
                        <LabelValueCard style={itemReturnCondition || llpReturnCondition ? { color: '#ff0000' } : {}} md={2} label={'MR Accruals at EOL'}
                        value={simulationType === '3' ? simulatedEolItem.mr_accruals ?
                        <>
                        <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{simulatedEolItem.mr_accruals}</NumericLabel>
                           
                            {
                                simulatedEolItem.mr_accruals !== eolItem.mr_accruals ?
                                    <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                        <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{eolItem.mr_accruals}</NumericLabel>
                                        <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                    </p> : null
                            }
                        </>
                        : 'US$0.00' :
                        eolItem.mr_accruals ?
                         <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{eolItem.mr_accruals}</NumericLabel>
                            : 'US$0.00'}
                        />
                            : null
                    }
                    {eol?.lease_type.value == 2 ?
                        <LabelValueCard style={itemReturnCondition || llpReturnCondition ? { color: '#ff0000', marginRight:'3px' } : {marginRight:'3px'}} md={2} label={'EOL Compensation Amount'}
                            value={simulationType === '3' ? simulatedEolItem.compensation_amount ?
                                <>
                                    <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{simulatedEolItem.compensation_amount}</NumericLabel>
                                    {
                                        simulatedEolItem.compensation_amount !== eolItem.compensation_amount ?
                                            <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                                <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{eolItem.compensation_amount}</NumericLabel>
                                                <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                            </p> : null

                                    }
                                </>
                                : 'US$0' :
                                eolItem.compensation_amount ?
                                    <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{eolItem.compensation_amount}</NumericLabel>
                                    : 'US$0'}
                        >
                        </LabelValueCard>

                        : null }
                        {
                        eol?.lease_type.value == 3 ?
                        <>
                            <LabelValueCard style={itemReturnCondition || llpReturnCondition ? { color: '#ff0000' } : {}} md={2} label='Lessor to Lessee Compensation Amount'
                                value={simulationType === '3' && eolItem?.mirror_in_mirror_out_amt && eolItem?.mirror_in_mirror_out_payer == 'Lessor' ? simulatedEolItem.mirror_in_mirror_out_amt ?
                                    <>
                                        <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{simulatedEolItem?.mirror_in_mirror_out_amt}</NumericLabel>
                                        {
                                            simulatedEolItem.mirror_in_mirror_out_amt !== eolItem.mirror_in_mirror_out_amt ?
                                                <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                                    <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{eolItem.mirror_in_mirror_out_amt}</NumericLabel>
                                                    <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                                </p>
                                                : null
                                        }
                                    </>
                                    : 'US$0'
                                    :
                                    eolItem?.mirror_in_mirror_out_amt && eolItem?.mirror_in_mirror_out_payer == 'Lessor' ?
                                        <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{eolItem?.mirror_in_mirror_out_amt}</NumericLabel>
                                        : 'US$0'}>
                            </LabelValueCard>
                            <LabelValueCard style={itemReturnCondition || llpReturnCondition ? { color: '#ff0000' } : {}} md={2} label='Lessee to Lessor Compensation Amount'
                                value={
                                    simulationType === '3' && eolItem?.mirror_in_mirror_out_amt && eolItem?.mirror_in_mirror_out_payer == 'Lessee' ? simulatedEolItem.mirror_in_mirror_out_amt ?
                                        <>
                                            <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{simulatedEolItem?.mirror_in_mirror_out_amt}</NumericLabel>
                                            {
                                                simulatedEolItem.mirror_in_mirror_out_amt !== eolItem.mirror_in_mirror_out_amt ?
                                                    <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                                        <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{eolItem.mirror_in_mirror_out_amt}</NumericLabel>
                                                        <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                                    </p>
                                                    : null
                                            }
                                        </>
                                        : 'US$0'
                                        :
                                        eolItem?.mirror_in_mirror_out_amt && eolItem?.mirror_in_mirror_out_payer == 'Lessee' ?
                                            <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{eolItem?.mirror_in_mirror_out_amt}</NumericLabel>
                                            : 'US$0'}>
                            </LabelValueCard>
                        </> : null
                    }
                        <Grid item xs={2}>
                                <div style={simulationType !== '2'?{color:'grey',fontSize:'14px', display:'flex'}:{fontSize:'14px', display:'flex'}}>
                                   <span className="label-value-card" style={eol?.lease_type.value == 2? {marginLeft:'10px'} :{}}> <p> Carry Fund Balance</p></span>
                                   <span style={{width:'92px'}}>
                                    <Tooltip title="Select this check box to carry over the fund balance to the next lease" arrow><InfoIcon style={{ fontSize: '16px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                    <Checkbox
                                        disabled={!editMode}
                                        checked={simulationType === '3' ? simulatedEolItem?.fund_carry_forward : eolItem.fund_carry_forward ? eolItem.fund_carry_forward : editMode ? eolItem.fund_carry_forward : false}
                                        onChange={(e) => onFieldChange(e, 'fund_carry_forward', !eolItem.fund_carry_forward, eolIndex, conditionIndex, null)}
                                        name="fund_carry_forward"
                                        color="primary"
                                    /></span>
                                </div>
                                {simulationType === '3' && eolItem.fund_carry_forward !== simulatedEolItem?.fund_carry_forward ?
                                    <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                        {eolItem.fund_carry_forward == true ? 'Checked' : 'Unchecked'}
                                        <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                    </p> : null
                                }
                            </Grid>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'inherit' }}>
                    <div style={{ marginBottom: '24px' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <TextField
                                    disabled={!editMode}
                                    fullWidth
                                    margin="normal"
                                    label='MR Rate @EOL'
                                    value={simulationType === '3' ? simulatedEolItem?.current_mr_rate : eolItem.current_mr_rate}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => onFieldChange(e, 'current_mr_rate', e.target.value, eolIndex, conditionIndex, null)}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                                        endAdornment: <InputAdornment position="end">/ ${eolItem.component_name.value_type}</InputAdornment>
                                    }}
                                />
                                {simulationType === '3' && eolItem.current_mr_rate !== simulatedEolItem?.current_mr_rate ?
                                    <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                        <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{eolItem.current_mr_rate}</NumericLabel>
                                        <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                    </p> : null
                                }
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    disabled={!editMode}
                                    fullWidth
                                    margin="normal"
                                    label='MR Esc.'
                                    value={simulationType === '3' ? simulatedEolItem?.escalation : eolItem.escalation}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => onFieldChange(e, 'escalation', e.target.value, eolIndex, conditionIndex, null)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                                    }}
                                />
                                {simulationType === '3' && eolItem.escalation !== simulatedEolItem?.escalation ?
                                    <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                        {eolItem.escalation}
                                        <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                    </p> : null
                                }
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    disabled={!editMode}
                                    fullWidth
                                    margin="normal"
                                    label='Cost Esc.(Annual)'
                                    value={simulationType === '3' ? simulatedEolItem?.cost_escalation : eolItem.cost_escalation ? eolItem.cost_escalation : editMode ? eolItem.cost_escalation : '0'}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => onFieldChange(e, 'cost_escalation', e.target.value, eolIndex, conditionIndex, null)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                                    }}
                                />
                                {simulationType === '3' && eolItem.cost_escalation !== simulatedEolItem?.cost_escalation ?
                                    <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                        {eolItem.cost_escalation}
                                        <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                    </p> : null
                                }
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    disabled={!editMode}
                                    fullWidth
                                    margin="normal"
                                    label='Fund Balance'
                                    value={simulationType === '3' ? simulatedEolItem?.fund_balance : eolItem.fund_balance}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => onFieldChange(e, 'fund_balance', e.target.value, eolIndex, conditionIndex, null)}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                                    }}
                                />
                                {simulationType === '3' && eolItem.fund_balance !== simulatedEolItem?.fund_balance ?
                                    <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                        <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{eolItem.fund_balance}</NumericLabel>
                                        <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                    </p> : null
                                }
                            </Grid>
                            <Grid item xs={3}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        disabled={!editMode}
                                        label='Fund Balance As of'
                                        margin="normal"
                                        format={fieldDateFormat}
                                        fullWidth
                                        inputVariant="outlined"
                                        value={simulationType === '3' ? simulatedEolItem?.fund_balance_as_of_date : eolItem.fund_balance_as_of_date}
                                        onChange={(data, value) => { onFieldChange(value, 'fund_balance_as_of_date', data ? moment(data).format(backendDateFormat) : data, eolIndex, conditionIndex, null); }}
                                    />
                                </MuiPickersUtilsProvider>
                                {simulationType === '3' && eolItem.fund_balance_as_of_date !== simulatedEolItem?.fund_balance_as_of_date ?
                                    <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                        {eolItem.fund_balance_as_of_date}
                                        <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                    </p> : null
                                }
                            </Grid>
                            <Grid item xs={3} style={{padding:'22px 0px 0px 17px'}}>
                                <div style={simulationType !== '2'?{color:'grey'}:{}}>
                                    Add SV Event at EOL
                                    <Tooltip title="Select this check box to adjust the last event to be performed at EOL" arrow><InfoIcon style={{ fontSize: '16px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                    <Checkbox
                                        disabled={!editMode}
                                        checked={simulationType === '3' ? simulatedEolItem?.add_sv_at_eol : eolItem.add_sv_at_eol ? eolItem.add_sv_at_eol : editMode ? eolItem.add_sv_at_eol : false}
                                        onChange={(e) => onFieldChange(e, 'add_sv_at_eol', !eolItem.add_sv_at_eol, eolIndex, conditionIndex, null)}
                                        name="add_sv_at_eol"
                                        color="primary"
                                    />
                                </div>
                                {simulationType === '3' && eolItem.add_sv_at_eol !== simulatedEolItem?.add_sv_at_eol ?
                                    <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                        {eolItem.add_sv_at_eol == true ? 'Checked' : 'Unchecked'}
                                        <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                    </p> : null
                                }
                            </Grid>
                        </Grid>
                    </div>
                    <TableListComp
                        heads={[{ label: 'Due Date' }, { label: 'Escalated MR Rate as of Due Date	' }, { label:'Ground Time (Days)'}, { label: 'Escalated Event Cost as of Date' }, { label: 'Fund Value' }, { label: 'Balance' }]}
                        data={tableData?.map((item, itemIndex) =>
                            <TableRow>
                                <TableCell>
                                    {simulationType === '1' ? <h4>{moment(item.eventDate).format(displayDateFormatShort)}</h4> : null}
                                    {simulationType === '2' ?
                                        eolItem?.component_name?.name.includes('Engine') ?
                                            <h4>{moment(item.eventDate).format(displayDateFormatShort)}</h4> :
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    required
                                                    margin="none"
                                                    id="eventDate"
                                                    format={fieldDateFormat}
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    value={item.eventDate ? item.eventDate : null}
                                                    onChange={(data, value) => { onFieldChange(value, 'eventDate', data ? moment(data).format(backendDateFormat) : data, eolIndex, conditionIndex, itemIndex); }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        : null
                                    }
                                    {simulationType === '3' ?
                                        <Fragment>
                                            {item.eventDate === eolItem?.event_details[itemIndex]?.eventDate ?
                                                <h4>{moment(item.eventDate).format(displayDateFormatShort)}</h4> :
                                                <div className="differentiation-values">
                                                    <h4>{moment(item.eventDate).format(displayDateFormatShort)}</h4>
                                                    <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                                        {moment( eolItem?.event_details[itemIndex]?.eventDate).format(displayDateFormatShort)}
                                                        <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                                    </p>
                                                </div>
                                            }
                                        </Fragment> : null
                                    }
                                </TableCell>
                                <TableCell>
                                    {simulationType === '1' || simulationType === '2' ? <h4><NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.mr}</NumericLabel></h4> : null}
                                    {simulationType === '3' ?
                                        <Fragment>
                                            {item?.mr === eolItem?.event_details[itemIndex]?.mr ?
                                                <h4><NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.mr}</NumericLabel></h4> :
                                                <div className="differentiation-values">
                                                    <h4><NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.mr}</NumericLabel></h4>
                                                    <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                                        <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{eolItem?.event_details[itemIndex]?.mr}</NumericLabel>
                                                        <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                                    </p>
                                                </div>
                                            }
                                        </Fragment>
                                        : null
                                    }
                                </TableCell>
                                <TableCell>
                                    {simulationType === '1' ? <h4>{item?.rest_days ? item?.rest_days : '--'}</h4> : null}
                                    {simulationType === '2' ?
                                        <TextField
                                            id="rest_days"
                                            disabled={eolItem?.component_name?.name.includes('LLP')}
                                            fullWidth
                                            margin="none"
                                            value={item?.rest_days ? item?.rest_days : ''}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'rest_days', parseInt(e.target.value), eolIndex, conditionIndex, itemIndex) : e.preventDefault() }}
                                        />
                                        : null
                                    }
                                    {simulationType === '3' ?
                                        <Fragment>
                                            {item?.rest_days === eolItem?.event_details[itemIndex]?.rest_days ?
                                                <h4>{item?.rest_days}</h4> :
                                                <div className="differentiation-values">
                                                    <h4>{item?.rest_days}</h4>
                                                    <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                                        {eolItem?.event_details[itemIndex]?.rest_days}
                                                        <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                                    </p>
                                                </div>
                                            }
                                        </Fragment>
                                        : null
                                    }
                                </TableCell>
                                <TableCell>
                                    {simulationType === '1' ? <h4><NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.eventCost}</NumericLabel></h4> : null}
                                    {simulationType === '2' ?
                                        <TextField
                                            id="eventCost"
                                            fullWidth
                                            margin="none"
                                            value={item?.eventCost ? item?.eventCost : ''}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'eventCost', e.target.value, eolIndex, conditionIndex, itemIndex) : e.preventDefault() }}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                                            }}
                                        />
                                        : null
                                    }
                                    {simulationType === '3' ?
                                        <Fragment>
                                            {item?.eventCost === eolItem?.event_details[itemIndex]?.eventCost ?
                                                <h4><NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.eventCost}</NumericLabel></h4> :
                                                <div className="differentiation-values">
                                                    <h4><NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.eventCost}</NumericLabel></h4>
                                                    <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                                        <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{eolItem?.event_details[itemIndex]?.eventCost}</NumericLabel>
                                                        <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                                    </p>
                                                </div>
                                            }
                                        </Fragment>
                                        : null
                                    }
                                </TableCell>
                                <TableCell>
                                    {simulationType === '1' || simulationType === '2' ? <h4><NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.fundCollected}</NumericLabel></h4> : null}
                                    {simulationType === '3' ?
                                        <Fragment>
                                            {item?.fundCollected === eolItem?.event_details[itemIndex]?.fundCollected ?
                                                <h4><NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.fundCollected}</NumericLabel></h4> :
                                                <div className="differentiation-values">
                                                    <h4><NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.fundCollected}</NumericLabel></h4>
                                                    <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                                        <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{eolItem?.event_details[itemIndex]?.fundCollected}</NumericLabel>
                                                        <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                                    </p>
                                                </div>
                                            }
                                        </Fragment>
                                        : null
                                    }
                                </TableCell>
                                <TableCell>
                                    {simulationType === '1' || simulationType === '2' ? <h4><NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.surPlus}</NumericLabel></h4> : null}
                                    {simulationType === '3' ?
                                        <Fragment>
                                            {item?.surPlus === eolItem?.event_details[itemIndex]?.surPlus ?
                                                <h4><NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.surPlus}</NumericLabel></h4> :
                                                <div className="differentiation-values">
                                                    <h4><NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.surPlus}</NumericLabel></h4>
                                                    <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                                        <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{eolItem?.event_details[itemIndex]?.surPlus}</NumericLabel>
                                                        <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                                    </p>
                                                </div>
                                            }
                                        </Fragment>
                                        : null
                                    }
                                </TableCell>
                            </TableRow>
                        )}
                    />
                </AccordionDetails>
            </Accordion>
            {llpModal.flag ?
                <Dialog
                    maxWidth='lg'
                    open={true}
                    onClose={() => setLLpModal(false)}
                >
                    <DialogTitle>
                        <p>{llpModal?.data?.component_name?.name} details </p>
                        {
                            llpReturnCondition ?
                                <span className="notice-info">
                                    (Below highlighted in red does not meet the return conditions)
                                </span>
                                : null}

                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <TableListComp
                            heads={llpModal?.eol?.lease_type?.value == 2 ? [{ label: 'Module Type', id: 'module_type' }, { label: 'LLP Description', id: 'llp_desc' }, { label: 'Expected Condition (FC)', id: 'expected_condition' }, { label: 'Actual Condition (FC)', id: 'actual_condition' }, { label: 'Compensation', id: 'compensation' }] :
                            llpModal?.eol?.lease_type?.value == 1 ?  [{ label: 'Module Type', id: 'module_type' }, { label: 'LLP Description', id: 'llp_desc' }, { label: 'Expected Condition (FC)', id: 'expected_condition' }, { label: 'Actual Condition (FC)', id: 'actual_condition' }, { label: 'MR Accruals', id: 'mr_accruals' }] :
                                [{ label: 'Module Type', id: 'module_type' }, { label: 'LLP Description', id: 'llp_desc' }, { label: 'Expected Condition (FC)', id: 'expected_condition' }, { label: 'Actual Condition (FC)', id: 'actual_condition' }, { label: 'MR Accruals', id: 'mr_accruals' }, { label: 'Lessor to Lessee', id: 'lessor-lessee' }, { label: 'Lessee to Lessor', id: 'lessee-lessor' }]
                            }
                            data={llpModal.data?.analysis?.llp_detail_analysis.map((item, llpIndex) =>
                                <EolLlpModalDetails simulationType={simulationType} simulatedItem={simulatedEolItem?.analysis && simulatedEolItem?.analysis.llp_detail_analysis && simulatedEolItem?.analysis.llp_detail_analysis[llpIndex] ? simulatedEolItem?.analysis.llp_detail_analysis[llpIndex] : {}} item={item} llpModal={llpModal} />
                            )}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setLLpModal(false)} variant='contained' color='primary'>
                            Okay
                        </Button>
                    </DialogActions>
                </Dialog>
                : null
            }
        </>
    )
}
