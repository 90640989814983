import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { Paper, Table, Grid, TableBody, TableCell, TableRow, Tab, Tabs, Button, Tooltip } from '@material-ui/core';
import { FleetHeader, FleetList, CompareLogs, AddAssembly, EditAssembly, LinkPortfolioAssembly, ImportFleet } from '../components';
import { globalGetService, globalPostService, globalExportService } from '../../../utils/globalApiServices';
import { FilterComponent, Pagination, PageLoader, EmptyCollection, DeletePopUp } from '../../../shared_elements'
import { STableLoader } from '../../../shared_elements/loaders';
import InfoIcon from '@material-ui/icons/Info';
import { checkApiStatus, downloadFileType, onCheckPermission } from '../../../utils';
import { fleetFilters, addAssemblyObj } from '../'
import { ArrowBack, Info } from '@material-ui/icons';
import ViewListIcon from '@material-ui/icons/ViewList';
import BarChartIcon from '@material-ui/icons/BarChart';
import moment from 'moment';
import FleetPortfolioGraphs from '../components/FleetPortfolioGraphs';
const PortfolioDetail = ({ match, params, props }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [tabIndex, setTabIndex] = useState('airframe');
    const [toggleView, setToggleView] = useState('list');
    const [compareLogs, setCompareLogs] = useState({ modal: false, data: [] })
    const [portfolioDetails, setPortfolioDetails] = useState({})
    const [assemblyInfo, setAssemblyInfo] = useState({ list: [1, 1, 1, 1], pagination: { page: 1, per_page: 10, total: 100 } });
    const [filter, setFilter] = useState({});
    const [skeletonLoader, setSkeletonLoader] = useState(false)
    const [isLoading, setLoading] = useState(false);
    const [addEditAssembly, setAddEditAssembly] = useState({ modal: false, mode: '', assemblyType: '', data: null })
    const [addAssemblyObj, setAddAssemblyObj] = useState({})
    const [selectedAssets, setSelectedAssets] = useState([])
    const [unlinkAssembly, setUnlinkAssembly] = useState({ modal: false, data: null })
    const [linkAssembly, setLinkAssembly] = useState({ modal: false, data: null });
    useEffect(() => {
        getPortfolioDetails()
        getAssemblyInfo({ ...filter, asset: tabIndex }, 'skeletonLoader');
    }, []);
    const onAssetSelection = (flag, id) => {
        if (flag && selectedAssets?.length < 10) {
            setSelectedAssets([...selectedAssets, id])
        } else {
            if (selectedAssets?.length == 10 && flag) {
                enqueueSnackbar('Maximum selection limit reached. You can select up to 10 logs. ', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            }
            setSelectedAssets(selectedAssets.filter(item => item !== id))
        }
    }
    const onCompareLogs = () => {
        globalGetService('new-mr-calculator/fleet-portfolio-list/', { module_type: 'portfolio-list', portfolio_slug: match.params.slug, asset: tabIndex, compare_logs: selectedAssets.join(',') })
            .then(response => {
                if (checkApiStatus(response)) {
                    setCompareLogs({ modal: true, data: response.data.data })
                }
            })
    }
    const onExportCompareReport = () => {
        setLoading(true)
        globalExportService('new-mr-calculator/fleet-portfolio-list/', { module_type: 'portfolio-list', portfolio_slug: match.params.slug, asset: tabIndex, compare_logs: selectedAssets.join(','), download: 'xlsx' })
            .then(response => {
                downloadFileType(response.data, 'Portfolio_Details_Comparision_Export', 'xlsx');
                setLoading(false)
            })
    }

    const getPortfolioDetails = () => {
        globalGetService('new-mr-calculator/fleet-portfolio-list/', { module_type: 'portfolio', portfolio_slug: match.params.slug })
            .then(response => {
                if (checkApiStatus(response)) {
                    setPortfolioDetails(response.data.data);
                    setAddAssemblyObj({ ...addAssemblyObj, portfolio_slug: match.params.slug })
                }
            })
    }
    const getAssemblyInfo = (query = {}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true);
        globalGetService('new-mr-calculator/fleet-portfolio-list/', { ...query, module_type: 'portfolio-list', portfolio_slug: match.params.slug })
            .then(response => {
                if (checkApiStatus(response)) {
                    setAssemblyInfo(response.data.data)
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false);
            })
    }
    const onUnlinkAssembly = () => {
        setLoading(true)
        globalPostService(`new-mr-calculator/portfolio-list/${match.params.slug}/unlink/`, { asset: tabIndex, id: [unlinkAssembly.data.id] })
            .then(response => {
                if (checkApiStatus(response)) {
                    setUnlinkAssembly({ modal: false, data: null });
                    setLoading(false)
                    getAssemblyInfo({ asset: tabIndex }, 'skeletonLoader');
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                } else {
                    setLoading(false)
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
            })
    }
    const onExportReport = (query = {}) => {
        setLoading(true);
        globalExportService(`new-mr-calculator/fleet-portfolio-list/`, { ...query, module_type: 'portfolio-list', portfolio_slug: match.params.slug, download: 'xlsx' })
            .then(response => {
                downloadFileType(response.data, 'Portfolio_Details_List_Export', 'xlsx');
                setLoading(false);
            })
    }
    let filterOptions = Object.assign({}, fleetFilters);
    let history = useHistory();
    return (
        <section className='portfolio-fleet-section'>
            <ul className="list-unstyled" style={{ display: 'flex' }}>
                <li className='list-inline-item go-back-icon' onClick={() => history.goBack()} >
                    <ArrowBack style={{ fontSize: '18px' }} /> Back to Listing
                </li>
                <li className='list-inline-item' >
                    &nbsp; &nbsp; {portfolioDetails?.name || ''}
                    <Tooltip title={<div>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Status : {portfolioDetails?.status?.label || '--'} </td>
                                </tr>
                                <tr>
                                    <td>Created By: {portfolioDetails?.created_by?.name || '--'} </td>
                                </tr>
                                <tr>
                                    <td>Created At: {portfolioDetails?.created_at ? moment(portfolioDetails?.created_at).format('MMM DD, YYYY') : '--'} </td>
                                </tr>
                                <tr>
                                    <td>Remarks : {portfolioDetails?.remarks || '--'} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>} arrow placement='right'>
                        <InfoIcon fontSize="small" style={{ verticalAlign: 'bottom', marginLeft: '3px', fontSize: "18px" }} />
                    </Tooltip>
                </li>
            </ul>
            {skeletonLoader ? <STableLoader count={9} /> :
                <>
                    <div className='fleet-tabs'>
                        <Grid container spacing={1} alignItems='center'>
                            <Grid item md={8}>
                                {
                                    toggleView == 'graph' ? null :
                                        <Paper>
                                            <Tabs indicatorColor='primary' value={tabIndex} onChange={(event, newValue) => { setTabIndex(newValue); getAssemblyInfo({ ...filter, asset: newValue }, 'pageLoader'); setSelectedAssets([]) }} variant='fullWidth'>
                                                <Tab label="Airframe" value='airframe' />
                                                <Tab label="Engines" value='engines' />
                                                <Tab label="APU" value='apu' />
                                                <Tab label="Landing Gears" value='lg' />
                                            </Tabs>
                                        </Paper>
                                }
                            </Grid>
                            <Grid item md={4}>
                                <ul className='list-inline' style={{ float: 'right', display: 'flex',marginTop: toggleView == 'graph' ? '8px' : '0px' }}>
                                    <li className='list-inline-item' style={{ marginLeft: "10px" }}>
                                        <span className='list-analytic-sw' style={{ width: '80px' }}>
                                            <span className={toggleView == 'list' ? 'active' : ''}>
                                                <ViewListIcon color={toggleView == 'list' ? 'primary' : 'default'} onClick={() => setToggleView('list')} />
                                            </span>
                                            <span className={toggleView == 'graph' ? 'active' : ''}>
                                                <BarChartIcon onClick={() => { setToggleView('graph') }} color={toggleView == 'graph' ? 'primary' : 'default'} />
                                            </span>
                                        </span>
                                    </li>
                                    <li className='list-inline-item'>
                                        <ImportFleet
                                            portfolio_slug={match.params.slug}
                                            moduleType='portfolio-list'
                                            getResponseBack={() => getAssemblyInfo({ ...filter, page: 1, asset: tabIndex }, 'pageLoader')}
                                        />
                                    </li>
                                    <li className='list-inline-item'>
                                        <Button disabled={selectedAssets.length > 1 ? false : true} onClick={() => onCompareLogs()} color='primary' size='small' variant='contained'>Compare Log</Button>
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>
                    </div>
                    {
                        toggleView == 'graph' ?
                            <div>
                                <FleetPortfolioGraphs
                                    portfolio_slug={match.params.slug}
                                    module={'portfolio'}
                                />
                            </div> :
                            <>
                                <Paper className='fleet-filter-div' square>
                                    <Grid container spacing={1}>
                                        <Grid item md={8}>
                                            <FilterComponent
                                                filter={filter}
                                                filterMenu={filterOptions}
                                                getResponseBack={(applyFilter) => getAssemblyInfo({ ...applyFilter, page: 1, per_page: assemblyInfo.pagination.per_page, asset: tabIndex }, 'pageLoader')}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <ul className='list-inline'>
                                                {onCheckPermission('mr_calculator', 'portfolio', 'C') ?
                                                    <li className='list-inline-item'>
                                                        <Button onClick={() => setAddEditAssembly({ modal: true, mode: 'add', assemblyType: tabIndex, data: addAssemblyObj })} color='primary' size='small' variant='contained'>Add Assembly</Button>
                                                    </li>
                                                    : null
                                                }
                                                <li className='list-inline-item'>
                                                    <Button color='primary' size='small' variant='outlined' onClick={() => onExportReport({ asset: tabIndex })}>Export</Button>
                                                </li>
                                            </ul>
                                        </Grid>
                                    </Grid>
                                </Paper>

                                <Paper>
                                    <Paper className='fleet-log-section' style={tabIndex ? { overflow: 'scroll' } : {}}>
                                        <Table className={`mui-styled-table fleet-log-listing  ${tabIndex ? 'engine-log-list' : ''}`} style={tabIndex ? { width: 'max-content', minWidth: "100%" } : {}}>
                                            <FleetHeader tabIndex={tabIndex} />
                                            <TableBody>
                                                {assemblyInfo.list.map((item, itemIndex) =>
                                                    <FleetList
                                                        item={item}
                                                        tab={'portfolio'}
                                                        tabIndex={tabIndex}
                                                        selectedAssets={selectedAssets}
                                                        onAssetSelection={onAssetSelection}
                                                        onEdit={() => setAddEditAssembly({ modal: true, mode: 'edit', assemblyType: tabIndex, data: item })}
                                                        onDelete={() => setUnlinkAssembly({ modal: true, data: item })}
                                                    />
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                    <Pagination
                                        pagination={assemblyInfo.pagination}
                                        onChangePage={(event, newPage) => getAssemblyInfo({ page: newPage + 1, per_page: assemblyInfo.pagination.per_page, asset: tabIndex }, 'pageLoader')}
                                        onChangeRowsPerPage={(event) => getAssemblyInfo({ page: 1, per_page: event.target.value, asset: tabIndex }, 'pageLoader')}
                                    />
                                    {assemblyInfo.list.length ? null : <EmptyCollection />}
                                </Paper>
                            </>
                    }
                </>
            }
            {addEditAssembly.modal ?
                <AddAssembly
                    tabIndex={tabIndex}
                    addEditAssembly={addEditAssembly}
                    getResponseBack={() => getAssemblyInfo({ ...filter, asset: tabIndex, page: 1 }, 'pageLoader')}
                    toggleModalFn={() => setAddEditAssembly({ modal: false, mode: '', assemblyType: '', data: null })}
                    addEditModuleType='portfolio'
                /> : null
            }
            {compareLogs.modal ?
                <CompareLogs
                    onExportCompareReport={onExportCompareReport}
                    compareLogs={compareLogs}
                    toggleModalFn={() => setCompareLogs({ modal: false, data: [] })}
                /> : null
            }
            {unlinkAssembly.modal ?
                <DeletePopUp
                    modal={unlinkAssembly.modal}
                    toggleModalFn={() => setUnlinkAssembly({ modal: false, data: null })}
                    title="Unlink Assembly"
                    content={<h4>Are you sure you want to unlink?</h4>}
                    deleteRecordFn={onUnlinkAssembly}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </section>
    )
}
export default PortfolioDetail;