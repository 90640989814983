import React, { useEffect, useState } from 'react'
import { Grid, TextField, Button, Accordion, AccordionSummary, AccordionDetails, TableCell, Table, TableRow, Tooltip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { TableListComp } from '../../../shared_elements';
import EOLConditionList from './EOLConditionList';
import { Add, Delete, ExpandMore } from '@material-ui/icons';
import { regexConstants } from '../../../constants/regEx';
import { getLocalStorageInfo, onCheckPermission } from '../../../utils';
export default function EOICard({ eoiUpdateApi, eolErrors, onFocusEolConidtion, props, onDeleteEOLContent, getEolScenariosApi, eol_scenarios, onEoiFieldChange, eol_conditionList, addNewEolScenarios, addEoiConditions, deleteEolConditions, onFieldChangeEolCndtn, pltConstants, eoiError, onFocusEOL, mrLogDetail }) {
   const [editPermission, setEditPermission]= useState(false)
   const [accordianArr, setAccordianArr]= useState([0,1,2])
   const [accordianState, setAccordianState] =useState(true)
    useEffect(() => {
        if(onCheckPermission('mr_calculator','analyze_mr_cash_flow','C' ) || onCheckPermission('mr_calculator','analyze_mr_cash_flow','U' )  ){
            setEditPermission(true)
        }
    }, []);
   const onChangeAccordian=(index)=>{
        if(accordianArr.includes(index)){
            let accArr= accordianArr.filter( item => item !=index )
            setAccordianArr(accArr)
        }
        else{
            setAccordianArr([...accordianArr , index])
        }
    }
    return (
        <div className='eoi-assembly-card' >
            <div className="action-eoi"  >
                { editPermission?<Button variant="contained" onClick={eoiUpdateApi} size='small' color="primary" style={{ marginRight: '5px', float: 'right' }}>Save</Button> : null}
                <div className='eoi-assembly-event' style={{ display: 'flex', alignItems: 'center', padding: '0px 14px', border: 'none' }}>
                    <h3>Lease Details</h3>
                    {editPermission ? <Button variant="outlined" onClick={() => addNewEolScenarios(eol_scenarios)} color={'primary'} size='small' style={{ marginLeft: '10px' }}>  <Add /> Add</Button> : null}
                    
                    <Grid item md={6}>
                        {
                            mrLogDetail.airframe ?
                                <Table border={1} borderColor='#d7d7d7' style={{ marginLeft: '22px' }}>
                                    <TableRow>
                                        <TableCell width="33.33%">
                                            <p>Aircraft Type</p>
                                            <h4>{mrLogDetail?.generalInfo?.aircraftType.name}</h4>
                                        </TableCell>
                                        <TableCell width="33.33%">
                                            <p>Maintenance Program</p>
                                            <h4>
                                                {mrLogDetail?.generalInfo?.maintenance_program[Object.keys(mrLogDetail?.generalInfo?.maintenance_program)[0]].split(',').join('/')}
                                            </h4>
                                        </TableCell>
                                        <TableCell width="33.33%">
                                            <p>Date of Manufacture</p>
                                            <h4>
                                                {moment(mrLogDetail?.generalInfo?.aircraft_manufacture_date).format(displayDateFormatShort)}
                                            </h4>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                :
                                mrLogDetail?.engines ?
                                <Table border={1} borderColor='#d7d7d7' style={{ marginLeft: '22px' }}>
                                    <TableRow>
                                        <TableCell width={'33.33%'}>
                                            <p>Engine Type</p>
                                            <h4>{mrLogDetail.generalInfo.engineType && mrLogDetail.generalInfo.engineType.name ? mrLogDetail.generalInfo.engineType.name : '--'}</h4>
                                        </TableCell>
                                        <TableCell width={'33.33%'}>
                                            <p>Region Of Operation</p>
                                            <h4>
                                                {mrLogDetail?.location?.address ?
                                                    mrLogDetail?.location?.address.length > 20 ? <Tooltip title={mrLogDetail?.location?.address}><span>{`${mrLogDetail?.location?.address.substr(0, 20)}...`}</span></Tooltip> :
                                                        mrLogDetail?.location?.address :
                                                    (mrLogDetail.generalInfo.engineType && mrLogDetail.generalInfo.regionOfOperation.name ? mrLogDetail.generalInfo.regionOfOperation.name : '--')
                                                }
                                            </h4>
                                        </TableCell>
                                        <TableCell width={'33.33%'}>
                                            <p>Utilization Ratio</p>
                                            <h4>{`${mrLogDetail.generalInfo.ratio}:1`}</h4>
                                        </TableCell>
                                    </TableRow>
                                </Table> :null
                        }
                    </Grid>
                </div>
            </div>
            {
                eol_scenarios.length ?
                null:
                <div style={{marginLeft:'12px', marginTop:'10px', fontSize:'14px'}}>Click +ADD to enter the Lease details along with the return conditions.</div>
            }
            {eol_scenarios?.map((lease, index) =>
                <Accordion expanded={accordianArr.includes(index)} style={{border:'1px solid #c7c7c7'}}>
                    <AccordionSummary className='lease-count-head' onClick={()=>onChangeAccordian(index)} expandIcon={<ExpandMore />}>
                        <h3> Lease {index + 1} </h3>
                        { onCheckPermission('mr_calculator','analyze_mr_cash_flow','D' )?
                            <Delete variant='contained' color='secondary' onClick={(e) => { onDeleteEOLContent(index, null) }} />
                            :null 
                        }
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>
                        <div style={{ marginBottom: '24px' }}>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            required
                                            label='Lease Start Date'
                                            margin="normal"
                                            id={`lease_start_date${index}`}
                                            format={fieldDateFormat}
                                            fullWidth
                                            disabled={editPermission? false:true}
                                            inputVariant="outlined"
                                            minDate={(index > 0 && eol_scenarios && eol_scenarios[index - 1]?.lease_end_date) ? eol_scenarios[index - 1].lease_end_date : undefined}
                                            error={eolErrors && eolErrors[index] && eolErrors[index].lease_start_date ? true : false}
                                            helperText={eolErrors && eolErrors[index] && eolErrors[index].lease_start_date}
                                            InputLabelProps={{ shrink: true }}
                                            minDateMessage={"Should be greater than previous lease end date"}
                                            value={lease?.lease_start_date ? lease.lease_start_date : null}
                                            onFocus={() => onFocusEOL(index, 'lease_start_date')}
                                            onChange={(data, value) => { onEoiFieldChange('lease_start_date', moment(data).format(backendDateFormat), index) }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        className='lease-duration'
                                        label='Lease End Term (in MO)'
                                        id={`lease_duration${index}`}
                                        fullWidth
                                        margin="normal"
                                        disabled={editPermission? false:true}
                                        value={lease?.lease_duration ? lease.lease_duration : ''}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        // InputProps={{
                                        //     readOnly: true,
                                        //   }}
                                        onChange={(e) => regexConstants.onlyNumeric.test(e.target.value) ? onEoiFieldChange('lease_duration', e.target.value, index) : e.preventDefault()}
                                    />
                                </Grid>
                                <Grid item xs={3}><MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        required
                                        label='Lease End Date'
                                        margin="normal"
                                        id={`lease_end_date${index}`}
                                        format={fieldDateFormat}
                                        fullWidth
                                        minDate={lease.lease_start_date ? moment(lease?.lease_start_date).add(1, 'month') : undefined}
                                        minDateMessage={"Lease end date should be 1 Month after lease start date"}
                                        InputLabelProps={{ shrink: true }}
                                        inputVariant="outlined"
                                        disabled={editPermission? false:true}
                                        error={eolErrors && eolErrors[index] && eolErrors[index].lease_end_date ? true : false}
                                        helperText={eolErrors && eolErrors[index] && eolErrors[index].lease_end_date? eolErrors[index].lease_end_date : null}
                                        onFocus={() => onFocusEOL(index, 'lease_end_date')}
                                        value={lease?.lease_end_date ? lease.lease_end_date : null}
                                        onChange={(data, value) => { onEoiFieldChange('lease_end_date', moment(data).format(backendDateFormat), index) }}
                                    />
                                </MuiPickersUtilsProvider></Grid>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        options={pltConstants.filter(item => item.type == 'eol_lease_type')}
                                        getOptionLabel={option => option.label}
                                        id={`lease_type${index}`}
                                        disabled={editPermission? false:true}
                                        value={lease?.lease_type ? lease.lease_type : null}
                                        onChange={(e, value) => { onEoiFieldChange('lease_type', value, index); }}
                                        onFocus={() => onFocusEOL(index, 'lease_type')}
                                        renderInput={params => <TextField required  {...params} margin="normal" label='Lease Type' fullWidth InputLabelProps={{ shrink: true }} error={eolErrors && eolErrors[index] && eolErrors[index].lease_type ? true : false} helperText={eolErrors && eolErrors[index] && eolErrors[index].lease_type} variant="outlined" />}
                                    />
                                </Grid>
                                

                            </Grid>
                        </div>
                        <div style={{ border: '1px solid #cfcfcf' }}>
                            <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                                <h4 style={{ margin: '14px 0px' }}>EOL Conditions</h4>
                                { editPermission && eol_conditionList.length > lease.eol_conditions.length ?
                                    <Button variant="outlined" onClick={() => addEoiConditions(index)} size='small' color={'primary'} style={{ marginLeft: '10px' }}>  <Add /> Add</Button>
                                    : null
                                }
                            </div>
                            <TableListComp
                                heads={[{ label: 'Component', id: '1' }, { label: ' Expected Mnt Condition', id: '3' }, { label: 'Expected EOL Condition', id: '4' },{}]}
                                data={lease?.eol_conditions?.map((itemObj, itemIndex) =>
                                    <EOLConditionList
                                        error={eolErrors && eolErrors[index] && eolErrors[index].conditionError && eolErrors[index].conditionError[itemIndex] ? eolErrors[index].conditionError[itemIndex] : {}}
                                        onDeleteEOLContent={onDeleteEOLContent}
                                        itemObj={itemObj}
                                        editPermission={editPermission}
                                        eol_scenarios={eol_scenarios}
                                        onFocusEolConidtion={onFocusEolConidtion}
                                        pltConstants={pltConstants}
                                        itemIndex={itemIndex}
                                        lease={lease}
                                        leaseIndex={index}
                                        eol_conditionList={eol_conditionList}
                                        onFieldChangeEolCndtn={onFieldChangeEolCndtn}
                                    />
                                )}
                            />
                        </div>
                    </AccordionDetails>
                </Accordion>
            )}
        </div>
    )
}
