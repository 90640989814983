import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Paper, Button, Dialog, DialogContent, DialogTitle, DialogActions, Grid, TextField, CircularProgress, InputAdornment } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { PageLoader, GoogleLocationField } from '../../../shared_elements'
import { CustomzieLLPModal, RegionCustomVariable } from '../../mr_calc/components';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import { globalGetService, globalPostService, globalPutService } from '../../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo, removeEmptyKey, capitalizeFirstLetter } from '../../../utils';
import LlpPopupModal from './LlpPopupModal';
const AddAssembly = ({addEditAssembly, toggleModalFn, getResponseBack, tabIndex, addEditModuleType}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [positionDropDown, setPositionDropDown] = useState([]);
    const [isfetchingList, setFetchingList] = useState(false);
    const [formData, setFormData] = useState(addEditAssembly.data);
    const [error, setError] = useState({});
    const [assetsOptions, setAssetsOptions] = useState([]);
    const [maintenancePrograms, setMaintenancePrograms] = useState([]);
    const [assetAircraftTypes, setAssetAircraftTypes] = useState([]);
    const [regionCondition, setRegionCondition] = useState({});
    const [adjustRegionVar, setAdjustRegionVar] = useState(false);
    useEffect(() => {
        getAssetPositionDrpdwn()
        getAssetsTypes();
        if (tabIndex === 'apu' || tabIndex === 'lg') {
            getAssetsAircraftTypes();
        }
        if (addEditAssembly.mode === 'edit' && tabIndex === 'engines') {
            getRegionVariables({engine_type:formData?.asset_type?.id, region_id:formData?.region_of_operation?.id, assign_default:formData?.variable_condition})
        }
    },[]);
    const getAssetsTypes = () => {
        let url = '';
        setFetchingList(true);
        url = tabIndex === 'airframe' ? 'console/aircraft-types/' : 
        tabIndex === 'engines' ? 'console/engine-types/' : 
        tabIndex === 'apu' ? 'console/apu-types/' : '';
        globalGetService(url, {'mr-calculator': 'mr-calculator'})
        .then(response => {
            setFetchingList(false);
            if(checkApiStatus(response)){
                if (tabIndex === 'airframe') {
                    setAssetsOptions(response.data.data.aircraftTypes);
                    if (addEditAssembly.mode === 'edit' && addEditAssembly.data.asset === 'airframe' && addEditAssembly.data.asset_type) {
                        getMaintenancePrograms(addEditAssembly.data.asset_type)
                    }
                } else if (tabIndex === 'engines') {
                    setAssetsOptions(response.data.data.engineTypes);
                } else if (tabIndex === 'apu') {
                    setAssetsOptions(response.data.data.apu_types);
                } else {}
            }
        });
    }
    const getAddressLocationDetails = (address) => {
        if(address){
            let regionObj = {};
            address.address_components.map(location => {
                let types = location.types[0];
                switch (types) {
                case 'country':
                    regionObj = {
                    ...regionObj,
                    country_name: location.long_name,
                    country_iso_2: location.short_name
                    }
                    return
                case "administrative_area_level_1":
                    regionObj = {
                    ...regionObj,
                    state_name: location.long_name,
                    state_code: location.short_name
                    }
                    return
                case "administrative_area_level_2":
                    regionObj = {
                    ...regionObj,
                    city_name: location.long_name,
                    city_code: location.short_name
                    }
                    return
                default:
                    return
                }
            });
            regionObj = {
                ...regionObj,
                address: `${regionObj.city_name ? regionObj.city_name: ''}${regionObj.city_name ? ', ' : ''}${regionObj.state_name ? regionObj.state_name : ''}${regionObj.state_name ? ', ' :''}${regionObj.country_name}`
            }
            if(tabIndex === 'engines' && formData?.asset_type?.id){
                getRegionVariables({engine_type:formData?.asset_type?.id, location:regionObj})
            }
            return regionObj;
        }
    }
    const getRegionVariables = (data) => {
        globalPostService(`console/regions_maps/`, data)
        .then(response => {
            if(checkApiStatus(response)){
                setRegionCondition(response.data.data);
            }
        })
    }
    
    const onChangeRegionCond = (value, index) => {
        if(adjustRegionVar){
            setRegionCondition({
                ...regionCondition,
                variable_condition:{
                    ...regionCondition.variable_condition,
                    [index]:{
                        ...regionCondition.variable_condition[index],
                        default:value
                    }
                }
            })
        }
    }
    const getAssetPositionDrpdwn =()=>{
        let query = {constant_types:['lg_position','engine_position']}
        globalPostService(`console/get-constants/`,query)
        .then(response=>{
            setPositionDropDown(response.data.data)
        })
    }
    const resetRegionVariables = () => {
        if(adjustRegionVar){
            setAdjustRegionVar(false);
            globalGetService(`console/regions/`,{region_id:regionCondition.current_region.id,engine_type:formData.asset_type.id})
            .then(response => {
                if(checkApiStatus(response)){
                    setRegionCondition({...regionCondition, variable_condition:response.data.data.variable_condition})
                }
            })
        }else{
            setAdjustRegionVar(true);
        }
    }
    const updateRegionVariables = () => {
        let variable_condition = [];
        Object.keys(regionCondition.variable_condition).map((label) => {
            if(regionCondition.variable_condition[label].default){
                variable_condition.push(regionCondition.variable_condition[label].default);
            }
        });
        setFormData({...formData,variable_condition:variable_condition})
        setAdjustRegionVar(false);
        enqueueSnackbar('Variable got stored', { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
    }
    const getMaintenancePrograms = (aircraftType) => {
        globalGetService(`console/aircraft-type/${aircraftType.id}/events/`, {list: true})
        .then(response => {
            if(checkApiStatus(response)){
                setMaintenancePrograms(response.data.data);
            }
        })
    }
    const getAssetsAircraftTypes = () => {
        globalGetService('console/aircraft-types/', {'mr-calculator': 'mr-calculator'})
        .then(response => {
            if(checkApiStatus(response)){
                setAssetAircraftTypes(response.data.data.aircraftTypes);
            }
        })
    }
    const onFieldChange = (key, value) => {
       setError({...error, [key]:''})
        setFormData({...formData, [key]:value});
        if (tabIndex === 'airframe' && key === 'asset_type') {
            getMaintenancePrograms(value)
        }
    }
    const onAddEditAssembly = () => {
        let validationInputs = {
            serial_number: formData.serial_number ? '':'Please enter Serial Number',
            asset_type: tabIndex === 'lg' || formData.asset_type ? '':'Please select Asset Type',
            cycles: (['engines', 'lg'].includes(tabIndex) && formData.cycles) || ['airframe', 'apu'].includes(tabIndex) ? '':'Please select Cycles',
            hours: (['engines', 'apu'].includes(tabIndex) && formData.hours) || ['airframe', 'lg'].includes(tabIndex) ? '':'Please select Hours',
            date_of_manufacture: formData.lsv_date || formData.date_of_manufacture || tabIndex === 'engines' ? formData.date_of_manufacture ? moment(formData.date_of_manufacture, 'YYYY-MM-DD', true).isValid() ? '':'Please enter valid Date of Manufacture' : '':'Please select Date of Manufacture',
            maintenance_program: ['engines', 'apu', 'lg'].includes(tabIndex) || formData.maintenance_program ? '':'Please select Maintenance Program',
            as_of_date: formData.as_of_date &&  moment(formData.as_of_date, 'YYYY-MM-DD', true).isValid()  ? '':'Please enter As of Date',
            lsv_date:  formData.lsv_date  || formData.date_of_manufacture  ? formData.lsv_date ? moment(formData.lsv_date, 'YYYY-MM-DD', true).isValid() ? '':'Please enter valid LSV Date ' : '': tabIndex === 'engines'  && !(formData.tsn_at_lsv ||formData.csn_at_lsv ) ? ''  : 'Please select LSV Date',
            tsn_at_lsv: (formData.lsv_date && formData.tsn_at_lsv) || tabIndex === 'airframe' ? '': ['engines', 'apu'].includes(tabIndex) && formData.lsv_date ? 'Please enter TSN @ LSV': '',
            csn_at_lsv: (formData.lsv_date && formData.csn_at_lsv) || tabIndex === 'airframe' ? '': ['engines', 'lg'].includes(tabIndex) && formData.lsv_date ?'Please enter CSN @ LSV':'',
            status: tabIndex === 'airframe' || formData.status ? '':'Please select Status',
            asset_aircraft_type: ['airframe', 'engines'].includes(tabIndex) || formData.asset_aircraft_type ? '':'Please select Aircraft Type',
        };
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true);
            if(tabIndex === 'engines'){
                setFormData({
                    ...formData,
                    regionOfOperation:regionCondition.custom_region
                })
            }
            if(formData.id){
                globalPutService(`new-mr-calculator/fleet-portfolio/`, {...formData, module_type:addEditModuleType, asset:tabIndex})
                .then(response => {
                    if(checkApiStatus(response)){
                        toggleModalFn();getResponseBack();
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                })
            }else{
                globalPostService(`new-mr-calculator/fleet-portfolio/`, {...formData, module_type:addEditModuleType, asset:tabIndex})
                .then(response => {
                    if(checkApiStatus(response)){
                        toggleModalFn();getResponseBack();
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                })
            }
        }else{
            enqueueSnackbar('Please update mandatory information ', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            setError(validationInputs);
        }
    }
    return(
        <>
            <Dialog
                open={addEditAssembly.modal}
                onClose={toggleModalFn}
                className='console-management-modal'
                maxWidth='md'
            >
                <DialogTitle id="scroll-dialog-title">
                    {formData.id ?'Edit':'Add'} {capitalizeFirstLetter(tabIndex == 'apu' ? 'APU': tabIndex)} Assembly
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid container spacing={1}>
                        <Grid item md={4}>
                            <TextField 
                                required
                                id='serial_number'
                                label='Serial Number'
                                value={formData?.serial_number||''}
                                fullWidth
                                margin="normal"
                                error={error?.serial_number?true:false}
                                helperText={error?.serial_number||''}
                                onChange={(e) => onFieldChange('serial_number', e.target.value)}
                                onFocus={() => setError({...error, 'serial_number':''})}
                                InputLabelProps={{shrink: true}}
                                variant='outlined'
                            />
                        </Grid>
                        { tabIndex !== 'lg' ?
                            <Grid item md={4}>
                                <Autocomplete
                                    options = {assetsOptions}
                                    getOptionLabel={option => option.name}
                                    id="asset_type"
                                    disabled={addEditAssembly.mode === 'edit' || isfetchingList ? true : false}
                                    value={formData?.asset_type||null}
                                    onChange={(e, value) => {onFieldChange('asset_type', value) ;{formData?.location? getRegionVariables({engine_type:value?.id, location:formData?.location}): console.log(''); } } }
                                    renderInput={params => <TextField required error={error?.asset_type ? true:false} helperText={error?.asset_type||''} onFocus={() => setError({...error, asset_type:''})} {...params} label="Asset Type" placeholder= {isfetchingList ? 'Fetching the List' :"Select Asset Type"} margin="normal" fullWidth InputLabelProps={{shrink: true}} variant="outlined" />}
                                />
                            </Grid>: null
                        }
                        { ['engines','lg'].includes(tabIndex) ?
                            <Grid item md={4}>
                                <Autocomplete
                                    options = {positionDropDown.filter(item => tabIndex =='lg'?  item.type ==="lg_position" :  item.type ==="engine_position" ) }
                                    getOptionLabel={option => option.label}
                                    id="position"
                                    // disabled={isfetchingList ? true : false}
                                    value={formData?.position||null}
                                    onChange={(e, value) => {onFieldChange('position', value)} }
                                    renderInput={params => <TextField {...params} label="Position" placeholder= {"Select Position"} margin="normal" fullWidth InputLabelProps={{shrink: true}} variant="outlined" />}
                                />
                            </Grid>: null
                        }
                        { tabIndex !== 'engines' ?
                            <Grid item md={4}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        required
                                        margin="normal"
                                        id="date_of_manufacture"
                                        label="Date of Manufacture"
                                        format={fieldDateFormat}
                                        fullWidth
                                        disableFuture
                                        disabled={addEditAssembly.mode === 'edit' ? true : false}
                                        minDate={moment().subtract(50, 'years')}
                                        InputLabelProps={{shrink: true}}
                                        value={formData?.date_of_manufacture||null}
                                        error={error?.date_of_manufacture ? true:false}
                                        helperText={error?.date_of_manufacture||''}
                                        onChange={(data, value) => {onFieldChange('date_of_manufacture', data ? moment(data).format(backendDateFormat):data);}}
                                        inputVariant="outlined"
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>:null
                        }
                        { tabIndex === 'airframe' || tabIndex === 'engines' ?
                            <Grid item xs={12} md={4}>
                                <GoogleLocationField 
                                onFieldChange={(event, key, value) => onFieldChange('location', getAddressLocationDetails(value))}
                                location={formData?.location?.address ? formData.location.address :''}
                                />
                            </Grid>:null
                        }
                        {
                           ['engines','apu'].includes(tabIndex)?
                           <>

                           </> : null
                        }
                        { tabIndex === 'airframe' ?
                            <Grid item md={4}>
                                <Autocomplete
                                    options = {maintenancePrograms}
                                    getOptionLabel={option => option.name}
                                    id="maintenance_program"
                                    disabled={addEditAssembly.mode === 'edit' ? true : false}
                                    value={formData?.maintenance_program||null}
                                    onChange={(e, value) => onFieldChange('maintenance_program', value)}
                                    renderInput={params => <TextField required error={error?.maintenance_program ? true:false} helperText={error?.maintenance_program||''} onFocus={() => setError({...error, maintenance_program:''})} {...params} label="Maintenance Program" placeholder="Select Maintenance Program" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant="outlined" />}
                                />
                            </Grid>:null
                        }
                        { tabIndex !== 'lg' ?
                            <Grid item md={4}>
                                <TextField 
                                    required = {tabIndex === 'airframe' ? false : true}
                                    id='hours'
                                    label='Avg. Hours'
                                    value={formData?.hours ? formData.hours : (formData?.hours||'')}
                                    fullWidth
                                    margin="normal"
                                    error={error?.hours?true:false}
                                    helperText={error?.hours||''}
                                    // disabled={formData?.hours && addEditModuleType === 'portfolio' ? true : false}
                                    onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('hours', e.target.value):e.preventDefault()}}
                                    onFocus={() => setError({...error, 'hours':''})}
                                    InputLabelProps={{shrink: true}}
                                    variant='outlined'
                                />
                            </Grid>:null
                        }
                        { tabIndex !== 'apu' ?
                            <Grid item md={4}>
                                <TextField 
                                    required = {tabIndex === 'airframe' ? false : true}
                                    id='cycles'
                                    label='Avg. Cycles'
                                    value={formData?.cycles ? formData.cycles : (formData?.cycles||'')}
                                    fullWidth
                                    margin="normal"
                                    error={error?.cycles?true:false}
                                    helperText={error?.cycles||''}
                                    // disabled={formData?.cycles && addEditModuleType === 'portfolio' ? true : false}
                                    onChange={(e) => {regexConstants.numberWithDot.test(e.target.value)?onFieldChange('cycles', e.target.value):e.preventDefault()}}
                                    onFocus={() => setError({...error, 'cycles':''})}
                                    InputLabelProps={{shrink: true}}
                                    variant='outlined'
                                />
                            </Grid>:null
                        }
                        <Grid item md={4}>
                            <TextField 
                                required={tabIndex === 'engines'?true:false}
                                id='tsn'
                                label='TSN'
                                value={formData?.tsn||''}
                                fullWidth
                                margin="normal"
                                onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('tsn', e.target.value):e.preventDefault()}}
                                InputLabelProps={{shrink: true}}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField 
                                required={tabIndex === 'engines'?true:false}
                                id='csn'
                                label='CSN'
                                value={formData?.csn||''}
                                fullWidth
                                margin="normal"
                                onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('csn', e.target.value): e.preventDefault()}}
                                InputLabelProps={{shrink: true}}
                                variant='outlined'
                            />
                        </Grid>
                        
                        <Grid item md={4}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    required
                                    margin="normal"
                                    id="as_of_date"
                                    label="As of Date"
                                    format={fieldDateFormat}
                                    fullWidth
                                    minDate={moment().subtract(50, 'years')}
                                    InputLabelProps={{shrink: true}}
                                    value={formData?.as_of_date||null}
                                    error={error?.as_of_date ? true:false}
                                    helperText={error?.as_of_date||''}
                                    onFocus={() => setError({...error, 'as_of_date':''})}
                                    onChange={(data, value) => {onFieldChange('as_of_date', data ? moment(data).format(backendDateFormat):data);}}
                                    inputVariant="outlined"
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        
                        <Grid item md={4}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    required={formData?.date_of_manufacture ? false : tabIndex === 'engines'  && !(formData.tsn_at_lsv ||formData.csn_at_lsv ) ? false : true}
                                    id="lsv_date"
                                    label="LSV Date"
                                    format={fieldDateFormat}
                                    fullWidth
                                    minDate={moment().subtract(50, 'years')}
                                    InputLabelProps={{shrink: true}}
                                    value={formData?.lsv_date||null}
                                    error={error?.lsv_date?true:false}
                                    onFocus={() => setError({...error, 'lsv_date':''})}
                                    helperText={error?.lsv_date||''}
                                    onChange={(data, value) => {onFieldChange('lsv_date', data ? moment(data).format(backendDateFormat):data);}}
                                    inputVariant="outlined"
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={4}>
                            <TextField 
                                required={(tabIndex === 'engines' || tabIndex === 'apu') && formData.lsv_date?true:false}
                                id='tsn_at_lsv'
                                label='TSN @ LSV'
                                value={formData?.tsn_at_lsv||''}
                                fullWidth
                                margin="normal"
                                error={error?.tsn_at_lsv?true:false}
                                helperText={error?.tsn_at_lsv||''}
                                onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('tsn_at_lsv', e.target.value):e.preventDefault()}}
                                onFocus={() => setError({...error, 'tsn_at_lsv':''})}
                                InputLabelProps={{shrink: true}}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField 
                                required={(tabIndex === 'engines' || tabIndex === 'lg') && formData.lsv_date?true:false}
                                id='csn_at_lsv'
                                label='CSN @ LSV'
                                value={formData?.csn_at_lsv||''}
                                fullWidth
                                margin="normal"
                                error={error?.csn_at_lsv?true:false}
                                helperText={error?.csn_at_lsv||''}
                                onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('csn_at_lsv', e.target.value):e.preventDefault()}}
                                onFocus={() => setError({...error, 'csn_at_lsv':''})}
                                InputLabelProps={{shrink: true}}
                                variant='outlined'
                            />
                        </Grid>
                        { tabIndex !== 'airframe' ?
                            <>
                                <Grid item md={4}>
                                    <Autocomplete
                                        options = {["Spare", "Titled"]}
                                        id="status"
                                        value={formData?.status||null}
                                        onChange={(e, value) => onFieldChange('status', value)}
                                        renderInput={params => <TextField required error={error?.status ? true:false} helperText={error?.status||''} onFocus={() => setError({...error, status:''})} {...params} label="Status" placeholder="Select Status" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <TextField 
                                        id='titled_msn'
                                        label='Titled MSN'
                                        value={formData?.titled_msn||''}
                                        fullWidth
                                        margin="normal"
                                        onChange={(e) => onFieldChange('titled_msn', e.target.value)}
                                        InputLabelProps={{shrink: true}}
                                        variant='outlined'
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <TextField 
                                        id='on_wing_msn'
                                        label='On Wing MSN'
                                        value={formData?.on_wing_msn||''}
                                        fullWidth
                                        margin="normal"
                                        onChange={(e) => onFieldChange('on_wing_msn', e.target.value)}
                                        InputLabelProps={{shrink: true}}
                                        variant='outlined'
                                    />
                                </Grid>
                            </>:null
                        }
                        { tabIndex === 'apu' || tabIndex === 'lg' ?
                            <Grid item md={4}>
                                <Autocomplete
                                    options = {assetAircraftTypes}
                                    getOptionLabel={option => option.name}
                                    id="asset_aircraft_type"
                                    value={formData?.asset_aircraft_type||null}
                                    onChange={(e, value) => onFieldChange('asset_aircraft_type', value)}
                                    renderInput={params => <TextField required error={error?.asset_aircraft_type ? true:false} helperText={error?.asset_aircraft_type||''} onFocus={() => setError({...error, asset_aircraft_type:''})} {...params} label="Aircraft Type" placeholder="Select Aircraft Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant="outlined" />}
                                />
                            </Grid>:null
                        }
                        {addEditAssembly.mode === 'edit' ?
                            <Grid item md={4}>
                                <TextField 
                                    id='ground_time_days'
                                    label='Ground Time (Days)'
                                    value={formData?.ground_time_days||''}
                                    fullWidth
                                    margin="normal"
                                    error={error?.ground_time_days?true:false}
                                    helperText={error?.ground_time_days||''}
                                    onChange={(e) => {regexConstants.onlyNumeric.test(e.target.value)?onFieldChange('ground_time_days', e.target.value):e.preventDefault()}}
                                    onFocus={() => setError({...error, 'ground_time_days':''})}
                                    InputLabelProps={{shrink: true}}
                                    variant='outlined'
                                />
                            </Grid>:null
                        }
                        {addEditAssembly.mode === 'edit' && tabIndex !== 'engines' ?
                            <Grid item md={4}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        required
                                        margin="normal"
                                        id="nsv_date"
                                        label="Next Due"
                                        format={fieldDateFormat}
                                        fullWidth
                                        minDate={moment().subtract(50, 'years')}
                                        InputLabelProps={{shrink: true}}
                                        value={formData?.nsv_date||null}
                                        error={error?.nsv_date ? true:false}
                                        helperText={error?.nsv_date||''}
                                        onChange={(data, value) => {onFieldChange('nsv_date', data ? moment(data).format(backendDateFormat):data);}}
                                        inputVariant="outlined"
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>:null
                        }
                        {addEditAssembly.mode === 'edit' && tabIndex !== 'engines' ?
                            <Grid item md={4}>
                                <TextField 
                                    id='sv_cost'
                                    label='Event Cost'
                                    value={formData?.sv_cost || ''}
                                    fullWidth
                                    margin="normal"
                                    error={error?.sv_cost?true:false}
                                    helperText={error?.sv_cost||''}
                                    onChange={(e) => {regexConstants.numberWithDot.test(e.target.value)?onFieldChange('sv_cost', e.target.value):e.preventDefault()}}
                                    onFocus={() => setError({...error, 'sv_cost':''})}
                                    InputProps={{startAdornment: <InputAdornment position="start">US$</InputAdornment>}}
                                    InputLabelProps={{shrink: true}}
                                    variant='outlined'
                                />
                            </Grid>:null
                        }
                        {tabIndex == 'engines' && addEditAssembly.mode === 'edit'  ?
                           <Grid item md={12} style={{marginBottom:'6px'}}>
                            <LlpPopupModal
                            setFormData={setFormData}
                            onAddEditAssembly={onAddEditAssembly}
                            formData={formData}
                          /> </Grid>
                          : null
                        }
                        {tabIndex === 'engines' && formData?.asset_type?.id && formData.location && Object.keys(formData.location).length && regionCondition?.variable_condition ?
                            <Grid item md={12}>
                                <Paper style={{padding:'10px'}}>
                                    <div></div>
                                    <div className="custom-region-variables">
                                        <RegionCustomVariable 
                                            regionCondition={regionCondition.variable_condition}
                                            onChangeRegionCond={onChangeRegionCond}
                                            updateRegionVariables={updateRegionVariables}
                                            adjustRegionVar={adjustRegionVar}
                                            toggleAdjustRagionVarFn={resetRegionVariables}
                                        />
                                    </div>
                                </Paper>
                            </Grid>:null
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                    <Button disabled={isLoading} onClick={onAddEditAssembly} color='primary' size='small' variant='contained'>
                        {isLoading ? <CircularProgress size={20} /> :'Save'}
                    </Button>
                </DialogActions>
                {
                    isLoading ? <PageLoader/> : null
                }
            </Dialog>
        </>
    )
}
export default AddAssembly;