import React, { Fragment } from 'react';
import moment from 'moment';
import { Paper, Table, TableBody, TableHead, TableCell, TableRow, FormControlLabel, Checkbox, TextField, Tooltip } from '@material-ui/core';
import LlpMrSplitModal from './LlpMrSplitModal';
import EngineSVPRSVMR from './EngineSVPRSVMR';
import EngineSVLow from './EngineSVLow';
import EngineSVCost from './EngineSVCost'
import WorkScopeLLP from './WorkScopeLLP'
import NumericLabel from 'react-pretty-numbers';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import InfoIcon from '@material-ui/icons/Info';
import { getCurrencyFormat, getLocalStorageInfo } from '../../../utils';
import { displayDateFormatShort } from '../../../constants';
import EditEngineType from './EditEngineType';
import EditRegionModal from './EditRegionModal';
const EngineShopVisits = ({ engineWorkScopes, engineIndex, mrLogDetail, isLocked, engine, changeWorkScopeFn, onFieldChange, cancelUsageUpdateFn, updateMrRate, carryOverNSVFn, updateLifeOnwing, updateLowApi, updateLowDataToEngine, getResponseBack, engineTypes, getEngineTypeApi, onEngineTypeChange, updateEngineTypeFn }) => {
    const [showShopVisits, setShopVisits] = React.useState(true);
    return (
        <div className='engine-shop-visit-table'>
            <Table border={1} borderColor='#d7d7d7'>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '25%' }}>Engine Workscope Details</TableCell>
                        {Object.keys(engineWorkScopes).map((workScope, index) =>
                            <TableCell align="center" style={{ width: '25%' }}>
                                {engine.enable_mature_run ? 'Mature Run' : `${engineWorkScopes[workScope].name} Run`}
                                {index === 2 ?
                                    <Fragment>
                                        {showShopVisits ?
                                            <KeyboardArrowDownIcon onClick={() => setShopVisits(false)} color="primary" style={{ float: 'right', cursor: 'pointer' }} /> :
                                            <KeyboardArrowRightIcon onClick={() => setShopVisits(true)} color="primary" style={{ float: 'right', cursor: 'pointer' }} />
                                        }
                                    </Fragment> : null
                                }
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                {showShopVisits ?
                    <TableBody>
                        <TableRow>
                            <TableCell>Life on Wing</TableCell>
                            {Object.keys(engineWorkScopes).map((workScope, wkIndex) =>
                                <TableCell align="center">
                                    <EngineSVLow
                                        workScope={engineWorkScopes[workScope]}
                                        updateLifeOnwing={(value) => updateLifeOnwing(engineIndex, wkIndex, 'life_of_wings', value)}
                                        updateLowApi={() => updateLowApi(engineWorkScopes[workScope].life_of_wings, 'edited_lifeOfWings', Number(workScope) + 1)}
                                        updateLowDataToEngine={(value) => updateLowDataToEngine(engineIndex, Number(workScope) + 1, 'low', value)}
                                        getResponseBack={getResponseBack}
                                        isLocked={isLocked}
                                    />
                                </TableCell>
                            )}
                        </TableRow>
                        <TableRow>
                            <TableCell>Shop Visit Date</TableCell>
                            {Object.keys(engineWorkScopes).map((workScope, index) => <TableCell align="center">{engineWorkScopes[workScope].nsv ? moment(engineWorkScopes[workScope].nsv).format(displayDateFormatShort) : '--'}</TableCell>)}
                        </TableRow>
                        <TableRow>
                            <TableCell>Engine Type</TableCell>
                            {Object.keys(engineWorkScopes).map((workScope, wkIndex) =>
                                <TableCell align="center">
                                    <EditEngineType
                                        engine={engine}
                                        mrLogDetail={mrLogDetail}
                                        engineTypes={engineTypes}
                                        getEngineTypeApi={getEngineTypeApi}
                                        workScope={engineWorkScopes[workScope]}
                                        updateEngineTypeFn={(data)=>updateEngineTypeFn(data, wkIndex)}
                                        onEngineTypeChange={(value) => onEngineTypeChange(engineIndex, wkIndex, 'edited_engine_type', value)}
                                        getResponseBack={getResponseBack}
                                        isLocked={isLocked}
                                    />
                                </TableCell>
                            )}
                        </TableRow>
                        <TableRow>
                            <TableCell>Region of Operation</TableCell>
                            {Object.keys(engineWorkScopes).map((workScope, wkIndex) =>
                                <TableCell align="center">
                                    <EditRegionModal
                                        isLocked={isLocked}
                                        mrLogDetail={mrLogDetail}
                                        wkIndex={wkIndex}
                                        updateRegionFn={(data, setModal) => updateEngineTypeFn(engine,wkIndex, data, setModal)}
                                        workScope={engineWorkScopes[workScope]}
                                    />
                                </TableCell>
                            )}

                        </TableRow>
                        <TableRow>
                            <TableCell>Workscope Module</TableCell>
                            {Object.keys(engineWorkScopes).map((workScope, index) =>
                                <TableCell align="center">
                                    <ul className="list-inline workscope-module-type">
                                        <li className="list-inline-item">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        disabled={isLocked}
                                                        checked={engineWorkScopes[workScope].workscope.includes(3)}
                                                        onChange={(e) => getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 ?
                                                            changeWorkScopeFn(e.target.checked ? [...engineWorkScopes[workScope].workscope, 3] : engineWorkScopes[workScope].workscope.filter(id => id !== 3), 'Fan', e.target.checked, workScope, index) : e.preventDefault()
                                                        }
                                                        size="small"
                                                        value="FAN"

                                                    />
                                                }
                                                label={<span style={{ fontSize: '14px' }}>FAN</span>}
                                            />
                                        </li>
                                        <li className="list-inline-item">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        disabled={isLocked}
                                                        color="primary"
                                                        checked={true}
                                                        value="CORE"
                                                    />
                                                }
                                                label={<span style={{ fontSize: '14px' }}>CORE</span>}
                                            />
                                        </li>
                                        <li className="list-inline-item">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        color="primary"
                                                        disabled={isLocked}
                                                        checked={engineWorkScopes[workScope].workscope.includes(2)}
                                                        onChange={(e) => getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 ?
                                                            changeWorkScopeFn(e.target.checked ? [...engineWorkScopes[workScope].workscope, 2] : engineWorkScopes[workScope].workscope.filter(id => id !== 2), 'LPT', e.target.checked, workScope, index) : e.preventDefault()
                                                        }
                                                        value="LPT"
                                                    />
                                                }
                                                label={<span style={{ fontSize: '14px' }}>LPT</span>}
                                            />
                                        </li>
                                    </ul>
                                </TableCell>
                            )}
                        </TableRow>
                        <TableRow>
                            <TableCell>LLPs to be replaced at Shop Visit</TableCell>
                            {Object.keys(engineWorkScopes).map((workScope, wkIndex) =>
                                <TableCell align="center">
                                    {Object.keys(engineWorkScopes[workScope].LLP).length} LLPs Added
                                    <WorkScopeLLP
                                        engine={engine}
                                        shopVisit={Number(workScope) + 1}
                                        engineIndex={engineIndex}
                                        wk={workScope}
                                        wkIndex={wkIndex}
                                        isLocked={isLocked}
                                        getResponseBack={getResponseBack}
                                    />
                                </TableCell>
                            )}
                        </TableRow>
                        <TableRow>
                            <TableCell className='full-row' colSpan={4}>Performance Restoration</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Maintenance Reserve Rate</TableCell>
                            {Object.keys(engineWorkScopes).map((workScope, index) =>
                                <TableCell align="center">
                                    <EngineSVPRSVMR
                                        workScope={engineWorkScopes[workScope]} s
                                        onFieldChange={(e, value, label) => onFieldChange(e, 'mr', value, engineIndex, 'prsv_mr', Number(workScope) + 1, index, label)}
                                        cancelUsageUpdateFn={() => cancelUsageUpdateFn('engines')}
                                        updateMrRate={() => updateMrRate(engine, engineIndex, engineWorkScopes[workScope].edited_engine_mr, 'edited_engine_mr', Number(workScope) + 1, engineWorkScopes[workScope].name, 'Performance Restoration')}
                                    />
                                </TableCell>
                            )}
                        </TableRow>
                        <TableRow>
                            <TableCell>Maintenance Reserve Rate (for the run)</TableCell>
                            {Object.keys(engineWorkScopes).map((workScope) =>
                                <TableCell align="center">
                                    {engineWorkScopes[workScope].engine_mr_for_run ?
                                        <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{engineWorkScopes[workScope].engine_mr_for_run}</NumericLabel> : '--'
                                    }
                                </TableCell>
                            )}
                        </TableRow>
                        <TableRow>
                            <TableCell>Shop Visit Cost (A)</TableCell>
                            {Object.keys(engineWorkScopes).map((workScope, index) =>
                                <TableCell align="center">
                                    <EngineSVCost
                                        workScope={engineWorkScopes[workScope]} s
                                        svCostType='engine_cost'
                                        onFieldChange={(e, value, label) => onFieldChange(e, 'mr', value, engineIndex, 'edited_engine_cost', Number(workScope) + 1, index, label, 'sv_cost')}
                                        cancelUsageUpdateFn={() => cancelUsageUpdateFn('engines')}
                                        updateSvCost={() => updateMrRate(engine, engineIndex, engineWorkScopes[workScope].edited_engine_cost, 'edited_engine_cost', Number(workScope) + 1, engineWorkScopes[workScope].name, 'Performance Restoration')}
                                    />
                                </TableCell>
                            )}
                        </TableRow>
                        <TableRow>
                            <TableCell>Fund Collected (B)</TableCell>
                            {Object.keys(engineWorkScopes).map((workScope) =>
                                <TableCell align="center">
                                    {engineWorkScopes[workScope].engine_fund ?
                                        <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{engineWorkScopes[workScope].engine_fund}</NumericLabel> : '--'
                                    }
                                </TableCell>
                            )}
                        </TableRow>
                        <TableRow>
                            <TableCell>Fund Difference (B-A)</TableCell>
                            {Object.keys(engineWorkScopes).map((workScope, wkIndex) =>
                                <TableCell align="center">
                                    {engineWorkScopes[workScope].remaining_engine_fund ?
                                        <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{engineWorkScopes[workScope].remaining_engine_fund}</NumericLabel> : '--'
                                    }<br />
                                    {wkIndex !== 2 ? getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 ?
                                        <FormControlLabel
                                            control={
                                                <Checkbox size="small" color="primary" disabled={isLocked} checked={engineWorkScopes[workScope].carry_forward_engine_fund_status} onChange={(e) => carryOverNSVFn({ carry_forward_engine_fund_status: e.target.checked }, 'Performance Restoration', workScope)} value="Carry Over Next SV" />
                                            }
                                            label={<span style={{ fontSize: '14px' }}>Carry over to Next SV</span>}
                                        /> : null
                                        : null
                                    }
                                </TableCell>
                            )}
                        </TableRow>
                        <TableRow>
                            <TableCell className='full-row' colSpan={4}>LLP Replacement</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Maintenance Reserve Rate</TableCell>
                            {Object.keys(engineWorkScopes).map((workScope) =>
                                <TableCell align="center">
                                    {!(engineWorkScopes[workScope].edited_llp_mr !== undefined && engineWorkScopes[workScope].edited_llp_mr !== null) || (engineWorkScopes[workScope].llp_bulk_mr == engineWorkScopes[workScope].edited_llp_mr) ?
                                        <div>
                                            <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{engineWorkScopes[workScope].llp_bulk_mr}</NumericLabel> / FC
                                            <span style={{ marginLeft: '4px', cursor: 'pointer' }}>
                                                {engineWorkScopes[workScope].nsv ? getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 && !isLocked ?
                                                    <LlpMrSplitModal getResponseBack={getResponseBack} shop_visit={engineWorkScopes[workScope].run_id} engine_no={engineIndex} llp_mr_split={engineWorkScopes[workScope].llp_mr_split} llp_mr_to_consider={engineWorkScopes[workScope].llp_mr_to_consider} />
                                                    : null
                                                    : null
                                                }
                                            </span>
                                        </div> :
                                        <div className="differentiation-values">
                                            <div>
                                                <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{engineWorkScopes[workScope].edited_llp_mr}</NumericLabel> / FC
                                                <span style={{ marginLeft: '4px', cursor: 'pointer' }}>
                                                    {!isLocked ?
                                                        <LlpMrSplitModal getResponseBack={getResponseBack} shop_visit={engineWorkScopes[workScope].run_id} engine_no={engineIndex} llp_mr_split={engineWorkScopes[workScope].llp_mr_split} llp_mr_to_consider={engineWorkScopes[workScope].llp_mr_to_consider} /> : null
                                                    }
                                                </span>
                                            </div>
                                            <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                                <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{engineWorkScopes[workScope].llp_bulk_mr}</NumericLabel>
                                                <Tooltip title="Original Value">
                                                    <InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} />
                                                </Tooltip>
                                            </p>
                                        </div>
                                    }
                                </TableCell>
                            )}
                        </TableRow>
                        <TableRow>
                            <TableCell>Shop Visit Cost (A)</TableCell>
                            {Object.keys(engineWorkScopes).map((workScope) =>
                                <TableCell align="center">
                                    {engineWorkScopes[workScope].llp_replacement_cost ?
                                        <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{engineWorkScopes[workScope].llp_replacement_cost}</NumericLabel> : 'US$0'
                                    }
                                </TableCell>
                            )}
                        </TableRow>
                        <TableRow>
                            <TableCell>Fund Collected (B)</TableCell>
                            {Object.keys(engineWorkScopes).map((workScope) =>
                                <TableCell align="center">
                                    {engineWorkScopes[workScope].llp_replacement_fund ?
                                        <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{engineWorkScopes[workScope].llp_replacement_fund}</NumericLabel> : 'US$0'
                                    }
                                </TableCell>
                            )}
                        </TableRow>
                        <TableRow>
                            <TableCell>Fund Difference (B-A)</TableCell>
                            {Object.keys(engineWorkScopes).map((workScope, wkIndex) =>
                                <TableCell align="center">
                                    {engineWorkScopes[workScope].llp_replacement_fund_remaining ?
                                        <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{engineWorkScopes[workScope].llp_replacement_fund_remaining}</NumericLabel> : '--'
                                    }<br />
                                    {wkIndex !== 2 ? getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 ?
                                        <FormControlLabel
                                            control={
                                                <Checkbox size="small" color="primary" disabled={isLocked} checked={engineWorkScopes[workScope].carry_forward_llp_fund_status} onChange={(e) => carryOverNSVFn({ carry_forward_llp_fund_status: e.target.checked }, 'LLP Replacement', workScope)} value="Carry Over Next SV" />
                                            }
                                            label={<span style={{ fontSize: '14px' }}>Carry over to Next SV</span>}
                                        /> : null
                                        : null
                                    }
                                </TableCell>
                            )}
                        </TableRow>
                    </TableBody> : null
                }
            </Table>
        </div>
    )
}
export default EngineShopVisits;