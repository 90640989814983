import React, { useState } from 'react'
import { Paper, Grid, TextField } from '@material-ui/core'
import AircraftFilters from '../components/AircraftFilters'
import AircraftCostVTypeGraph from './AircraftCostVTypeGraph'
import AircraftCostVChecks from './AircraftCostVChecks'
import Autocomplete from '@material-ui/lab/Autocomplete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Skeleton } from '@material-ui/lab';
export default function AircraftSection({ getGraphListFn, setAircraftFilter, aircraftFilter, aircraftData, aircraftCheckData, costLoader, checkLoader, getResponseBack, callExternalFunction, tabIndex }) {
  const [costGraphType, setCostGraphType] = useState({ label: 'Bar Plot', value: 1 })
  const [checkGraphType, setCheckGraphType] = useState({ label: 'Bar Plot', value: 1 })
  return (
    <div>
      <Paper className="generic-filter-block" style={{ paddingTop: '20px' }}>
        <Grid container spacing={1}>
          <Grid item xs={11} md={11}>
            <AircraftFilters
              setFilter={setAircraftFilter}
              filter={aircraftFilter}
              getResponseBack={getResponseBack}
              processingLoader={null}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {
              costLoader ? <Skeleton variant="rect" animation='wave' height={350} /> :
                <Paper className="events-bar-chart" style={{ borderRadius: 0 }}>
                  <div className='events-bar-chart-title' style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
                    <h4> Aircraft Cost Graph</h4>
                    {
                      aircraftData?.aircraft_type?.length ?
                        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                          <Autocomplete
                            options={[{ label: 'Bar Plot', value: 1 }, { label: 'Line Plot', value: 2 }]}
                            getOptionLabel={option => option.label}
                            value={costGraphType || null}
                            style={{ width: 135, marginRight: '20px' }}
                            disableClearable={true}
                            id="costGraphType"
                            onChange={(e, value) => setCostGraphType(value)}
                            renderInput={params => <TextField {...params} InputLabelProps={{ shrink: 'true', }} />}
                          />
                          <span style={{ cursor: 'pointer', height: '18px' }} onClick={() => { getGraphListFn(aircraftData, 'Aircraft Cost Graph') }} >
                            <OpenInNewIcon color='primary' style={{ fontSize: '20px' }} />
                          </span>
                        </span>
                        : null}
                  </div>
                  <AircraftCostVTypeGraph
                  tabIndex={tabIndex}
                    costGraphType={costGraphType?.value}
                    callExternalFunction={callExternalFunction}
                    aircraftData={aircraftData}
                  />
                </Paper>
            }
          </Grid>
          <Grid item xs={12} md={6}>
            {
              checkLoader ? <Skeleton variant="rect" animation='wave' height={350} /> :
                <Paper className="events-bar-chart" style={{ borderRadius: 0 }}>
                  <div className='events-bar-chart-title' style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
                    <h4> Aircraft Maintenance Check Graph</h4>
                    {
                      aircraftCheckData?.maintenance_check?.length ?
                        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                          <Autocomplete
                            options={[{ label: 'Bar Plot', value: 1 }, { label: 'Line Plot', value: 2 }]}
                            getOptionLabel={option => option.label}
                            value={checkGraphType || null}
                            style={{ width: 135, marginRight: '20px' }}
                            disableClearable={true}
                            id="checkGraphType"
                            onChange={(e, value) => setCheckGraphType(value)}
                            renderInput={params => <TextField {...params} InputLabelProps={{ shrink: 'true', }} />}
                          />
                          <span style={{ cursor: 'pointer', height: '18px' }} onClick={() => { getGraphListFn(aircraftCheckData, 'Aircraft Maintenance Check Graph') }} >
                            <OpenInNewIcon color='primary' style={{ fontSize: '20px' }} />
                          </span>
                        </span>
                        : null}
                  </div>
                  <AircraftCostVChecks
                  tabIndex={tabIndex}
                  checkGraphType={checkGraphType?.value}
                    callExternalFunction={callExternalFunction}
                    aircraftData={aircraftCheckData}
                  />
                </Paper>
            }
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}
