import { globalGetService, globalPostService } from '../utils/globalApiServices';
import * as actions from './actions';
import { checkApiStatus } from '../utils';
export const getRegionListAc = (query={}) => {
  return (dispatch) => {
    globalGetService(`console/regions/`, query)
    .then(response => {
      if(checkApiStatus(response)){
        dispatch({
          type: actions.REGION_LIST,
          payload: response.data.data.region_list
        })
      }
    })
  }
}

export const getPortfolioListAc = (query) => {
  return (dispatch) => {
    globalGetService(`console/asset/portfolios/`)
    .then(response => {
      if(checkApiStatus(response)){
        dispatch({
          type: actions.PORTFOLIO_LIST,
          payload: response.data.data
        })
      }
    })
  }
}
export const getEngineTypesAc = (query={}) => {
  return (dispatch) => {
    if(!query.aircraft_type){
      dispatch({
        type: actions.LOADER,
        payload: true
      })
    }
    globalGetService(`console/engine-types/`, query)
    .then(response => {
      dispatch({
        type: actions.LOADER,
        payload: false
      })
      if(checkApiStatus(response)){
        dispatch({
          type: actions.ENGINE_TYPES,
          payload: response.data.data.engineTypes
        })
      }
    })
  }
}
export const getAircraftTypesAc = (props={}, query={}) => {
  return (dispatch) => {
    globalGetService(`console/aircraft-types/`, query)
    .then(response => {
      if(checkApiStatus(response)){
        dispatch({
          type:actions.AIRCRAFT_TYPES,
          payload: response.data.data.aircraftTypes
        })
      }
    })
  }
}

export const getAPUTypesAc = (query={}) => {
  return(dispatch) => {
    globalGetService(`console/apu-types/`, query)
    .then(response => {
      if(checkApiStatus(response)){
        dispatch({
          type: actions.APU_TYPES,
          payload: response.data.data.apu_types
        })
      }
    })
  }
}

export const getLessorListAc = (props={}, query={}) => {
  return (dispatch) => {
    globalGetService(`console/lessors/`, query)
    .then(response => {
      if(checkApiStatus(response)){

      }
    })
  }
}
export const getLesseeListAc = (props={}, query={}) => {
  return (dispatch) => {
    globalGetService(`console/lessee-pool/`, query)
    .then(response => {
      if(checkApiStatus(response)){
        dispatch({
          type: actions.LESSEE_LIST,
          payload: response.data.data.lessees
        })
      }
    })
  }
}
export const getOperatorListAc = (props={}, query={}) => {
  return (dispatch) => {
    globalGetService(`console/operators/`, query)
    .then(response => {
      if(checkApiStatus(response)){
        dispatch({
          type: actions.OPERATOR_LIST,
          payload: response.data.data.operators
        })
      }
    })
  }
}
export const getManufacturersAc = (props={}, query={}) => {
  return (dispatch) => {
    globalGetService(`console/manufacturers/`, query)
    .then(response => {
      if(checkApiStatus(response)){
        dispatch({
          type: actions.MANUFACTURER_LIST,
          payload: response.data.data
        })
      }
    })
  }
}

export const getPlatformConstantsAc = (data) => {
  return (dispatch) => {
    globalPostService(`console/get-constants/`, data)
    .then(response => {
      if(checkApiStatus(response)){
        dispatch({
          type: actions.PLATFORM_CONTANTS,
          payload: response.data.data
        })
      }
    })
  }
}
