import React, { Fragment, useState, } from "react";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { Table, TableHead, Tooltip, TextField, TableBody, TableRow, TableCell, Tabs, Tab, Button, Grid, Paper, Breadcrumbs, Typography, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import moment from "moment";
import InfoIcon from '@material-ui/icons/Info';
import MomentUtils from '@date-io/moment';
import { Autocomplete } from "@material-ui/lab";
import EolOutputConditions from "./EolOutputConditions";
export default function EolOutputList({ editMode, simulationType, onFieldChange, eol, simulatedEol, eolIndex, openAllAcc, setOpenAllAcc, onChangeAccordian, accordianArr, mrLogDetail, onLeaseDatesChange }) {

  return (
    <div>
      <div className="eol-projection-heading">
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <Grid item sm={6}>
              {
                mrLogDetail.airframe ?
                  <Table border={1} borderColor='#d7d7d7' >
                    <TableRow>
                      <TableCell width="33.33%">
                        <p>Aircraft Type</p>
                        <h4>{mrLogDetail?.general_info?.aircraft_type.name}</h4>
                      </TableCell>
                      <TableCell width="33.33%">
                        <p>Maintenance Program</p>
                        <h4>
                          {mrLogDetail?.general_info?.program_group.split(',').join('/')}
                        </h4>
                      </TableCell>
                      <TableCell width="33.33%">
                        <p>Date of Manufacture</p>
                        <h4>
                          {moment(mrLogDetail?.general_info?.dateOfManufacture).format(displayDateFormatShort)}
                        </h4>
                      </TableCell>
                    </TableRow>
                  </Table>
                  :
                  mrLogDetail?.engines ?
                    <Table border={1} borderColor='#d7d7d7' >
                      <TableRow>
                        <TableCell width={'33.33%'}>
                          <p>Engine Type</p>
                          <h4>{mrLogDetail.engines?.general_info?.engine_type?.name ? mrLogDetail.engines?.general_info?.engine_type?.name : '--'}</h4>
                        </TableCell>
                        <TableCell width={'33.33%'}>
                          <p>Region Of Operation</p>
                          <h4>
                            {mrLogDetail?.location?.address ?
                              mrLogDetail?.location?.address.length > 20 ? <Tooltip title={mrLogDetail?.location?.address}><span>{`${mrLogDetail?.location?.address.substr(0, 20)}...`}</span></Tooltip> :
                                mrLogDetail?.location?.address :
                              (mrLogDetail?.engines.general_info.engineType && mrLogDetail?.engines.general_info.regionOfOperation.name ? mrLogDetail?.engines?.general_info.regionOfOperation.name : '--')
                            }
                          </h4>
                        </TableCell>
                        <TableCell width={'33.33%'}>
                          <p>Utilization Ratio</p>
                          <h4>{`${mrLogDetail?.engines?.general_info?.utilization_ratio}:1`}</h4>
                        </TableCell>
                      </TableRow>
                    </Table> : null
              }
            </Grid>
          </Grid>
          <Grid item md={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                label='Lease Start Date'
                margin="normal"
                id={`lease_start_date${eolIndex}`}
                format={fieldDateFormat}
                fullWidth
                disabled={!editMode}
                InputLabelProps={{ shrink: true }}
                inputVariant="outlined"
                onChange={(event, value) => { onLeaseDatesChange(event, 'lease_start_date', event ? moment(event).format(backendDateFormat) : event, eolIndex, 'date') }}
                value={simulationType == 3 ? simulatedEol?.lease_start_date ? simulatedEol?.lease_start_date : null : eol?.lease_start_date ? eol.lease_start_date : null}
              />
            </MuiPickersUtilsProvider>
            {
              simulatedEol.lease_start_date !== eol.lease_start_date && simulationType == 3 ?
                <p style={{ color: '#ff0000', fontSize: '12px' }}>
                  {moment(simulatedEol?.lease_start_date).format(fieldDateFormat)}
                  <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                </p>
                : null
            }
          </Grid>
          <Grid item md={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                required
                label='Lease End Date'
                margin="normal"
                id={`lease_end_date${eolIndex}`}
                format={fieldDateFormat}
                fullWidth
                disabled={!editMode}
                InputLabelProps={{ shrink: true }}
                inputVariant="outlined"
                onChange={(event, value) => { onLeaseDatesChange(event, 'lease_end_date', event ? moment(event).format(backendDateFormat) : event, eolIndex, 'date') }}
                value={simulationType == 3 ? simulatedEol?.lease_end_date ? simulatedEol?.lease_end_date : null : eol?.lease_end_date ? eol.lease_end_date : null}
              />
            </MuiPickersUtilsProvider>
            {
              simulatedEol.lease_end_date !== eol.lease_end_date && simulationType == 3 ?
                <p style={{ color: '#ff0000', fontSize: '12px' }}>
                  {moment(simulatedEol?.lease_end_date).format(fieldDateFormat)}
                  <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                </p>
                : null
            }
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              options={[]}
              getOptionLabel={option => option.label}
              id={`lease_type${eolIndex}`}
              disabled={true}
              value={eol?.lease_type ? eol.lease_type : null}
              renderInput={params => <TextField required  {...params} margin="normal" label='Lease Type' fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              className='lease-duration'
              label='Lease End Term (in MO)'
              id={`lease_duration${eolIndex}`}
              fullWidth
              margin="normal"
              value={ simulationType == 3 ? simulatedEol?.lease_duration ? simulatedEol?.lease_duration : null : eol?.lease_duration ? eol.lease_duration : null}
              variant="outlined"
              disabled={!editMode}
              onChange={(event, value) => { onLeaseDatesChange(event, 'lease_duration', event.target.value, eolIndex,) }}
              InputLabelProps={{ shrink: true }}
            />
            {
              simulatedEol.lease_duration !== eol.lease_duration && simulationType == 3?
                <p style={{ color: '#ff0000', fontSize: '12px' }}>
                  {simulatedEol?.lease_duration}
                  <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                </p>
                : null
            }
          </Grid>
        </Grid>
      </div>
      {eol?.eol_conditions?.map((eolItem, conditionIndex) =>
        <EolOutputConditions
          onChangeAccordian={onChangeAccordian}
          accordianArr={accordianArr}
          openAllAcc={openAllAcc}
          setOpenAllAcc={setOpenAllAcc}
          simulationType={simulationType}
          simulatedEolItem={simulationType === '3' && simulatedEol && simulatedEol.eol_conditions[conditionIndex] ? simulatedEol.eol_conditions[conditionIndex] : {}}
          eolItem={eolItem}
          eolIndex={eolIndex}
          conditionIndex={conditionIndex}
          eol={eol}
          simulatedEol={simulatedEol}
          editMode={editMode}
          onFieldChange={onFieldChange}
        />
      )}
    </div>
  )
}
