import React, { Fragment } from 'react';
import { Table, TableBody, TableHead, TableRow, TableCell, Grid, TextField, FormControlLabel, Checkbox, Button, Tooltip, IconButton, InputAdornment } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { getLocalStorageInfo } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import GaugeChart from './GaugeChart';
import OtherCost from './OtherCost';
const AirframeCard = ({airframeEdit, onAddRemoveOtherCost, onChangeOtherCost, toggleEditFn, airframeUpdateUsageFn, cancelUsageUpdateFn, mrLogDetail, onFieldChange, airEngineError, updateAirEngineError, isLocked}) => {
  return(
    <div className='apu-assembly-card'>
        <div>
          <Grid alignItems='center' container spacing={1}>
            <Grid item md={6}>
              <Table border={1} borderColor='#d7d7d7'>
                <TableRow>
                  <TableCell width="33.33%">
                    <p>Aircraft Type</p>
                    <h4>{mrLogDetail.generalInfo.aircraftType.name}</h4>
                  </TableCell>
                  <TableCell width="33.33%">
                    <p>Maintenance Program</p>
                    <h4>
                      {mrLogDetail.generalInfo.maintenance_program[Object.keys(mrLogDetail.generalInfo.maintenance_program)[0]].split(',').join('/')}
                    </h4>
                  </TableCell>
                  <TableCell width="33.33%">
                    <p>Date of Manufacture</p>
                    <h4>
                      {moment(mrLogDetail.generalInfo.aircraft_manufacture_date).format(displayDateFormatShort)}
                    </h4>
                  </TableCell>
                </TableRow>
              </Table>
            </Grid>
            <Grid item md={6}>
              <ul className="list-inline assembly-cta-list">
                {  !airframeEdit ?
                  getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow']  && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1  && !isLocked ?
                  <Tooltip title="Edit Airframe" arrow>
                    <IconButton color="primary" component="span" size="small">
                      <EditIcon onClick={toggleEditFn} color="primary" />
                    </IconButton>
                  </Tooltip> : null
                  : !isLocked ?
                    <Fragment>
                      <li className="list-inline-item">
                        <Button size="small" color="primary" variant="contained" onClick={airframeUpdateUsageFn}>Save</Button>
                      </li>
                      <li className="list-inline-item">
                        <Button size="small" color="primary" variant="outlined" onClick={cancelUsageUpdateFn}>Cancel</Button>
                      </li>
                    </Fragment> : null
                }
              </ul>
            </Grid>
          </Grid>
          { Object.keys(mrLogDetail.airframe).map((label, index) =>{
            return(
              <div className='apu-assembly-event'>
                <div className='event-name'>
                  <h4>{mrLogDetail.airframe[label].description.replace('s', '')} Check</h4>
                </div>
                <div className='event-details'>
                  <Grid container spacing={1}>
                    <Grid item md={8}>
                      <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} md={4}>
                          <TextField
                            disabled={!airframeEdit}
                            id="af_currentFundValue"
                            label="Fund Balance"
                            fullWidth
                            margin="normal"
                            value={mrLogDetail.airframe[label].currentFundValue}
                            InputLabelProps={{shrink: true}}
                            onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'currentFundValue', e.target.value, label): e.preventDefault()}}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">US$</InputAdornment>
                            }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              disabled={!airframeEdit}
                              required
                              margin="normal"
                              id="af_fundDate"
                              label="Fund Balance as of"
                              format={fieldDateFormat}
                              fullWidth
                              disableFuture={true}
                              error={airEngineError && airEngineError[label] && airEngineError[label].fundDate ? true :false}
                              helperText={airEngineError && airEngineError[label] && airEngineError[label].fundDate ? airEngineError[label].fundDate : '' }
                              minDate={mrLogDetail && mrLogDetail.generalInfo && mrLogDetail.generalInfo.aircraft_manufacture_date && moment(mrLogDetail.generalInfo.aircraft_manufacture_date)}
                              maxDate={moment()}
                              InputLabelProps={{shrink: true}}
                              value={mrLogDetail.airframe[label].fundDate ? mrLogDetail.airframe[label].fundDate:null}
                              onChange={(data, value) => {onFieldChange(value, 'fundDate', data ? moment(data).format(backendDateFormat):data, label); }}
                              onAccept={(e) =>  airEngineError && airEngineError[label] && airEngineError[label].fundDate && updateAirEngineError(label, 'fundDate', '')}
                              inputVariant="outlined"
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                          <TextField
                            disabled={!airframeEdit}
                            required
                            id="af_actualMR"
                            label="MR Rate"
                            fullWidth
                            margin="normal"
                            error={airEngineError && airEngineError[label] && airEngineError[label].actualMR ? true :false}
                            helperText={airEngineError  && airEngineError[label] && airEngineError[label].actualMR ? airEngineError[label].actualMR :'' }
                            value={mrLogDetail.airframe[label].actualMR}
                            InputLabelProps={{shrink: true}}
                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'actualMR', e.target.value, label): e.preventDefault()}}
                            onFocus={() => airEngineError && airEngineError[label] && airEngineError[label].actualMR &&  updateAirEngineError(label, 'actualMR', '')}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                              endAdornment: <InputAdornment position="end">/ Month</InputAdornment>
                            }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                          <TextField
                            disabled={!airframeEdit}
                            id="af_escalation"
                            label="MR Rate Esc.(Annual)"
                            fullWidth
                            margin="normal"
                            value={mrLogDetail.airframe[label].escalation}
                            InputLabelProps={{shrink: true}}
                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'escalation', e.target.value, label): e.preventDefault()}}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                          <TextField
                            disabled={!airframeEdit}
                            id="af_cost_escalation"
                            label="Event Cost Esc.(Annual)"
                            fullWidth
                            margin="normal"
                            value={mrLogDetail.airframe[label].cost_escalation}
                            InputLabelProps={{shrink: true}}
                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'cost_escalation', e.target.value, label): e.preventDefault()}}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              disabled={!airframeEdit}
                              disableFuture={true}
                              margin="normal"
                              id="af_lastShopvisitDate"
                              label="Last Qualified Due Date"
                              format={fieldDateFormat}
                              fullWidth
                              minDate={mrLogDetail && mrLogDetail.airframe && mrLogDetail.airframe[label] && mrLogDetail.airframe[label].lastShopvisitDate ? moment(mrLogDetail.airframe[label].lastShopvisitDate) : mrLogDetail && mrLogDetail.generalInfo && mrLogDetail.generalInfo.aircraft_manufacture_date ? moment(mrLogDetail.generalInfo.aircraft_manufacture_date) : null }
                              InputLabelProps={{shrink: true}}
                              helperText={airEngineError.lastShopvisitDate ? airEngineError.lastShopvisitDate : '' }
                              value={mrLogDetail.airframe[label].lastShopvisitDate ? mrLogDetail.airframe[label].lastShopvisitDate:null}
                              onChange={(data, value) => {onFieldChange(value, 'lastShopvisitDate', data ? moment(data).format(backendDateFormat):data, label);}}
                              inputVariant="outlined"
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              disabled={!airframeEdit}
                              margin="normal"
                              disablePast={true}
                              id="af_nextShopvisitDate"
                              label="Next Qualified Due Date"
                              format={fieldDateFormat}
                              fullWidth
                              InputLabelProps={{shrink: true}}
                              value={mrLogDetail.airframe[label].nextShopvisitDate ? mrLogDetail.airframe[label].nextShopvisitDate:null}
                              onChange={(data, value) => {onFieldChange(value, 'nextShopvisitDate', data ? moment(data).format(backendDateFormat):data, label);}}
                              inputVariant="outlined"
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                          <TextField
                            disabled={!airframeEdit}
                            id="af_event_cost"
                            label="Next Qualified Event Cost"
                            fullWidth
                            margin="normal"
                            value={mrLogDetail.airframe[label].event_cost}
                            InputLabelProps={{shrink: true}}
                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value)  ? onFieldChange(e, 'event_cost', e.target.value, label): e.preventDefault()}}
                            InputProps={{
                              startAdornment: <InputAdornment position="end">US$</InputAdornment>
                            }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                          <TextField
                            disabled={!airframeEdit}
                            id="ground_time"
                            label={
                              <div>
                                Ground Time
                                <Tooltip title={`Number of days the asset is grounded for SV to be completed.`} placement="top" arrow>
                                  <InfoIcon fontSize="small" style={{verticalAlign:'bottom' , marginLeft:'3px'}} />
                                </Tooltip>
                              </div>
                            }
                            fullWidth
                            margin="normal"
                            value={mrLogDetail.airframe[label].rest_days}
                            InputLabelProps={{shrink: true, style: { pointerEvents: "auto" }}}
                            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'rest_days', e.target.value, label): e.preventDefault()}}
                            variant="outlined"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">Days</InputAdornment>
                            }}
                          />
                        </Grid>
                        <Grid item md={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={!airframeEdit}
                              checked={mrLogDetail.airframe[label].include_other_costs?true:false}
                              onChange={(e) => onFieldChange(e, 'include_other_costs', e.target.checked, label)}
                              name="include_other_costs"
                              color="primary"
                            />
                          }
                          label="Include Other Cost"
                        />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={4}>
                      <GaugeChart 
                        unit={' / Month'}
                        min={mrLogDetail.airframe[label].minMR}
                        max={mrLogDetail.airframe[label].maxMR}
                        actual={mrLogDetail.airframe[label].targetMR}
                      />
                    </Grid>
                  </Grid>
                  { mrLogDetail.airframe[label].include_other_costs ?
                    <Grid container spacing={1}>
                      <Grid item md={12}>
                        { airframeEdit ? 
                          <Button
                            onClick={() => onAddRemoveOtherCost('add', label)}  
                            startIcon={<AddIcon fontSize='small' />} 
                            color="primary" 
                            variant="outlined" 
                            size="small" 
                            style={{marginBottom:'6px'}}>
                              Add More
                          </Button>:null
                          }
                        <Table className='mui-styled-table'>
                          <TableHead>
                            <TableRow>
                              <TableCell>Include In MR</TableCell>
                              <TableCell>Cost Type</TableCell>
                              <TableCell>Cost</TableCell>
                              { airframeEdit ?
                                <TableCell>Action</TableCell>:null
                              }
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Checkbox disabled color="primary" checked={true} />
                              </TableCell>
                              <TableCell>
                                <TextField
                                    disabled={true}
                                    id={`Scheduled_Maintenance_Tasks-MPD`}
                                    fullWidth
                                    margin="none"
                                    InputLabelProps={{shrink: true}}
                                    value={'Scheduled Maintenance Tasks-MPD'}
                                    inputProps={{ maxLength: 25 }}
                                    variant="outlined"
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                    disabled={true}
                                    id={`actual_event_cost`}
                                    fullWidth
                                    margin="none"
                                    InputLabelProps={{shrink: true}}
                                    value={mrLogDetail.airframe[label].actual_event_cost}
                                    inputProps={{ maxLength: 25 }}
                                    InputProps={{
                                      startAdornment: <InputAdornment position="start">US$</InputAdornment>
                                  }}
                                    variant="outlined"
                                />
                              </TableCell>
                              { airframeEdit ?<TableCell></TableCell>:null}
                              
                            </TableRow>
                            {mrLogDetail.airframe[label].other_cost_details.map((item,index) =>
                              <OtherCost
                                airframeEdit={airframeEdit}
                                item={item}
                                label={label}
                                onAddRemoveOtherCost={() => onAddRemoveOtherCost('remove', label, index)}
                                onChangeOtherCost={(e, keyParam,value) => onChangeOtherCost(e, keyParam,value, label, index)}
                              />
                            )}
                          </TableBody>
                        </Table>
                        { airEngineError && airEngineError[label] && airEngineError[label].otherCostError ?
                          <p className='error-msg'>{airEngineError[label].otherCostError}</p>:null
                        }
                      </Grid>
                    </Grid>:null
                  }
                </div>
              </div>
            )
          })}
        </div>
    </div>
  )
}
export default AirframeCard;
