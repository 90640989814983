import React, { useState, useEffect } from 'react'
import { TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { globalGetService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
import { useSnackbar } from 'notistack';

export default function AircraftFilters({ processingLoader, getResponseBack, setFilter, filter }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [maintenancePrograms, setMaintenancePrograms] = useState([])
    const [aircraftTypeList, setAircraftTypeList] = useState([])
    useEffect(() => {
        getAircraftTypeAPi()
    }, [])
    const changeGenericFilter = (key, value) => {
        if(value?.length <= 5){
            setFilter(prevState => ({
                ...prevState,
                [key]: value
            }))
        }
        else{
            enqueueSnackbar('Max 5 values can be selected.', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
        if (key == 'aircraft_type') {
            if (value[0] && value?.length == 1) {
                getMaintenancePrograms(value[0])
            }
            setMaintenancePrograms([])
            setFilter(prevState => ({
                ...prevState,
                maintenance_programs: []
            }))
        }
        else {

        }
    }
    const getAircraftTypeAPi = (query) => {
        globalGetService(`console/aircraft-types/`, query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setAircraftTypeList(response.data.data?.aircraftTypes)
                }
            })
    }
    const getMaintenancePrograms = (aircraftType) => {
        globalGetService(`console/aircraft-type/${aircraftType.id}/events/`, { list: true })
            .then(response => {
                if (checkApiStatus(response)) {
                    setMaintenancePrograms(response.data.data && response.data.data[0] ? response.data.data : []);
                }
            })
    }
    return (
        <div style={{ position: 'relative' }}>
            {processingLoader ?
                <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', zIndex: 10, background: 'rgba(255,255,255,.8)' }}>
                    <p style={{ textAlign: 'center', fontSize: '15px', padding: '7px 0' }}><span style={{ padding: '4px', background: '#fff', fontWeight: '500', fontFamily: 'Conv_IBMPlexSans-Medium' }}>Processing the Graph Data...</span></p>
                </div> : null
            }
            <ul className='list-inline flex-centred' style={{display:'inline-flex', alignItems:'center'}}>
                <li className='list-inline-item margin-right'>
                    <Autocomplete
                        options={aircraftTypeList}
                        multiple
                        getOptionLabel={option => option.name}
                        value={filter?.aircraft_type || []}
                        style={{ width: 375 }}
                        id="aircraft_type"
                        onChange={(e, value) => changeGenericFilter('aircraft_type', value)}
                        renderInput={params => <TextField {...params} InputLabelProps={{ shrink: 'true' }} label="Aircraft Type" placeholder="Select Aircraft Type" variant="outlined" />}
                    />
                </li>
                <li className='list-inline-item margin-right'>
                    <Autocomplete
                        options={maintenancePrograms}
                        multiple
                        disabled={filter?.aircraft_type?.length > 1}
                        getOptionLabel={option => option.name}
                        value={filter?.maintenance_programs || []}
                        style={{ width: 375 }}
                        id="maintenance_programs"
                        onChange={(e, value) => changeGenericFilter('maintenance_programs', value)}
                        renderInput={params => <TextField {...params} InputLabelProps={{ shrink: 'true' }} label="Maintenance Program" placeholder="Select Maintenance Program" variant="outlined" />}
                    />
                </li>
                <li className='list-inline-item margin-right apply-btn'>
                    <Button variant='contained' color='primary' size='small' onClick={()=> getResponseBack(filter)} >
                        Apply
                    </Button>
                </li>
            </ul>
        </div>
    )
}
 