import React, { Fragment } from 'react';
import { Paper, Table, TableCell, TableRow, Grid, TextField, Button, Tooltip, IconButton, InputAdornment, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import MomentUtils from '@date-io/moment';
import { isMobile } from 'react-device-detect';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { LabelValueCard } from '../../../shared_elements';
import InfoIcon from '@material-ui/icons/Info';
import { getCurrencyFormat, getNumberDecimalFormat, getLocalStorageInfo } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
import EditIcon from '@material-ui/icons/Edit';
import GaugeChart from './GaugeChart';
const LGCard = ({landingGearEdit, toggleEditFn, lgUpdateUsageFn, cancelUsageUpdateFn, mrLogDetail, onFieldChange, genericError, updateGenericErrorField, openInfoPopupFlag, closeInfoPopup, lgInfoPopup, lgUpdateUsageApi, isLocked, data, index}) => {
  const fundDateValidation = () => {
    const csn =  data?.csn
    const utilizationFC = data?.cycleUtlization
    const minDate  = (csn/utilizationFC) * 30.44
    return moment().subtract(parseInt(minDate), 'd')
  }
  return(
    <div className='apu-assembly-card'>
      <div>
        <Grid alignItems='center' container spacing={1}>
          <Grid item md={6}>
            <Table border={1} borderColor='#d7d7d7'>
              <TableRow>
                <TableCell width={data?.dateOfManufacture? '33.33%':'50%'}>
                  <p>Cycles Since New {data?.as_of_date ? data?.to_show ? `(as of ${moment(data?.as_of_date).format(displayDateFormatShort)})` : '':mrLogDetail.generalInfo.created_at ? `(as of ${moment(mrLogDetail.generalInfo.created_at).format(displayDateFormatShort)})` : ''}</p>
                  <h4>{data?.to_show ? data?.csn : '--'}</h4>
                </TableCell>
                { data?.dateOfManufacture ?
                  <TableCell width={data?.dateOfManufacture? '33.33%':'50%'}>
                    <p>Date of Manufacture</p>
                    <h4>{data?.dateOfManufacture ? moment(data?.dateOfManufacture).format(displayDateFormatShort):'--'  }</h4>
                  </TableCell>:null
                }
                <TableCell width={data?.dateOfManufacture? '33.33%':'50%'}>
                  <p>Assumed Utilization FC</p>
                  <h4>{data?.cycleUtlization ? data?.cycleUtlization : '--' }</h4>
                </TableCell>
              </TableRow>
            </Table>
          </Grid>
          <Grid item md={6}>
            <ul className="list-inline assembly-cta-list">
              { !landingGearEdit ?
                getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow']  && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 && !isLocked  ?
                <Tooltip title="Edit Landing Gear" arrow>
                  <IconButton color="primary" component="span" size="small">
                    <EditIcon onClick={toggleEditFn} color="primary" />
                  </IconButton>
                </Tooltip> : null
                :  !isLocked ?
                  <Fragment>
                    <li className="list-inline-item">
                      <Button size="small" color="primary" variant="contained" onClick={lgUpdateUsageFn}>Save</Button>
                    </li>
                    <li className="list-inline-item">
                      <Button size="small" color="primary" variant="outlined" onClick={cancelUsageUpdateFn}>Cancel</Button>
                    </li>
                  </Fragment> : null
              }
            </ul>
          </Grid>
        </Grid>
        <div className='apu-assembly-event'>
          <div className='event-name'>
            <h4>Landing Gear</h4>
          </div>
          <div className='event-details'>
            <Grid container spacing={1}>
                <Grid item md={8}>
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={6} md={4}>
                      <TextField
                        disabled={!landingGearEdit}
                        id="lg_currentFundValue"
                        label="Fund Balance"
                        fullWidth
                        margin="normal"
                        value={data?.currentFundValue}
                        InputLabelProps={{shrink: true}}
                        onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'currentFundValue', e.target.value, 'lg'):e.preventDefault()}}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">US$</InputAdornment>
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          disabled={!landingGearEdit}
                          required
                          margin="normal"
                          id="lg_fundDate"
                          label="Fund Balance as of"
                          format={fieldDateFormat}
                          fullWidth
                          onFocus={() => updateGenericErrorField('fundDate', '', 'lg', index)}
                          error={genericError && genericError.fundDate ? true : false }
                          helperText={genericError && genericError.fundDate ? genericError.fundDate : ''}
                          InputLabelProps={{shrink: true}}
                          maxDate={moment()}
                          minDate={data?.dateOfManufacture ? moment(data?.dateOfManufacture) : fundDateValidation()}
                          value={data?.fundDate ? data?.fundDate:null}
                          onChange={(data, value) => {onFieldChange(value, 'fundDate', data ? moment(data).format(backendDateFormat):data, 'lg'); updateGenericErrorField('fundDate', '', 'lg', index)}}
                          inputVariant="outlined"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <TextField
                        disabled={!landingGearEdit}
                        required
                        id="lg_actualMR"
                        label="MR Rate"
                        fullWidth
                        margin="normal"
                        error={genericError && genericError.actualMR ? true : false}
                        helperText={genericError && genericError.actualMR ? genericError.actualMR :''}
                        onFocus={() => updateGenericErrorField('actualMR', '', 'lg', index)}
                        value={data?.actualMR}
                        InputLabelProps={{shrink: true}}
                        onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'actualMR', e.target.value, 'lg'):e.preventDefault()}}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                          endAdornment: <InputAdornment position="end">/ Month</InputAdornment>
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <TextField
                        disabled={!landingGearEdit}
                        id="lg_escalation"
                        label="MR Rate Esc.(Annual)"
                        fullWidth
                        margin="normal"
                        value={data?.escalation}
                        InputLabelProps={{shrink: true}}
                        onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'escalation', e.target.value, 'lg'):e.preventDefault()}}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <TextField
                        disabled={!landingGearEdit}
                        id="lg_cost_escalation"
                        label="Event Cost Esc.(Annual)"
                        fullWidth
                        margin="normal"
                        value={data?.cost_escalation}
                        InputLabelProps={{shrink: true}}
                        onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'cost_escalation', e.target.value, 'lg'):e.preventDefault()}}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          disabled={!landingGearEdit}
                          margin="normal"
                          id="lg_nextShopvisitDate"
                          label="Next Qualified Due Date"
                          format={fieldDateFormat}
                          fullWidth
                          disablePast={true}
                          InputLabelProps={{shrink: true}}
                          value={data?.nextShopvisitDate ? data?.nextShopvisitDate:null}
                          onChange={(data, value) => {onFieldChange(value, 'nextShopvisitDate', data ? moment(data).format(backendDateFormat):data); }}
                          inputVariant="outlined"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          disabled={!landingGearEdit}
                          margin="normal"
                          id="lg_lastShopvisitDate"
                          label="Last Qualified Due Date"
                          error={genericError && genericError.lastShopvisitDate ? true :false}
                          helperText={genericError && genericError.lastShopvisitDate ? genericError.lastShopvisitDate : ''}
                          format={fieldDateFormat}
                          fullWidth
                          maxDate={moment()}
                          InputLabelProps={{shrink: true}}
                          value={data?.lastShopvisitDate ? data?.lastShopvisitDate:null}
                          onChange={(data, value) => {onFieldChange(value, 'lastShopvisitDate', data ? moment(data).format(backendDateFormat):data); updateGenericErrorField('lastShopvisitDate', '','lg', index)}}
                          inputVariant="outlined"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <TextField
                        disabled={!landingGearEdit}
                        required={data?.lastShopvisitDate ? true:false}
                        id="csn_at_lsv"
                        label="CSN @ Last SV"
                        error={genericError && genericError.csn_at_lsv ? true :false}
                        helperText={genericError && genericError.csn_at_lsv ? genericError.csn_at_lsv : ''}
                        onFocus={() =>  updateGenericErrorField('csn_at_lsv', '', 'lg', index)}
                        fullWidth
                        margin="normal"
                        value={data?.csn_at_lsv}
                        InputLabelProps={{shrink: true}}
                        inputProps={{ maxLength: 6 }}
                        onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'csn_at_lsv', e.target.value, 'lg'):e.preventDefault()}}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <TextField
                        disabled={!landingGearEdit}
                        id="event_cost"
                        label="Next Qualified Event Cost"
                        fullWidth
                        margin="normal"
                        value={data?.event_cost}
                        InputLabelProps={{shrink: true}}
                        variant="outlined"
                        onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'event_cost', e.target.value, 'lg'):e.preventDefault()}}
                        InputProps={{
                          startAdornment: <InputAdornment position="end">US$</InputAdornment>
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <TextField
                        disabled={!landingGearEdit}
                        id="rest_days"
                        label={
                          <div>
                            Ground Time
                            <Tooltip title={`Number of days the asset is grounded for SV to be completed.`} placement="top" arrow>
                              <InfoIcon fontSize="small" style={{verticalAlign:'bottom' , marginLeft:'3px'}} />
                            </Tooltip>
                          </div>
                        }
                        fullWidth
                        margin="normal"
                        value={data?.rest_days}
                        InputLabelProps={{shrink: true, style: { pointerEvents: "auto" }}}
                        onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'rest_days', e.target.value, 'lg'):e.preventDefault()}}
                        variant="outlined"
                          InputProps={{
                              endAdornment: <InputAdornment position="end">Days</InputAdornment>
                            }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={4}>
                  <GaugeChart 
                    unit={' / Month'}
                    min={data?.minMR}
                    max={data?.maxMR}
                    actual={data?.targetMR}
                  />
                </Grid>
            </Grid>
          </div>
        </div>
      </div>
      {lgInfoPopup ?
        <Dialog
          open={lgInfoPopup}
          onClose={closeInfoPopup}
          aria-labelledby="scroll-dialog-title"
        >
        <DialogContent dividers={true}>
          <div className="" style={isMobile ? {width:'auto'}:{minWidth:'400px'}}>
            <p className="" style={{alignItems:'center',marginTop:'100px' }} align="center" >
              Last Shop Visit Date is in between <strong>Fund Balance as of the date</strong> and <strong>Next Qualified Due Date</strong>.
              {/*Fund Calculation will start from <strong>Last Shop Visit Date ({moment(data?.lastShopvisitDate).format(displayDateFormatShort)})</strong>.*/}
              Confirm to proceed.
            </p>
            <div className="" align="center" style={{marginTop:'20px'}}  >
              <Button variant="contained" onClick={lgUpdateUsageApi} color="primary" style={{marginRight:'5px'}}>confirm</Button>
              <Button variant="outlined" onClick={closeInfoPopup} color={'primary'}>Cancel</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog> : null
      }
    </div>
  )
}
export default LGCard;
