import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Grid, TextField, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import ViewListIcon from '@material-ui/icons/ViewList';
import BarChartIcon from '@material-ui/icons/BarChart';
// import { globalGetService, globalPostService } from '../../../utils/globalApiServices';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ComparisonGraph from './ComparisonGraph';
const CompareLogs = ({ compareLogs, toggleModalFn, onExportCompareReport }) => {
    const [isLoading, setLoading] = useState(false)
    const [filter, setFilter] = useState({ label: 'Fund balance vs SV Cost', value: 1 })
    const [toggleView, setToggleView] = useState('list');
    return (
        <>
            <Dialog
                open={compareLogs.modal}
                onClose={toggleModalFn}
                className='console-management-modal'
                maxWidth={'lg'}
            >
                <DialogTitle id="scroll-dialog-title" style={{minWidth:'800px'}}>
                    <span style={{display:"flex", justifyContent:"space-between"}}>
                    {
                        toggleView == 'list' ?
                            'Compare Logs' :
                            <Autocomplete
                                options={[{ label: 'Fund balance vs SV Cost', value: 1 }, { label: 'Fund Balance vs MR Rate', value: 2 }, { label: 'SV Cost vs MR Rate', value: 3 }]}
                                getOptionLabel={option => option.label}
                                id="filter"
                                getOptionSelected={(option, val) => option.value == val.value}
                                style={{ width: 230 }}
                                disableClearable={true}
                                value={filter || null}
                                onChange={(e, value) => { setFilter(value); }}
                                renderInput={params => <TextField margin="normal"  {...params} label="Graph Type" placeholder="Select Graph Type" InputLabelProps={{ shrink: true }} variant="outlined" />}
                            />
                    }
                    <div style={{ alignItems:'center', display: 'flex' }}>
                        <span className='list-analytic-sw' style={{ width: '80px', marginRight: '10px', display:'inline-flex', marginLeft:'0px' }}>
                            <span className={toggleView == 'list' ? 'active' : ''}>
                                <ViewListIcon color={toggleView == 'list' ? 'primary' : 'default'} onClick={() => setToggleView('list')} />
                            </span>
                            <span className={toggleView == 'graph' ? 'active' : ''}>
                                <BarChartIcon onClick={() => { setToggleView('graph') }} color={toggleView == 'graph' ? 'primary' : 'default'} />
                            </span>
                        </span>
                        <Button color='primary' size='small' variant='outlined' onClick={() => onExportCompareReport()}> Export </Button> </div>
                        </span>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            {
                                toggleView == 'list' ?
                                    <Table className='mui-styled-table'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                {compareLogs.data.serial_number.map((item, index) =>
                                                    <TableCell>{item}</TableCell>
                                                )}

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {compareLogs.data.fields.map((ft, ftIndex) =>
                                                <TableRow key={ftIndex}>
                                                    <TableCell>{ft}</TableCell>
                                                    {compareLogs.data.row_items[ftIndex].map((item, index) =>
                                                        <TableCell>{item}</TableCell>
                                                    )}
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                    :
                                    <div>
                                        <ComparisonGraph 
                                        data ={compareLogs?.data}
                                        filter={filter}
                                        />
                                    </div>}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default CompareLogs