import React, { useState, useEffect } from 'react'
import AircraftSection from '../components/AircraftSection'
import { Grid, Paper, Tab, Table, TableBody, Tabs, Tooltip } from '@material-ui/core';
import { globalExportService, globalPostExportService, globalPostService } from '../../../utils/globalApiServices'
import ApuLgSection from '../components/ApuLgSection';
import EngineSection from '../components/EngineSection';
import GraphTableList from '../components/GraphTableList';
import { PageLoader } from '../../../shared_elements';
import { downloadFileType, getLocalStorageInfo } from '../../../utils';
import InfoIcon from '@material-ui/icons/Info';
import DetailedListAnalytics from '../components/DetailedListAnalytics';
export default function Analytics() {
  const [aircraftFilter, setAircraftFilter] = useState({ aircraft_type: [], maintenance_programs: [] })
  const [apuLgfilter, setApuLgFilter] = useState({ aircraft_type: [], apu_type: [] })
  const [engineData, setEngineData] = useState(false)
  const [engineFilter, setEngineFilter] = useState({ engine_type: [],run_id:{label: '1st Run', value: 1 }, graph_comparision: { label: 'On wing life vs Cost', value: 1 } })
  const [tabIndex, setTabIndex] = useState('logs');
  const [aircraftData, setAircraftData] = useState([])
  const [aircraftCheckData, setAircraftCheckData] = useState([])
  const [apuData, setApuData] = useState([])
  const [lgData, setLgData] = useState([])
  const [analyticsDetailedData, setAnalyticsDetailedData] = useState({})
  const [costLoader, setCostLoader] = useState(false)
  const [pageLoader, setPageLoader] = useState(false)
  const [checkLoader, setCheckLoader] = useState(false)
  const [apuLoader, setApuLoader] = useState(false)
  const [lgLoader, setLgLoader] = useState(false)
  const [filter, setFilter] = useState(false)
  const [engineLoader, setEngineLoader] = useState(false)
  const [eventData, setEventData] = useState('')
  
  const [graphList, setGraphList] = useState(false)
  const [graphType, setGraphType] = useState(false)
  useEffect(() => {
    getAllGraphData(tabIndex)
  }, [])
  const getAllGraphData = (newValue) => {
    getAircraftCostTypeGraphFn(newValue)
    getAircraftCheckTypeGraphFn(newValue)
    getApuGraphDataFn(newValue)
    getLgGraphDataFn(newValue)
    getEngineGraphDataFn(newValue)
  }
  const getAircraftCostTypeGraphFn = (tabIndex, data, tabVal, pagination={}, eventData={}) => {
    let detailedList =tabVal==='detailed' ? true : false
    let prevData = aircraftData
    let payload = {
      ...pagination,
      ...eventData,
      detailed_list :detailedList? true:null,
      filter: {
        aircraft_type: data?.aircraft_type?.map(item => { return item?.id })
      }
    }
    if(detailedList){
      setPageLoader(true)
    }
    else{
      setCostLoader(true)
    }
    globalPostService(`new-mr-calculator/analytics/${tabIndex}/aircraft_cost/`, payload)
      .then(response => {
        if(detailedList){
          setPageLoader(false)
          setAircraftData(prevData)
          setAnalyticsDetailedData({data:response.data.data, flag:true,type:'cost',tabIndex:tabIndex, filter:payload})
        }
        else{
          setFilter(payload?.filter)
          setCostLoader(false)
          setAircraftData(response.data.data)
        }
      })
  }
  const getAircraftCheckTypeGraphFn = (tabIndex, data, tabVal, pagination={}, eventData={}) => {
    let detailedList =tabVal==='detailed' ? true : false
    let prevData = aircraftCheckData
    let checkData = {
      ...pagination,
      ...eventData,
      detailed_list :detailedList? true:null,
      filter:detailedList ?filter: {
        aircraft_type: data?.aircraft_type?.map(item => { return item?.id }),
        maintenance_checks: data?.maintenance_programs?.map(item => { return item?.id }),
      }
    }
    if(detailedList){
      setPageLoader(true)
    }
    else{
      setCheckLoader(true)
    }
    globalPostService(`new-mr-calculator/analytics/${tabIndex}/aircraft_checks/`, checkData)
      .then(response => {
        if(detailedList){
          setPageLoader(false)
          setAircraftCheckData(prevData)
          setAnalyticsDetailedData({data:response.data.data, flag:true, type:'check',tabIndex:tabIndex, filter:checkData})
        }
        else{
          setCheckLoader(false)
          setFilter(checkData?.filter)
          setAircraftCheckData(response.data.data)
        }
      })
  }
  const getApuGraphDataFn = (tabIndex, data, tabVal, pagination={}, eventData={}) => {
    let detailedList =tabVal==='detailed' ? true : false
    let prevData = apuData
    let payload = {
      ...pagination,
      ...eventData,
      detailed_list :detailedList? true:null,
      filter:detailedList ?filter:  {
        aircraft_type: data?.aircraft_type?.map(item => { return item?.id }),
        apu_type: data?.apu_type?.map(item => { return item?.id }),
      }
    }
    if(detailedList){
      setPageLoader(true)
    }
    else{
      setApuLoader(true)
    }
    globalPostService(`new-mr-calculator/analytics/${tabIndex}/apu_graph/`, payload)
      .then(response => {
         if(detailedList){
          setPageLoader(false)
          setApuData(prevData)
          setAnalyticsDetailedData({data:response.data.data, flag:true,type:'apu',tabIndex:tabIndex, filter:payload})
        }
        else{
        setFilter(payload?.filter)
          setApuLoader(false)
          setApuData(response.data.data)
        }
      })
  }
  const getLgGraphDataFn = (tabIndex, data, tabVal, pagination={}, eventData={}) => {
    let detailedList =tabVal==='detailed' ? true : false
    let prevData = lgData
    let payload = {
      ...pagination,
      ...eventData,
      detailed_list :detailedList? true:null,
      filter:detailedList ?filter:  {
        aircraft_type: data?.aircraft_type?.map(item => { return item?.id })
      }
    }
    if(detailedList){
      setPageLoader(true)
    }
    else{
      setLgLoader(true)
    }
    globalPostService(`new-mr-calculator/analytics/${tabIndex}/lg_graph/`, payload)
      .then(response => {
        if(detailedList){
          setPageLoader(false)
          setLgData(prevData)
          setAnalyticsDetailedData({data:response.data.data, flag:true, type:'lg', filter:payload, tabIndex:tabIndex})
        }
        else{
        setFilter(payload?.filter)
        setLgLoader(false)
        setLgData(response.data.data)
        }
      })
  }
  const getEngineGraphDataFn = (tabIndex, data, tabVal , pagination={}, eventData={}) => {
     let detailedList =tabVal==='detailed' ? true : false
    let prevData = engineData
    let payload = {
      ...pagination,
      ...eventData,
      detailed_list :detailedList? true:null,
      filter:detailedList ?filter:  {
        region_of_operation: data?.region_of_operation?.id,
        derate: data?.derate?.id,
        graph_comparision: data?.graph_comparision,
        run_id: data?.run_id?.value,
        engine_type: data?.engine_type?.map(item => { return item?.id }),
        region_of_operation: data?.region_of_operation?.id,
        utlizationRatio: data?.utlizationRatio?.value ? parseFloat(data?.utlizationRatio?.value): data?.utlizationRatio
      }
    }
      if(detailedList){
      setPageLoader(true)
    }
    else{
      setEngineLoader(true)
    }
    globalPostService(`new-mr-calculator/analytics/${tabIndex}/engine_graph/`, payload)
      .then(response => {
          if(detailedList){
          setPageLoader(false)
          setEngineData(prevData)
          setAnalyticsDetailedData({data:response.data.data, flag:true, type:'engine', filter:payload, tabIndex:tabIndex})
        }
        else{
          setEngineFilter(prevState=>({
            ...prevState,
            engine_type:response.data.data?.engine_type.map((item,index) => ({name: item, id:response.data.data.engine_type_id[index]})),
            utlizationRatio:response.data.data?.ratios[1] ? null : {label:response.data.data?.ratios + ':1' , value:response.data.data?.ratios}
          }))
        setFilter(payload?.filter)
        setEngineLoader(false)
        setEngineData(response.data.data)
        }
      })
  }
  const getAircraftUpdateGraphs = (filter) => {
    getAircraftCostTypeGraphFn(tabIndex, filter)
    getAircraftCheckTypeGraphFn(tabIndex, filter)
  }
  const getLgApuUpdateGraphs = (filter) => {
    getApuGraphDataFn(tabIndex, filter)
    getLgGraphDataFn(tabIndex, filter)
  }
  const getEngineGraph = (filter) => {
    getEngineGraphDataFn(tabIndex, filter)
  }
  const onTabChange = (value) => {
    setTabIndex(value);
    getAllGraphData(value);
    setAircraftFilter({ aircraft_type: [], maintenance_programs: [] })
    setApuLgFilter({ aircraft_type: [], apu_type: [] })
    setEngineFilter({ engine_type: [],run_id:{label: '1st Run', value: 1 }, graph_comparision: { label: 'On wing life vs Cost', value: 1 } })
  }
  const getGraphListFn = (data, graphType) => {
    setPageLoader(true)
    globalPostService(`new-mr-calculator/analytics/list/`, data)
      .then(response => {
        setPageLoader(false)
        setGraphList({ data: response.data.data, modal: true, name:graphType })
      })
  }
  const exportGraphListFn = () => {
    setPageLoader(true)
    globalPostExportService(`new-mr-calculator/analytics/list/`, {...graphList?.data, download:true})
      .then(response => {
        setPageLoader(false)
        downloadFileType(response.data, (`${graphList?.name} Export`), 'xlsx');
      })
  }
  const callExternalFunction=(type, event, paginationData={per_page:10,page:1})=>{
    setEventData(event)
    setGraphType(type)
    let pagination={
      per_page:paginationData?.per_page,
      page:paginationData?.page
    }
    let detailedData ={

    }
    if(type ==='cost'){
      getAircraftCostTypeGraphFn(tabIndex, filter, 'detailed',pagination, event)
    }
   else if(type ==='check'){
    getAircraftCheckTypeGraphFn (tabIndex, filter, 'detailed',pagination, event)
    }
    else if(type ==='apu'){
      getApuGraphDataFn(tabIndex, filter, 'detailed',pagination, event)
    }
    else if(type ==='lg'){
      getLgGraphDataFn(tabIndex, filter,'detailed',pagination, event)
    }
   else if(type ==='engine'){
getEngineGraphDataFn(tabIndex, filter, 'detailed',pagination, event)
    }
  }
  let universalViewer = getLocalStorageInfo().defaultLessor?.commverge_universal_analytics === true
  return (
    <div className='analytics-graph-container'>
      {
        pageLoader ? <PageLoader /> : null
      }
       {
          !universalViewer ?
          <div className='analytics-head-wp'>
            Log Specific
          </div> :
          <Paper className='tabs-cls'>
            <Tabs indicatorColor='primary' value={tabIndex} onChange={(event, newValue) => onTabChange(newValue)} variant='fullWidth'>
              <Tab label={
                <span>
                  Log specific
                  <Tooltip title={`Contains cumulative data from the logs generated.`} placement="top" arrow>
                    <InfoIcon fontSize="small" style={{ position: 'relative', top: "4px", marginLeft: '4px', fontSize: '18px' }} />
                  </Tooltip>
                </span>
              }
                value='logs' />
              <Tab label="Universal" value='generic' />
            </Tabs>
          </Paper>
      }
      <Paper>
      <EngineSection
        callExternalFunction={callExternalFunction}
          getGraphListFn={getGraphListFn}
          engineFilter={engineFilter}
          getResponseBack={getEngineGraph}
          setEngineFilter={setEngineFilter}
          engineLoader={engineLoader}
          engineData={engineData}
          tabIndex={tabIndex}
        />
        <AircraftSection
        callExternalFunction={callExternalFunction}
          getGraphListFn={getGraphListFn}
          graphList={graphList}
          setAircraftFilter={setAircraftFilter}
          aircraftFilter={aircraftFilter}
          getResponseBack={getAircraftUpdateGraphs}
          costLoader={costLoader}
          checkLoader={checkLoader}
          aircraftData={aircraftData}
          aircraftCheckData={aircraftCheckData}
          tabIndex={tabIndex}
        />
        <ApuLgSection
        callExternalFunction={callExternalFunction}
          getGraphListFn={getGraphListFn}
          graphList={graphList}
          apuLgfilter={apuLgfilter}
          setApuLgFilter={setApuLgFilter}
          getResponseBack={getLgApuUpdateGraphs}
          lgLoader={lgLoader}
          apuLoader={apuLoader}
          lgData={lgData}
          apuData={apuData}
          tabIndex={tabIndex}
        />
      </Paper>
      {
        graphList?.modal ?
        <GraphTableList
        exportGraphListFn={exportGraphListFn}
        setGraphList={setGraphList}
        graphList={graphList}
        />
      : null}
      {
        analyticsDetailedData?.flag ?
        <DetailedListAnalytics 
        eventData={eventData}
        graphType={graphType}
        callExternalFunction={callExternalFunction}
        analyticsDetailedData={analyticsDetailedData}
        closeModal={()=> setAnalyticsDetailedData({flag:false})}
        />
        : null
      }

    </div>
  )
}
