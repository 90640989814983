import React, { Fragment } from 'react';
import { Button, Paper, Grid, TextField, FormControlLabel, Checkbox, InputAdornment, Tooltip, IconButton, Dialog, DialogTitle, DialogActions,DialogContent } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import { isMobile } from 'react-device-detect';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import CustomzieLLPModal from './CustomzieLLPModal';
import EditIcon from '@material-ui/icons/Edit';
import { LabelValueCard } from '../../../shared_elements';
import { getCurrencyFormat, getNumberDecimalFormat, getLocalStorageInfo } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
import InfoIcon from '@material-ui/icons/Info';
import GaugeChart from './GaugeChart';
const EngineCard = ({enginesEdit, engine, toggleEditFn, engineIndex, onFieldChange, customCycleRemainFn, mrLogDetail, updateCustomLLPCyclesFn, updateEngineUsageFlag, error, updateErrorField, updateEngineUsageFn,cancelUsageUpdateFn, closeEditedCard, updateEngineUsageApi, editedSvCardFlag, viewLlpFn, engineLLPCalFn, isLocked, shopVisitWarningUpdate, warningModal }) => {
  return(
    <Fragment>
    <Paper style={{borderRadius:0}} className="engine-assembly-event">
      <div className='event-name flex-centered'>
        <h4>Engine Shop Visit Details</h4>
        <ul className="list-inline assembly-cta-list">
          { !enginesEdit ?
            getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow']  && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 && !isLocked  ?
            <Tooltip title={`Edit Engine ${parseInt(engineIndex)+1}`} arrow>
              <IconButton color="primary" component="span" size="small">
                <EditIcon fontSize='small' onClick={toggleEditFn} color="primary" />
              </IconButton>
            </Tooltip> : null
            :!isLocked ?
              <Fragment>
                <li className="list-inline-item">
                  <Button size="small" color="primary" variant="contained" onClick={updateEngineUsageFn}>Save</Button>
                </li>
                <li className="list-inline-item">
                  <Button size="small" color="primary" variant="outlined" onClick={cancelUsageUpdateFn} >Cancel</Button>
                </li>
              </Fragment> : null
          }
        </ul>
      </div>
      <div>
        <div className='event-details'>
          <div className='event-basic-detail'>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} md={8}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        required
                        disabled={!enginesEdit}
                        margin="normal"
                        id="fund_date"
                        label="Fund Date"
                        format={fieldDateFormat}
                        fullWidth
                        minDate={engine.lsv_date ? engine.lsv_date : moment("05/1/1969")}
                        error={error.fund_date ? true: false}
                        helperText={error.fund_date ? error.fund_date : ''}
                        InputLabelProps={{shrink: true}}
                        value={engine.fund_date ? engine.fund_date:null}
                        onChange={(data, value) => {onFieldChange(value, 'fund_date', data ? moment(data).format(backendDateFormat):data, 'engines', engineIndex)}}
                        onAccept={(e) => error && error.fund_date && updateErrorField('fund_date', '')}
                        inputVariant="outlined"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <TextField
                      disabled={!enginesEdit}
                      required={(engine.lsv_date || engine.tsn_at_lsv || engine.csn_at_lsv) ? true:false}
                      id="tsn"
                      label={`Time Since New`}
                      fullWidth
                      error={error.tsn ? true: false}
                      helperText={error.tsn ? error.tsn : ''}
                      onFocus={() =>  {updateErrorField('tsn', ''); updateErrorField('tsn_at_lsv', '')}}
                      margin="normal"
                      value={engine.tsn}
                      inputProps={{ maxLength: 6 }}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">FH</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <TextField
                      disabled={!enginesEdit}
                      required={(engine.lsv_date || engine.tsn_at_lsv || engine.csn_at_lsv) ? true:false}
                      id="csn"
                      label={`Cycles Since New`}
                      fullWidth
                      error={error.csn ? true: false}
                      helperText={error.csn ? error.csn : ''}
                      onFocus={() =>{updateErrorField('csn', ''); updateErrorField('csn_at_lsv')}}
                      margin="normal"
                      inputProps={{ maxLength: 6 }}
                      value={engine.csn}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'csn', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">FC</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        disabled={!enginesEdit}
                        required={(engine.lsv_date || engine.tsn_at_lsv || engine.csn_at_lsv) ? true:false}
                        margin="normal"
                        id="lsv_date"
                        label="Last SV Date"
                        format={fieldDateFormat}
                        fullWidth
                        maxDate={engine.fund_date ? engine.fund_date : moment()}
                        error={error.lsv_date ? true: false}
                        helperText={ error.lsv_date ? error.lsv_date : ''}
                        InputLabelProps={{shrink: true}}
                        onOpen={(e) => error && error.lsv_date && updateErrorField('lsv_date', '')}
                        value={engine.lsv_date ? engine.lsv_date:null}
                        onChange={(data, value) => {onFieldChange(value, 'lsv_date', data ? moment(data).format(backendDateFormat):data, 'engines', engineIndex); }}
                        onFocus={(e) => error && error.lsv_date && updateErrorField('lsv_date', '')}
                        inputVariant="outlined"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <TextField
                      disabled={!enginesEdit}
                      required={(engine.lsv_date || engine.tsn_at_lsv || engine.csn_at_lsv) ? true:false}
                      id="tsn_at_lsv"
                      label="TSN @ Last SV"
                      fullWidth
                      error={error.tsn_at_lsv ? true: false}
                      helperText={ error.tsn_at_lsv ? error.tsn_at_lsv : ''}
                      onFocus={(e) => error && error.tsn_at_lsv ? updateErrorField('tsn_at_lsv', '') : e.preventDefault()}
                      margin="normal"
                      value={engine.tsn_at_lsv}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn_at_lsv', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">FH</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <TextField
                      disabled={!enginesEdit}
                      required={(engine.lsv_date || engine.tsn_at_lsv || engine.csn_at_lsv) ? true:false}
                      id="csn_at_lsv"
                      label="CSN @ Last SV"
                      fullWidth
                      error={error.csn_at_lsv ? true: false}
                      helperText={ error.csn_at_lsv ? error.csn_at_lsv : ''}
                      margin="normal"
                      onFocus={(e) => error && error.csn_at_lsv ? updateErrorField('csn_at_lsv', '') : e.preventDefault()}
                      value={engine.csn_at_lsv}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'csn_at_lsv', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">FC</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <TextField
                      disabled={!enginesEdit}
                      id="hour_util"
                      required
                      label="Assumed Utilization"
                      fullWidth
                      error={error.hour_util ? true : false}
                      helperText={error.hour_util ? error.hour_util : ''}
                      onFocus={(e) => error && error.hour_util ? updateErrorField('hour_util', '') : e.preventDefault()}
                      margin="normal"
                      value={engine.hour_util}
                      InputLabelProps={{shrink: true}}
                      inputProps={{ maxLength: 6 }}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value < 732 ? onFieldChange(e, 'hour_util', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">FH</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <TextField
                      disabled={true}
                      id="cycle_util"
                      label="Assumed Utilization"
                      fullWidth
                      margin="normal"
                      value={engine.cycle_util}
                      InputLabelProps={{shrink: true}}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">FC</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <TextField
                      disabled={!enginesEdit}
                      id={`rest_days${engineIndex}`}
                      fullWidth
                      label={
                        <div>
                          Ground Time
                          <Tooltip title={`Number of days the asset is grounded for SV to be completed.`} placement="top" arrow>
                            <InfoIcon fontSize="small" style={{verticalAlign:'bottom' , marginLeft:'3px'}} />
                          </Tooltip>
                        </div>
                      }
                      error={error.rest_days ? true : false}
                      helperText={error.rest_days ? error.rest_days : ''}
                      onFocus={(e) => error && error.rest_days ? updateErrorField('rest_days', '') : e.preventDefault()}
                      margin="normal"
                      value={engine.rest_days? engine.rest_days : ''}
                      InputLabelProps={{shrink: true, style: { pointerEvents: "auto" }}}
                      onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'rest_days', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                      variant="outlined"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">Days</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={12}>
                    { !enginesEdit ?
                      <span className="llp-csn" style={{fontSize:'14px'}}>Match LLPs to Engine CSN: <b>{engine.engineLLPStatus ? 'Yes':'No'}</b></span>
                      :
                      <FormControlLabel
                        control={
                          <Checkbox size="small" color="primary" onChange={(e) => {enginesEdit ? onFieldChange(e, 'engineLLPStatus', e.target.checked, 'engines', engineIndex): e.preventDefault()}} checked={engine.engineLLPStatus} value="Match LLPs to Engine CSN" />
                        }
                        label={<span style={{fontSize:'14px'}}>Match LLPs to Engine CSN</span>}
                      />
                    }
                    <CustomzieLLPModal
                      mrLogDetail={mrLogDetail}
                      engineIndex={engineIndex}
                      toggleEditFn={toggleEditFn}
                      viewLlpFn={viewLlpFn}
                      updateCustomLLPCyclesFn={updateCustomLLPCyclesFn}
                      enginesEdit={enginesEdit}
                      engine={engine}
                      updateEngineUsageApi={updateEngineUsageApi}
                      lsvDate={engine.lsv_date}
                      isLocked={isLocked}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4}>
                <GaugeChart 
                  unit={' / FH'}
                  min={engine.blended_mr.min}
                  max={engine.blended_mr.max}
                  actual={engine.blended_mr.value}
                />
              </Grid>
            </Grid>
          </div>
          <div className='prsv-llp-event'>

            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} md={6}>
                <h4>Performance Restoration ( PRSV )</h4>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      disabled={!enginesEdit}
                      id="fund"
                      label="Fund Balance As of Date"
                      fullWidth
                      margin="normal"
                      value={engine.fund}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'fund', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">US$</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled={!enginesEdit}
                      id="actual_prsv_mr"
                      label="MR Rate"
                      fullWidth
                      margin="normal"
                      value={engine.actual_prsv_mr}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'actual_prsv_mr', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                        endAdornment: <InputAdornment position="end">/ FH</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled={!enginesEdit}
                      id="escalation"
                      label="MR Rate Esc.(Annual)"
                      fullWidth
                      margin="normal"
                      value={engine.engine_escalation}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'engine_escalation', e.target.value, 'engines', engineIndex):e.preventDefault()}}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled={!enginesEdit}
                      id="prsv_cost_escalation"
                      label="Event Cost Esc.(Annual)"
                      fullWidth
                      margin="normal"
                      value={engine.prsv_cost_escalation}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'prsv_cost_escalation', e.target.value, 'engines', engineIndex):e.preventDefault()}}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
              <h4>LLP Replacement</h4>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      disabled={!enginesEdit}
                      id="fund_llp"
                      label="Fund Balance As of Date"
                      fullWidth
                      margin="normal"
                      value={engine.fund_llp}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'fund_llp', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">US$</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled={!enginesEdit}
                      id="actual_llp_mr"
                      label="MR Rate"
                      fullWidth
                      margin="normal"
                      value={engine.actual_llp_mr}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'actual_llp_mr', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                        endAdornment: <InputAdornment position="end">/ FC</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled={!enginesEdit}
                      id="llp_escalation"
                      label="MR Rate Esc.(Annual)"
                      fullWidth
                      margin="normal"
                      value={engine.llp_escalation}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'llp_escalation', e.target.value, 'engines', engineIndex):e.preventDefault()}}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled={!enginesEdit}
                      id="llp_cost_escalation"
                      label="Event Cost Esc.(Annual)"
                      fullWidth
                      margin="normal"
                      value={engine.llp_cost_escalation}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'llp_cost_escalation', e.target.value, 'engines', engineIndex):e.preventDefault()}}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className='event-cut-off-blk'>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6} md={3}>
              <TextField
                disabled={!enginesEdit}
                id="llp_cut_off"
                label="Commercial Value LLP Cut Off"
                fullWidth
                error={error.llp_cut_off ? true: false}
                helperText={ error.llp_cut_off ? error.llp_cut_off : ''}
                onFocus={(e) => error && error.llp_cut_off ? updateErrorField('llp_cut_off', '') : e.preventDefault()}
                margin="normal"
                value={engine.llp_cut_off}
                InputLabelProps={{shrink: true}}
                onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) && e.target.value <= engine.llp_max_for_validation  ? onFieldChange(e, 'llp_cut_off', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">FC</InputAdornment>
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <TextField
                disabled={!enginesEdit}
                id="llp_build_goal"
                label={
                  <div>
                    LLP Build Goal
                    <Tooltip title={`Default is 10% of Life on Wing of Next Shop Visit.`} placement="top" arrow>
                      <InfoIcon fontSize="small" style={{verticalAlign:'bottom' , marginLeft:'3px'}} />
                    </Tooltip>
                  </div>
                }
                fullWidth
                error={error.llp_build_goal ? true: false}
                helperText={ error.llp_build_goal ? error.llp_build_goal : ''}
                onFocus={(e) => error && error.llp_build_goal ? updateErrorField('llp_build_goal', '') : e.preventDefault()}
                margin="normal"
                value={engine.llp_build_goal}
                InputLabelProps={{shrink: true, style: { pointerEvents: "auto" }}}
                onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) && e.target.value <= engine.llp_max_for_validation  ? onFieldChange(e, 'llp_build_goal', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">FC</InputAdornment>
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <TextField
                disabled={!enginesEdit}
                id="stub_life_list_price"
                label="Stub Life List Price"
                fullWidth
                error={error.stub_life_list_price ? true: false}
                helperText={ error.stub_life_list_price ? error.stub_life_list_price : ''}
                onFocus={(e) => error && error.stub_life_list_price ? updateErrorField('stub_life_list_price', '') : e.preventDefault()}
                margin="normal"
                value={engine.stub_life_list_price}
                InputLabelProps={{shrink: true}}
                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 999 ? onFieldChange(e, 'stub_life_list_price', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <TextField
                disabled={!enginesEdit}
                id="stub_life_limit"
                label="Stub Life Limit"
                fullWidth
                error={error.stub_life_limit ? true: false}
                helperText={ error.stub_life_limit ? error.stub_life_limit : ''}
                onFocus={(e) => error && error.stub_life_limit ? updateErrorField('stub_life_limit', '') : e.preventDefault()}
                margin="normal"
                value={engine.stub_life_limit}
                InputLabelProps={{shrink: true}}
                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 999 ? onFieldChange(e, 'stub_life_limit', e.target.value, 'engines', engineIndex): e.preventDefault()}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </div>
        { !engine.workscopes[Object.keys(engine.workscopes)[0]].nsv ?
          <span className="engine-alert">Please Update engine usage details to Review MR Data
            {!enginesEdit ?
              <Fragment>,<span onClick={updateEngineUsageFlag}>Click Here</span></Fragment>:null
            }
          </span>:null
        }
      </div>
      {engine.edited_sv_card && warningModal ?
        <Dialog
          open={editedSvCardFlag}
          onClose={closeEditedCard}
          aria-labelledby = "scroll-dialog-title"
        >
          <DialogContent>
            <div className="" style={isMobile ? {width:'auto'}:{minWidth:'400px'}}>
              <p className="" style={{alignItems:'center',marginTop:'50px' }} align="center" >
                Shop Visit related changes will reset. Confirm to proceed
              </p>
              <div className="" align="center" style={{marginTop:'20px'}}  >
                <Button variant="contained" onClick={shopVisitWarningUpdate} color="primary" style={{marginRight:'5px'}}>confirm</Button>
                <Button variant="outlined"  onClick={closeEditedCard} color={'primary'}>Cancel</Button>
              </div>
            </div>
          </DialogContent>
        </Dialog> : null
      }
    </Paper>
    </Fragment>
  )
}
export default EngineCard;
