import moment from 'moment';
import { globalGetService, globalPostService, globalDeleteService, globalExportService, globalPostExportService, globalStaticPostService, globalPutService } from '../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo, removeEmptyKey } from '../../utils';
import { fieldValidation } from '../../utils/formValidation';
import { trackActivity } from '../../utils/mixpanel'
import { errorCode, engineUsageErrorCode, genericErrorCode, deRateErrorCode, financialPlotsErrorCode, financialPlotsEngineErrorCode } from './';
const regex = /^[0-9]*$/;
export function geMRCalculatorUsageApi(props) {
  globalGetService(`/console/license/mr/usage/`)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({ mrCalculatorUsage: response.data.data })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
export function getApuLgLimitApi(aircraftId) {
  globalGetService(`new-mr-calculator/get-apu-lg-limit/${aircraftId}/`)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({
          apuLgLimit: response.data.data
        })
      }
    })
}
export function updateRegionMapsApi(props, data) {
  return new Promise(
    function (resolve, reject) {
      globalPostService(`console/regions_maps/`, data)
        .then(response => {
          resolve(response)
        })
    }
  )
}

export function getRegionCustomVariablesApi(query) {
  this.setState({ pageLoader: true });
  globalGetService(`console/regions/`, query)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.setState({
          regionCondition: response.data.data
        });
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
export function getMntEventsApi(props = {}, aircraftTypeId) {
  globalGetService(`console/aircraft-type/${aircraftTypeId}/events/`)
    .then(response => {
      if (checkApiStatus(response)) {
        let maintanenceList = [];
        const data = response.data.data;
        Object.keys(data).map((item, index) => {
          maintanenceList.push({ label: data[item].replace(/,/g, '/') + '', value: item })
        });
        this.setState({ mntGroups: maintanenceList });
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
export function initiateMrLogApi(props={}, data={}){
  const { radioCheck, radioCheckLg, apuLgLimit, stateFlagCheck } = this.state;
  let payload = Object.assign({}, data)
  if ([24].includes(getLocalStorageInfo().defaultLessor.id)) {
    payload = {
      ...payload,
      use_acumen_algorithm: false
    }
  }
  let validationInputs = {};
  if (data.metricsInfo.includes('airframe')) {
    validationInputs = {
      ...validationInputs,
      maintenanceProgram: errorCode['maintenanceProgram'][fieldValidation({...errorCode['maintenanceProgramObj'], fieldval: data.airframeAndGen.maintenanceProgram})],
      dateOfManufacture: moment(data?.airframeAndGen?.dateOfManufacture, 'YYYY.MM.DD').endOf('day').diff(moment().endOf('day'), 'days') > 0 ?  " Date of Manufacturing cannot be future date" :errorCode['dateOfManufacture'][fieldValidation({...errorCode['dateOfManufactureObj'], fieldval: data.airframeAndGen.dateOfManufacture})],
      aircraftType:errorCode['aircraftType'][fieldValidation({...errorCode['aircraftTypeObj'], fieldval: data.airframeAndGen.aircraftType})],
    }
  } else {
    delete payload['airframeAndGen']
  }
  if (data.metricsInfo.includes('apu')) {
    validationInputs = {
      ...validationInputs,
      apuType: errorCode['apuType'][fieldValidation({ ...errorCode['apuTypeObj'], fieldval: data.apuAndGen.apuType })],
      apu_hourlyUtlization: errorCode['hourlyUtlization'][fieldValidation({ ...errorCode['hourlyUtlizationObj'], fieldval: data.apuAndGen.hourlyUtlization })],
      tsnAtLsvDom: radioCheck == "dol" && !data.apuAndGen.tsn_at_lsv && !data.apuAndGen.dateOfLastOverhual ? "Please enter either Date of Last Overhaul or TSN at Last Shop Visit" : '',
      tsnAndDom: radioCheck == "dom" && !data.apuAndGen.tsn && !data.apuAndGen.dateOfLastOverhual ? "Please enter either Time Since New or Date of Manufacture" : '',
      as_of_date: data.apuAndGen.as_of_date>=data.apuAndGen.dateOfLastOverhual ? '' : radioCheck == "dom" ? "As of Date must be greater than or equal to Date of Manufacture" : "As of Date must be greater than or equal to Date of Last Overhaul"
    }
    if (Number(data.apuAndGen.hourlyUtlization) == 0) {
      validationInputs = {
        ...validationInputs,
        apu_hourlyUtlization: 'Please enter Assumed Utilization greater than 0'
      }
    }
    if (radioCheck == "dol") {
      validationInputs = {
        ...validationInputs,
        dateOfLastOverhual: errorCode['dateOfLastOverhual'][fieldValidation({ ...errorCode['dateOfLastOverhualObj'], fieldval: data.apuAndGen.dateOfLastOverhual })],
        tsn_at_lsv: errorCode['tsn_at_lsv'][fieldValidation({ ...errorCode['tsn_at_lsvObj'], fieldval: data.apuAndGen.tsn_at_lsv })]
      }
      if (data.apuAndGen.tsn) {
        if (parseFloat(data.apuAndGen.tsn) < parseFloat(data.apuAndGen.tsn_at_lsv)) {
          validationInputs = {
            ...validationInputs,
            tsn_at_lsv: 'Please enter TSN at Last Shop Visit Less than Time Since New'
          }
        }
        if (parseFloat(data.apuAndGen.tsn) > parseFloat(apuLgLimit.apu.limit) && parseFloat(data.apuAndGen.tsn_at_lsv) < parseFloat(data.apuAndGen.tsn - apuLgLimit.apu.limit)) {
          validationInputs = {
            ...validationInputs,
            tsn_at_lsv: `TSN at Last Shop Visit should be between ${parseFloat(data.apuAndGen.tsn - apuLgLimit.apu.limit)} and ${data.apuAndGen.tsn}`
          }
        }
      }
    }
  } else {
    delete payload['apuAndGen']
  }
  if (data.metricsInfo.includes('lg')) {
    validationInputs = {
      ...validationInputs,
      lg_cycleUtlization: errorCode['cycleUtlization'][fieldValidation({ ...errorCode['cycleUtlizationObj'], fieldval: data.lgAndGen.cycleUtlization })],
      csnAtLsvAndDOM: radioCheckLg == "dol" && !data.lgAndGen.csn_at_lsv && !data.lgAndGen.dateOfLastOverhual ? 'Please enter either Date of Last Overhaul or CSN at Last Shop Visit' : "",
      csnAndDOM: radioCheckLg == "dom" && !data.lgAndGen.csn && !data.lgAndGen.dateOfLastOverhual ? "Please enter either Cycles Since New or Date of Manufacture" : '',
      as_of_date: data.lgAndGen.as_of_date>=data.lgAndGen.dateOfLastOverhual ? '' : "As of Date must be greater than or equal to Date of Manufacture/Date of Last Overhaul"
    }
    if (radioCheckLg == "dol") {
      validationInputs = {
        ...validationInputs,
        dateOfLastOverhualLg: errorCode['dateOfLastOverhual'][fieldValidation({ ...errorCode['dateOfLastOverhualObj'], fieldval: data.lgAndGen.dateOfLastOverhual })],
        csn_at_lsv: errorCode['csn_at_lsv'][fieldValidation({ ...errorCode['csn_at_lsvObj'], fieldval: data.lgAndGen.csn_at_lsv })]
      }
      if (data.lgAndGen.csn) {
        if (Number(data.lgAndGen.csn) < Number(data.lgAndGen.csn_at_lsv)) {
          validationInputs = {
            ...validationInputs,
            csn_at_lsv: "Please enter CSN at Last Shop Visit Less than Cycles Since New"
          }
        }
        if (Number(data.lgAndGen.csn) > Number(apuLgLimit.lg.limit) && Number(data.lgAndGen.csn_at_lsv) < Number(data.lgAndGen.csn - apuLgLimit.lg.limit)) {
          validationInputs = {
            ...validationInputs,
            csn_at_lsv: `CSN at Last Shop Visit should be between ${parseFloat(data.lgAndGen.csn - apuLgLimit.lg.limit)} and ${data.lgAndGen.csn}`
          }
        }
      }
    }
  } else {
    delete payload['lgAndGen']
  }
  if (data.metricsInfo.includes('engine')) {
    validationInputs = {
      ...validationInputs,
      engineType: errorCode['engineType'][fieldValidation({ ...errorCode['engineTypeObj'], fieldval: data.engine.engineType })],
      regionOfOperation: data.metricsInfo.includes('engine') ? errorCode['regionOfOperation'][fieldValidation({ ...errorCode['regionOfOperationObj'], fieldval: data.engine.regionOfOperation })] : '',
      utlizationRatio: errorCode['utlizationRatio'][fieldValidation({ ...errorCode['utlizationRatioObj'], fieldval: data.engine.utlizationRatio })],
      utilization_hours: errorCode['utilization_hours'][fieldValidation({ ...errorCode['utilization_hoursObj'], fieldval: data.engine.utilization_hours })],
    }
    if (data.location) {
      validationInputs = {
        ...validationInputs,
        country: errorCode['country'][fieldValidation({ ...errorCode['countryObj'], fieldval: data.location.country_name })],
        // state:errorCode['state'][fieldValidation({...errorCode['stateObj'], fieldval: data.location.state_name})],
      }
      if (stateFlagCheck) {
        validationInputs = {
          ...validationInputs,
          state: errorCode['state'][fieldValidation({ ...errorCode['stateObj'], fieldval: data.location.state_name })]
        }
      }
    }
  } else {
    delete payload['engine']
  }
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
    if (data.metricsInfo.includes('airframe')) {
      payload = {
        ...payload,
        airframeAndGen: {
          ...payload.airframeAndGen,
          maintenanceProgram: payload.airframeAndGen.maintenanceProgram.value,
          tsn: !payload.airframeAndGen.tsn ? '0' : payload.airframeAndGen.tsn,
          csn: !payload.airframeAndGen.csn ? '0' : payload.airframeAndGen.csn,
        }
      }
    }
    if (data.metricsInfo.includes('apu')) {
      payload = {
        ...payload,
        apuAndGen: {
          ...payload.apuAndGen,
          dom_or_dlo: radioCheck == 'dom' ? 1 : 2,
          tsn: payload.apuAndGen.tsn && payload.apuAndGen.tsn !== '' ? payload.apuAndGen.tsn : ''
        }
      }
    }
    if (data.metricsInfo.includes('lg')) {
      payload = {
        ...payload,
        lgAndGen: {
          ...payload.lgAndGen,
          dom_or_dlo: radioCheckLg == "dom" ? 1 : 2,
          csn: payload.lgAndGen.csn && payload.lgAndGen.csn !== '' ? payload.lgAndGen.csn : "",
          tsn: payload.lgAndGen.tsn && payload.lgAndGen.tsn !== '' ? payload.lgAndGen.tsn : 0
        }
      }
    }
    this.setState({ pageLoader: true });
    globalPostService(`new-mr-calculator/initiate/?timestamp=${new Date().getTime()}`, payload)
      .then(response => {
        this.setState({ pageLoader: false });
        if (checkApiStatus(response)) {
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          trackActivity('MR Log Generated', { log_id: response.data.data.log_id, page_title: 'Generate MR Log' })
          props.history.push(`/intermediate/${response.data.data.log_id}`)
        } else if (response.data.statusCode == 5000 || response.data.statusCode == 5001 || response.data.statusCode == 5002) {
          this.setState({
            licenseModal: true,
            licenseDesc: response.data.message
          })
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          trackActivity(' MR Log Generation Failed', { page_title: 'Generate MR Log', source: 'backend' })
        }
      })
  } else {
    this.setState({ error: validationInputs });
    trackActivity(' MR Log Generation Failed', { page_title: 'Generate MR Log', source: 'frontend' })
  }
}
export function getMrLogListApi(props = {}, query = {}, loaderType) {
  this.setState({ [loaderType]: true });
  globalGetService(`mr-calculator/logs/list/`, removeEmptyKey(query))
    .then(response => {
      this.setState({ [loaderType]: false });
      if (checkApiStatus(response)) {
        delete query.sort;
        delete query.sort_by;
        delete query.page;
        delete query.per_page;
        this.setState({
          mrLogsInfo: response.data.data,
          filter: query
        });
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
export function getMrLogDetailApi(props = {}, assemblyType) {
  this.setState({ pageLoader: true });
  globalGetService(`new-mr-calculator/log/${props.match.params.id}/`)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        let result = response.data.data;
        let assemblyIndex = '';
        if(assemblyType){
          assemblyIndex = assemblyType
        }else{
          if(result.airframe){
            assemblyIndex = 'airframe'
          }else if(result.engines){
            assemblyIndex = 'engines'
          }else if(result.apu){
            assemblyIndex = 'apu'
          }else{
            assemblyIndex = 'landing_gear'
          }    
        }
        this.setState({ mrLogDetail: response.data.data, assemblyIndex:assemblyIndex })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
export function updateLockApi(props = {}, data = {}) {
  this.setState({ pageLoader: true })
  globalPostService(`new-mr-calculator/update-log/`, data)
    .then(response => {
      this.setState({ pageLoader: false })
      if (checkApiStatus(response)) {
        if (props.location.pathname.includes('intermediate')) {
          this.getMrLogDetailApi(props, this.state.assemblyIndex)
        } else {
          this.getMrLogListApi(props, {}, 'pageLoader')
        }
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        this.setState({
          airframeEdit: false,
          enginesEdit: false,
          apuEdit: false,
          landingGearEdit: false,
          lockEdit: true,
          refNoModal:false
        })
        trackActivity(data.is_locked === true ? 'Log Locked' : 'Log Unlocked', { page_title: props.location.pathname.includes('intermediate') ? 'Intermediate' : 'Log Listing', log_id: data.log_id })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
export function exportLogInfoApi(props = {}, query = {}) {
  this.setState({ pageLoader: true });
  globalExportService(`mr-calculator/logs/list/`, query)
    .then(response => {
      this.setState({ pageLoader: false });
      trackActivity('Logs List Exported', { page_title: 'Log Listing', extension: query.download })
      downloadFileType(response.data, (`CommVerge Log_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), query.download);
      this.props.enqueueSnackbar("Log List Exported successfully", { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    })
}
export function deleteMrLogApi(props = {}, data = {}) {
  this.setState({ pageLoader: true });
  globalDeleteService(`mr-calculator/delete-log/`, data)
    .then(response => {
      if (checkApiStatus(response)) {
        this.getMrLogListApi(this.props, {}, 'pageLoader');
        this.setState({ deleteModal: false, deleteIds: [] });
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        trackActivity('Log Deleted', { log_id: data.log_id })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
export function getEolConditionApi (props){
  globalGetService(`new-mr-calculator/eol/conditions-list/${props?.match?.params?.id}/`)
  .then(response =>{
     if(checkApiStatus(response)){
         this.setState({eol_conditionList :response.data.data})
     }
 })
}
export function getEolScenariosApi (props){
  this.setState({eolPageLoader:true})
  globalGetService(`new-mr-calculator/eol-scenario/view/${props?.match?.params?.id}/`)
  .then(response =>{
    this.setState({eolPageLoader:false})
     if(checkApiStatus(response)){
         this.setState({eol_scenarios:response.data.data})
     }
 })
}
export function deleteEolScenariosApi (props, query){
  this.setState({pageLoader:true})
  globalDeleteService(`new-mr-calculator/eol-scenario/delete/${props?.match?.params?.id}/`, query)
  .then(response =>{
     if(checkApiStatus(response)){
      this.setState({pageLoader:false})
      this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      this.getEolScenariosApi(props)
     }
 })
}
export function addEolScenariosApi(props, query={}){
  this.setState({pageLoader:true})
  globalPostService(`new-mr-calculator/eol-scenario/add-edit/${props?.match?.params?.id}/`,{'eol_scenarios':query})
  .then(response =>{
    this.setState({pageLoader:false})
    if(checkApiStatus(response)){
      this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      this.getEolScenariosApi(props)
    }
    else{
      this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }
  })
}
export function engineUsageCalFn(props = {}, data = {}, engine_no) {
  let validationInputs = {}
  let errorObj = {}
  validationInputs = intermediateEngineValidationFn(data)
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
    if (data.edited_sv_card) {
      this.setState({ editedSvCardFlag: true })
    } else {
      this.engineUsageCalApi(props, data, engine_no)
    }
  } else {
    this.setState({ genericError: validationInputs })
  }
}
export function updateEngineTypeApi(data, props, engine_no, setModal){
  let payload =Object.assign({},data)
  payload = {
    ...payload,
    log_id: props.match.params.id,
    engine_no: engine_no,
  }
  delete payload['workscopes']
  this.setState({ pageLoader: true });
  globalPostService(`new-mr-calculator/engine-usage/`, payload)
  .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        if(setModal){
          setModal(false)
        }
        this.getMrLogDetailApi(this.props, this.state.assemblyIndex);
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        this.setState({ enginesEdit: false, genericError: {} })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
export function engineUsageCalApi(props = {}, data = {}, engine_no, low = "", lowKey = "", shop_visit) {
  let payload = Object.assign({}, data);
  delete payload['workscopes']
  payload = {
    ...payload,
    log_id: props.match.params.id,
    engine_no: engine_no,
    tsn: data && data.tsn ? data.tsn : 0,
    csn: data && data.csn ? data.csn : 0,
    tsn_at_lsv: data && data.tsn_at_lsv ? data.tsn_at_lsv : 0,
    csn_at_lsv: data && data.csn_at_lsv ? data.csn_at_lsv : 0,
    timeAndCycleStatus: false,
    rest_days: data.rest_days ? parseInt(data.rest_days) : null,
  }
  if (lowKey == 'edited_lifeOfWings') {
    payload = {
      ...payload,
      edit_low: { shop_visit, low: parseFloat(low) }
    }
  }
  if (data.lsv_date || data.tsn_at_lsv || data.csn_at_lsv) {
    payload = {
      ...payload,
      timeAndCycleStatus: true
    }
  }
  this.setState({ pageLoader: true });
  globalPostService(`new-mr-calculator/engine-usage/`, payload)
    .then(response => {
      if (checkApiStatus(response)) {
        this.getMrLogDetailApi(this.props, this.state.assemblyIndex);
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        this.setState({ enginesEdit: false, genericError: {} })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
      this.setState({ pageLoader: false });
    })
}
export function updateLLpCycleLimitApi(props = {}, data = {}, engine_no, customizeLLP, check) {
  let payload = Object.assign({}, data);
  delete payload['workscopes']
  payload = {
    ...payload,
    log_id: props.match.params.id,
    engine_no: engine_no,
    tsn: data && data.tsn ? data.tsn : 0,
    csn: data && data.csn ? data.csn : 0,
    tsn_at_lsv: data && data.tsn_at_lsv ? data.tsn_at_lsv : 0,
    csn_at_lsv: data && data.csn_at_lsv ? data.csn_at_lsv : 0,
    timeAndCycleStatus: false,
    llpLimits: customizeLLP,
    engineLLPStatus: check.every(item => item == false ? false : true)
  }
  if (data.lsv_date || data.tsn_at_lsv || data.csn_at_lsv) {
    payload = {
      ...payload,
      timeAndCycleStatus: true
    }
  }
  this.setState({ pageLoader: true });
  globalPostService(`new-mr-calculator/engine-usage/?timestamp=${new Date().getTime()}`, payload)
    .then(response => {
      if (checkApiStatus(response)) {
        this.getMrLogDetailApi(this.props, this.state.assemblyIndex);
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        this.setState({ enginesEdit: false, genericError: {} })
        trackActivity(`LLP Updated`, { page_title: 'Intermediate', log_id: props.match.params.id })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
      this.setState({ pageLoader: false });
    })
}

export function updateLlpMrSplitApi(props={}, data){
  return new Promise(function(resolve){
    globalPostService(`new-mr-calculator/engine-usage-sv/`,data)
    .then(response => {
      resolve(response);
    })
  })
}

export function updateMrRateShopVisitApi(props = {}, data = {}, engine_no, mr = "", mrKey = "", shop_visit, name, type) {
  let payload = Object.assign({}, data)
  payload = {
    ...payload,
    log_id: props.match.params.id,
    engine_no: engine_no,
    tsn: data && data.tsn ? data.tsn : 0,
    csn: data && data.csn ? data.csn : 0,
    tsn_at_lsv: data && data.tsn_at_lsv ? data.tsn_at_lsv : 0,
    csn_at_lsv: data && data.csn_at_lsv ? data.csn_at_lsv : 0,
    sv_update: true
  }
  if (mrKey == 'edited_engine_mr') {
    payload = {
      ...payload,
      prsv_mr: { shop_visit, mr: parseFloat(mr) }
    }
  }
  if (mrKey == "edited_llp_bulk_mr") {
    payload = {
      ...payload,
      llp_mr: { shop_visit, mr: parseFloat(mr) }
    }
  }
  this.setState({ pageLoader: true });
  globalPostService(`new-mr-calculator/engine-usage-sv/`, payload)
    .then(response => {
      if (checkApiStatus(response)) {
        this.getMrLogDetailApi(this.props, this.state.assemblyIndex);
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        this.setState({ enginesEdit: false })
        trackActivity(`${type} MR Rate of ${name} Run Updated`, { page_title: 'Intermediate', log_id: props.match.params.id })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        this.getMrLogDetailApi(this.props, this.state.assemblyIndex)
      }
      this.setState({ pageLoader: false });
    })
}
export function changeWorkScopeApi(props = {}, data = {}, type, selected, run, workscopeInfo) {
  this.setState({ pageLoader: true });
  this.updateWorkscopeFn(props = {}, data, workscopeInfo, type, selected )
  trackActivity(`${selected ? 'Selected' : 'UnSelected'} ${type} in ${run} Run`, { page_title: 'Intermediate', log_id: data.log_id })
}
export function updateWorkscopeFn(props = {}, query = {}, workScopeInfo, type, selected) {
  globalGetService(`new-mr-calculator/workscope/llps/`, query)
    .then(response => {
      if (checkApiStatus(response)) {
        let workscopeData = {}
        workscopeData = {
          ...workscopeData,
          llps:response.data.data.map(item => ({
            ...item,
            replaced: item.module.includes(type) ? selected ?  1 : 0 : item.replaced ,
            csn:item.max_limit-item.cycles_remaining,
            cycles_remaining_in:item.remaining_on_new ? item.remaining_on_new : item.cycles_remaining ,
            cycles_remaining_out: item.llp_build_goal ? item.llp_build_goal :  item.cycles_remaining && item.remaining_on_new ?  item.cycles_remaining == item.remaining_on_new ? item.max_limit : item.cycles_remaining : item.max_limit,
            csn_out: item.cycles_remaining && item.remaining_on_new ? item.cycles_remaining == item.remaining_on_new ? parseInt(item.max_limit - item.max_limit) : parseInt(item.max_limit - item.cycles_remaining) : parseInt(item.max_limit - item.max_limit)  ,
            csn_in: item.max_limit - item.cycles_remaining_in,
            cycles_for_next_run:workScopeInfo.cycles_for_next_run ? workScopeInfo.cycles_for_next_run : workScopeInfo.life_limiter,
            edited_list_price: item.edited_list_price ? item.edited_list_price : item.price
          })),
          cost_of_year:response.data.cost_of_years,
          query:query
        }
        replacedTheLLPSelectedApi(props,{
          shop_visit_no: query.shop_visit_no,
          engine_no:query.engine_no,
          log_id:query.log_id,
          current_replaced_llps: workscopeData.llps.filter(llp => llp.replaced == 1).map(item => ({ ...item, cycles_remaining: item.cycles_remaining_out ? parseInt(item.cycles_remaining_out) : parseInt(item.cycles_remaining) }))
        }).then(response => {
          if(checkApiStatus(response)){
            this.getMrLogDetailApi(this.props, this.state.assemblyIndex)
            // window.location.reload()
          }
        })
        }
    })
}
export function carryOverNSVApi(props = {}, data = {}, name, type) {
  this.setState({ pageLoader: true });
  globalPostService(`mr-calculator/workscope/fund-carry-forward/`, data)
    .then(response => {
      if (checkApiStatus(response)) {
        this.getMrLogDetailApi(this.props, this.state.assemblyIndex);
        this.setState({
          workScopeInfo: {
            modal: false,
            engine_no: '',
            data: {}
          }
        });
        trackActivity(`${type} Carry Over NSV of ${name} Run ${data.carry_forward_llp_fund_status ? 'Selected' : 'UnSelected'}`, { page_title: 'Intermediate', log_id: props.match.params.id })
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
      this.setState({ pageLoader: false });
    })
}
export function selectLLPToReplaceApi(props = {}, query = {}, workScopeInfo) {
  this.setState({ pageLoader: true });
  globalGetService(`new-mr-calculator/workscope/llps/`, query)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          workScopeLLPInfo: {
            ...prevState.workScopeLLPInfo,
            modal: true,
            cyclesRemaining: response.data.data.map(item => item.cycles_remaining),
            llps:response.data.data.sort(((a,b) => (a.module_sort > b.module_sort) ? 1 : ((b.module_sort > a.module_sort) ? -1 : 0))).map((item) => {return {...item,
              csn:item.max_limit-item.cycles_remaining,
              cycles_remaining_in:item.remaining_on_new ? item.remaining_on_new : item.cycles_remaining ,
              cycles_remaining_out:
              item.cycles_remaining &&  item.remaining_on_new &&
                item.cycles_remaining == item.remaining_on_new ?
                  item.llp_build_goal ? item.llp_build_goal
                  : item.max_limit  : item.cycles_remaining ,
              csn_out: item.cycles_remaining && item.remaining_on_new ? item.cycles_remaining == item.remaining_on_new ? parseInt(item.max_limit - item.max_limit) : parseInt(item.max_limit - item.cycles_remaining) : parseInt(item.max_limit - item.max_limit)  ,
              csn_in: item.max_limit - item.cycles_remaining_in,
              cycles_for_next_run:workScopeInfo.cycles_for_next_run ? workScopeInfo.cycles_for_next_run : workScopeInfo.life_limiter,
              module_sort: item.module  == "Fan" ? "Fan" : item.module == "LPT" ?  'LPT' : item.module == "Core" ? item.part_name.includes('HPT') ? "HPT" : "HPC" :'',
              edited_list_price: item.edited_list_price ? item.edited_list_price : item.price,
              edited_max_limit: item.edited_max_limit ? item.edited_max_limit : item.max_limit
            }}),
            cost_of_year:response.data.cost_of_years,
            query:query
          }
        }))
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }

    })
}
export function replacedTheLLPSelectedApi(props = {}, data = {}) {
  return new Promise(
    function (resolve, reject) {
      globalPostService(`new-mr-calculator/workscope/new-llps-update/`, data)
        .then(response => {
          resolve(response)
        })
    }
  )
}
export function customCycleRemainApi(props = {}, engineTypeId, engineNo, mrLogDetail) {
  this.setState({ pageLoader: true });
  globalGetService(`mr-calculator/engine-type/${engineTypeId}/llps/?log_id=${props.match.params.id}&engine_no=${engineNo}`)
    .then(response => {
      if (checkApiStatus(response)) {
        trackActivity(`LLP Viewed`, { page_title: 'Intermediate', log_id: props.match.params.id })
        let engineCSN = parseInt(mrLogDetail.engines[engineNo].csn);
        let engineCsnAtLsv = parseInt(mrLogDetail.engines[engineNo].csn_at_lsv)
        let ids = (mrLogDetail.engines[engineNo].llpLimits ? mrLogDetail.engines[engineNo].llpLimits.map(llpLimit => llpLimit.id) : []);
        let resObj = Object.assign({}, response.data.data);
        let llpCustom = Object.keys(resObj).map(key => {
          if (ids.includes(parseInt(key))) {
            let llpEdit = mrLogDetail.engines[engineNo].llpLimits.filter(llpLimit => llpLimit.id == key)[0];
            if (llpEdit.editCycles) {
              return llpEdit = { ...llpEdit, max_limit: parseInt(llpEdit.max_limit), remaining: parseInt(llpEdit.remaining), csn: parseInt(llpEdit.max_limit) - parseInt(llpEdit.remaining), module_sort: llpEdit.module == "Fan" ? "Fan" : llpEdit.module == "LPT" ? 'LPT' : llpEdit.module == "Core" ? llpEdit.part_name.includes('HPT') ? "HPT" : "HPC" : '' }
            } else {
              llpEdit = {
                ...llpEdit,
                remaining: llpEdit.remaining - (engineCsnAtLsv ? parseInt(engineCSN - engineCsnAtLsv) : regex.test(engineCSN) ? engineCSN : 0),
                max_limit: parseInt(llpEdit.max_limit),
                csn: parseInt(llpEdit.max_limit) - parseInt(llpEdit.remaining - (engineCsnAtLsv ? parseInt(engineCSN - engineCsnAtLsv) : regex.test(engineCSN) ? engineCSN : 0)),
                module_sort: llpEdit.module == "Fan" ? "Fan" : llpEdit.module == "LPT" ? 'LPT' : llpEdit.module == "Core" ? llpEdit.part_name.includes('HPT') ? "HPT" : "HPC" : ''
              }
              return llpEdit;
            }
          } else {
            let customCsn = Object.assign({}, resObj[key]);
            customCsn = {
              ...customCsn,
              remaining: customCsn['remaining'] - (engineCsnAtLsv ? parseInt(engineCSN - engineCsnAtLsv) : regex.test(engineCSN) ? engineCSN : 0),
              csn: parseInt(customCsn.max_limit) - parseInt(customCsn['remaining'] - (engineCsnAtLsv ? parseInt(engineCSN - engineCsnAtLsv) : regex.test(engineCSN) ? engineCSN : 0)),
              module_sort: customCsn.module == "Fan" ? "Fan" : customCsn.module == "LPT" ? 'LPT' : customCsn.module == "Core" ? customCsn.part_name.includes('HPT') ? "HPT" : "HPC" : ''
            }
            return customCsn
          }
        });
        llpCustom = llpCustom.reduce((obj, key) => {
          obj[key.id] = key;
          return obj;
        }, {});
        this.setState({
          modal: true,
          engineNo: engineNo,
          customCycleRemain: llpCustom,
          originalList: response.data.data
        })
      } else {
        // this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
      this.setState({ pageLoader: false });
    })
}
export function cancelUsageUpdateApi(props = {}, assemblyType) {
  this.setState({ pageLoader: true });
  let assemblyEditFlag = assemblyType + 'Edit'
  globalGetService(`new-mr-calculator/log/${props.match.params.id}/`)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          mrLogDetail: {
            ...prevState.mrLogDetail,
            [assemblyType]: response.data.data[assemblyType]
          },
          [assemblyEditFlag]: false
        }))
      }
    })
}
export function airframeUpdateUsageApi(props, data, mrLog) {
  let validationInputs = {}
  let errorObj = {}, otherCostError = false;
  let payload = Object.assign({}, data)

  Object.keys(data).map(label => {
    validationInputs = {
      ...validationInputs,
      [label]: intermediateFormValidationFn(data[label])
    }
    payload = {
      ...payload,
      [label]: {
        ...payload[label],
        currentFundValue: payload[label].currentFundValue ? payload[label].currentFundValue : '0',
        rest_days: payload[label].rest_days ? parseInt(payload[label].rest_days) : null,

      }
    }
    if(data[label].include_other_costs){
      payload = {
        ...payload,
        [label]: {
          ...payload[label],
          other_cost_details: payload[label].other_cost_details.map((item) => {return {...item, cost: Number(item.cost)} })
        }
      }
    }
    if (!data[label].nextShopvisitDate) {
      payload = {
        ...payload,
        [label]: {
          ...payload[label],
          nsv_update: false,
        }
      }
    }
  })
  if (Object.keys(removeEmptyKey(validationInputs)).every((k) => { return removeEmptyKey(validationInputs)[k] === "" })) {
    this.setState({ pageLoader: true });
    globalPostService(`new-mr-calculator/aircraft-usage/airframe/`, { airframe: payload, generalInfo: mrLog.generalInfo, log_id: this.props.match.params.id })
      .then(response => {
        this.setState({ pageLoader: false });
        if (checkApiStatus(response)) {
          this.setState({ airframeEdit: false });
          this.getMrLogDetailApi(props, this.state.assemblyIndex)
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        }
      })
  } else {
    this.setState({
      airEngineError: validationInputs
    });
    this.props.enqueueSnackbar('Please enter the mandatory highlighted fields', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
  }
}
export function apuUpdateUsageFn(props, data) {
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    fundDate: genericErrorCode['fundDate'][fieldValidation({ ...genericErrorCode['fundDateObj'], fieldval: data.fundDate })],
    actualMR: genericErrorCode['actualMR'][fieldValidation({ ...genericErrorCode['actualMRObj'], fieldval: data.actualMR })],
  }
  // if( data.tsn_at_lsv){
  //   validationInputs = {
  //     ...validationInputs,
  //     tsn_at_lsv:genericErrorCode['tsn_at_lsv'][fieldValidation({...genericErrorCode['tsn_at_lsvObj'], fieldval:data.tsn_at_lsv})],
  //   }
  // }
  if (data.lastShopvisitDate) {
    validationInputs = {
      ...validationInputs,
      lastShopvisitDate: genericErrorCode['lastShopvisitDate'][fieldValidation({ ...genericErrorCode['lastShopvisitDateObj'], fieldval: data.lastShopvisitDate })],
      tsn_at_lsv: genericErrorCode['tsn_at_lsv'][fieldValidation({ ...genericErrorCode['tsn_at_lsvObj'], fieldval: data.tsn_at_lsv })],
    }
  }

  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
    if (data.lastShopvisitDate > data.fundDate) {
      this.setState({ apuInfoPopup: true })
    } else {
      this.apuUpdateUsageApi(props, data)
    }
  } else {
    this.setState({
      genericError: validationInputs
    })
  }
}
export function apuUpdateUsageApi(props = {}, data = {}) {
  let payload = Object.assign({}, data)
  payload = {
    ...payload,
    utilizationType: 'tsn',
    dateOfLastOverhual: data && data.lastShopvisitDate ? data.lastShopvisitDate : null,
    rest_days: data?.rest_days ? parseInt(data.rest_days) : null,
    tsn_at_lsv: data && data.tsn_at_lsv ? data.tsn_at_lsv : 0,
    csn_at_lsv: data && data.csn_at_lsv ? data.csn_at_lsv : 0,
    currentFundValue: data && data.currentFundValue ? data.currentFundValue : 0,
    cost_escalation: data && data.cost_escalation ? data.cost_escalation : 0,
    escalation: data && data.escalation ? data.escalation : 0,
  }
  if (data.tsn_at_lsv || data.csn_at_lsv || data.lastShopvisitDate) {
    payload = {
      ...payload,
      utilizationType: 'dateOfLastOverhual'
    }
  }
  if (!data.nextShopvisitDate) {
    payload = {
      ...payload,
      nsv_update: false
    }
  }
  this.setState({ pageLoader: true });
  globalPostService(`new-mr-calculator/aircraft-usage/apu/`, { apu: payload, log_id: this.props.match.params.id })
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.setState({ apuEdit: false, apuInfoPopup: false });
        this.getMrLogDetailApi(props, this.state.assemblyIndex)
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })

}
export function lgUpdateUsageFn(props, data) {
  let validationInputs = data.map((item, index) => ({
    fundDate: genericErrorCode['fundDate'][fieldValidation({ ...genericErrorCode['fundDateObj'], fieldval: item.fundDate })],
    actualMR: genericErrorCode['actualMR'][fieldValidation({ ...genericErrorCode['actualMRObj'], fieldval: item.actualMR })],
    csn_at_lsv: item?.lastShopvisitDate ? genericErrorCode['csn_at_lsv'][fieldValidation({ ...genericErrorCode['csn_at_lsvObj'], fieldval: item.csn_at_lsv })] : '',
  }))
  let shopVisitCheckArr = []
  if (!validationInputs.map(lgItem => Object.keys(lgItem).every((k) => { return lgItem[k] === "" })).includes(false)) {
    data.map(item => (
      item.lastShopvisitDate > item.fundDate ? shopVisitCheckArr.push(true) : shopVisitCheckArr.push(false)
    ))
    if (shopVisitCheckArr.includes(true)) {
      this.setState({ lgInfoPopup: true })
    }
    else {
      this.lgUpdateUsageApi(props, data)
    }
  } else {
    this.setState({
      genericError: validationInputs
    })
  }
}
export function lgUpdateUsageApi(props, data) {
  let payload =  data
  payload= payload.map( (item, index)=>({
    ...item,
  utilizationType: data.lastShopvisitDate || data.tsn_at_lsv || data.csn_at_lsv ? 'dateOfLastOverhual' :'tsn',
  rest_days: item?.rest_days ? parseInt(item.rest_days) : null,
  dateOfLastOverhual: item && item.lastShopvisitDate ? item.lastShopvisitDate : null,
  tsn_at_lsv: item && item.tsn_at_lsv ? item.tsn_at_lsv : 0,
  csn_at_lsv: item && item.csn_at_lsv ? item.csn_at_lsv : 0,
  currentFundValue: item && item.currentFundValue ? item.currentFundValue : 0,
  cost_escalation: item && item.cost_escalation ? item.cost_escalation : 0,
  escalation: item && item.escalation ? item.escalation : 0,
  nsv_update: !data.nextShopvisitDate ? false : null
}))
  this.setState({ pageLoader: true });
  globalPostService(`new-mr-calculator/aircraft-usage/landing_gear/`, { landingGear: payload, log_id: this.props.match.params.id })
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.setState({ landingGearEdit: false, lgInfoPopup: false });
        this.getMrLogDetailApi(props, this.state.assemblyIndex)
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}


export function exportLLPListApi(props = {}, query = {}, name) {
  this.setState({ pageLoader: true });
  globalExportService(`new-mr-calculator/workscope/llps/`, query)
    .then(response => {
      this.props.enqueueSnackbar('LLP List exported successfully', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      trackActivity(`LLPs Exported`, { page_title: 'Intermediate', log_id: props.match.params.id })
      downloadFileType(response.data, (`LLPs_${moment().format('YYYY-MM-DD')}`), query.download);
      this.setState({ pageLoader: false });
    })
}
export function getCustomVariableApi(params = {}, query = {}) {
  globalGetService(`new-mr-calculator/log/${params.id}/variable-conditions/`, query)
    .then(response => {
      if (checkApiStatus(response)) {

      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
export function getMrUsageInfoApi(params = {}, query = {}) {
  globalGetService(`console/license/mr/usage/`, query)
    .then(response => {
      if (checkApiStatus(response)) {

      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
// Graph api
export function mrFinanceGraphApi(props = {}, assemblyType) {
  this.setState({ pageLoader: true });
  globalGetService(`new-mr-calculator/plot-mr-projection/`, { log_id: props.match.params.id })
    .then(response => {
      if (checkApiStatus(response)) {
        let result=response.data.data;
        let address = '', assembly='';
        if (result.engines) {
          this.getOutputDerateApi(result?.engines)
          if (result.location && result.location.address) {
            address = response.data.data.location.address
            this.setState({ regionDetails: result.location })
          } else {
            address = result.engines.general_info.region.name
            this.setState({ regionDetails: result.engines.general_info.region })
          }
        }
        if(assemblyType){
          assembly = assemblyType;
        }else{
          if(result.airframe){
            assembly = 'airframe';
          }else if(result.engines){
            assembly = 'engines';
          }else if(result.apu){
            assembly = 'apu';
          }else{
            assembly = 'landing_gear';
          }
        }
        
        this.setState({
          financeRptInfo: result,
          copyfinanceRptInfo: result,
          address: address,
          assemblyIndex:assembly
        });
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
      this.setState({ pageLoader: false });
    })
}
export function mrFinanceLifeOfWingsApi(props = {}, query = {}) {
  globalGetService(`new-mr-calculator/plot-engine-life-on-wing/${props.match.params.id}/`, query)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({ lifeOnWings: Object.keys(response.data.data).length ? response.data.data : {} })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
export function generateSimulateResultApi(props, data) {
  const { address } = this.state
  let payload = Object.assign({}, data)
  let financeError = {}, apuErrorFlag = false, lgErrorFlag = false, airframeErrorFlag = false, engineErrorFlag = false;
  // airframe validation
  if (payload.airframe) {
    let error = [], mrError = []
    Object.keys(payload.airframe).map(label => {
      payload = {
        ...payload,
        airframe: {
          ...payload.airframe,
          [label]: {
            ...payload.airframe[label],
            escalation_percentage: payload && payload.airframe && payload.airframe[label] && payload.airframe[label].escalation_percentage ? payload.airframe[label].escalation_percentage : 0,
            cost_escalation: payload && payload.airframe && payload.airframe[label] && payload.airframe[label].cost_escalation ? payload.airframe[label].cost_escalation : 0
          }
        }
      }
      let errorInfo = financialPlotsValidation(payload.airframe[label])
      financeError = {
        ...financeError,
        airframe: {
          ...financeError.airframe,
          [label]: errorInfo
        }
      }
      if (errorInfo.events && Object.keys(errorInfo.events).length || errorInfo.mr !== "") {
        Object.keys(errorInfo.events).map(label => {
          if (Object.keys(removeEmptyKey(errorInfo.events[label])).length) {
            error.push(true);
          } else {
            error.push(false)
          }
        });
        if (errorInfo.mr !== '') {
          mrError.push(true)
        }
      }
    });
    if (error.includes(true) || mrError.includes(true)) {
      airframeErrorFlag = true
    }
  }
  // engines validation
  if (payload.engines) {
    let engineMrError = [], llpMrError = [], shopVisitError = [], generalInfoError = [];
    Object.keys(payload.engines.engine_data).map(label => {
      payload = {
        ...payload,
        engines: {
          ...payload.engines,
          engine_data: {
            ...payload.engines.engine_data,
            [label]: {
              ...payload.engines.engine_data[label],
              llp_cost_escalation: payload && payload.engines && payload.engines.engine_data && payload.engines.engine_data[label] && payload.engines.engine_data[label].llp_cost_escalation ? payload.engines.engine_data[label].llp_cost_escalation : 0,
              llp_escalation: payload && payload.engines && payload.engines.engine_data && payload.engines.engine_data[label] && payload.engines.engine_data[label].llp_escalation ? payload.engines.engine_data[label].llp_escalation : 0,
              engine_escalation: payload && payload.engines && payload.engines.engine_data && payload.engines.engine_data[label] && payload.engines.engine_data[label].engine_escalation ? payload.engines.engine_data[label].engine_escalation : 0,
              prsv_cost_escalation: payload && payload.engines && payload.engines.engine_data && payload.engines.engine_data[label] && payload.engines.engine_data[label].prsv_cost_escalation ? payload.engines.engine_data[label].prsv_cost_escalation : 0,
            }
          }
        }
      }
      if (payload && payload.engines && payload.engines.engine_data && payload.engines.engine_data[label].shop_visits) {
        Object.keys(payload.engines.engine_data[label].shop_visits).map(secondaryChild => {
          if (payload && payload.engines && payload.engines.engine_data && payload.engines.engine_data[label].shop_visits && payload.engines.engine_data[label].shop_visits) {
            Object.keys(payload.engines.engine_data[label].shop_visits[secondaryChild]).map(tertiaryChild => {
              payload = {
                ...payload,
                engines: {
                  ...payload.engines,
                  engine_data: {
                    ...payload.engines.engine_data,
                    [label]: {
                      ...payload.engines.engine_data[label],
                      shop_visits: {
                        ...payload.engines.engine_data[label].shop_visits,
                        [secondaryChild]: {
                          ...payload.engines.engine_data[label].shop_visits[secondaryChild],
                          [tertiaryChild]: {
                            ...payload.engines.engine_data[label].shop_visits[secondaryChild][tertiaryChild],
                            eventCost: payload.engines.engine_data[label].shop_visits[secondaryChild][tertiaryChild].eventCost ? payload.engines.engine_data[label].shop_visits[secondaryChild][tertiaryChild].eventCost : 0
                          }

                        }
                      }
                    }
                  }
                }
              }
            })
          }
        })
      }
      let errorInfo = financialPlotsEngineValidation(payload.engines.engine_data[label])
      financeError = {
        ...financeError,
        engines: {
          ...financeError.engines,
          [label]: errorInfo,
        },
        general_info: {
          ...financeError.general_info,
          cycle_utilization: financialPlotsEngineErrorCode['cycle_utilization'][fieldValidation({ ...financialPlotsEngineErrorCode['cycle_utilizationObj'], fieldval: data.engines.general_info.cycle_utilization })],
          hourly_utilization: financialPlotsEngineErrorCode['hourly_utilization'][fieldValidation({ ...financialPlotsEngineErrorCode['hourly_utilizationObj'], fieldval: data.engines.general_info.hourly_utilization })],
          utilization_ratio: data.engines.general_info.utilization_ratio > data.engines.general_info.utilization_max_ratio || data.engines.general_info.utilization_ratio < data.engines.general_info.utilization_min_ratio ? 'please enter valid utilization ratio' : '',
          regionOfOperation: address ? '' : 'Please enter the Region of Operation'

        }
      }
      if (errorInfo.shop_visits && Object.keys(errorInfo.shop_visits).length || errorInfo.engine_mr !== "" || errorInfo.llp_mr !== "") {
        Object.keys(errorInfo.shop_visits).map(label => {
          Object.keys(errorInfo.shop_visits[label]).map(labelChild => {
            if (Object.keys(removeEmptyKey(errorInfo.shop_visits[label][labelChild])).length) {
              shopVisitError.push(true)
            } else {
              shopVisitError.push(false)
            }
          })
        })
        if (errorInfo.engine_mr !== '') {
          engineMrError.push(true)
        } else {
          engineMrError.push(false)
        }
        if (errorInfo.llp_mr !== "") {
          llpMrError.push(true)
        } else {
          llpMrError.push(false)
        }
      }
      if (financeError.general_info) {
        if (financeError.general_info.cycle_utilization !== "" || financeError.general_info.hourly_utilization !== "" || financeError.general_info.utilization_ratio || financeError.general_info.regionOfOperation) {
          generalInfoError.push(true)
        } else {
          generalInfoError.push(false)
        }
      }
      if (shopVisitError.includes(true) || engineMrError.includes(true) || llpMrError.includes(true) || generalInfoError.includes(true)) {
        engineErrorFlag = true
      }
    })
  }
  //apu validation
  if (payload.apu) {
    payload = {
      ...payload,
      apu: {
        ...payload.apu,
        cost_escalation: payload && payload.apu && payload.apu.cost_escalation ? payload.apu.cost_escalation : 0,
        escalation_percentage: payload && payload.apu && payload.apu.escalation_percentage ? payload.apu.escalation_percentage : 0,
      }
    }
    let errorInfo = financialPlotsValidation(payload.apu);
    financeError = {
      ...financeError,
      apu: errorInfo
    }
    if ((errorInfo.events && Object.keys(errorInfo.events).length) || errorInfo.mr != '') {
      let error = [];
      Object.keys(errorInfo.events).map(label => {
        if (Object.keys(removeEmptyKey(errorInfo.events[label])).length) {
          error.push(true);
        } else {
          error.push(false)
        }
      });
      if (error.includes(true) || errorInfo.mr != '') {
        apuErrorFlag = true;
      }
    }
  }
  // landing Gear validation
  if (payload.landing_gear) {
    payload = {
      ...payload,
      lg: {
        ...payload.lg,
        cost_escalation: payload && payload.lg && payload.lg.cost_escalation ? payload.lg.cost_escalation : 0,
        escalation_percentage: payload && payload.lg && payload.lg.escalation_percentage ? payload.lg.escalation_percentage : 0,
      }
    }
    let errorInfo = financialPlotsValidation(payload.landing_gear);
    financeError = {
      ...financeError,
      landing_gear: financialPlotsValidation(payload.landing_gear)
    }
    if ((errorInfo.events && Object.keys(errorInfo.events).length) || errorInfo.mr != '') {
      let error = [];
      Object.keys(errorInfo.events).map(label => {
        if (Object.keys(removeEmptyKey(errorInfo.events[label])).length) {
          error.push(true);
        } else {
          error.push(false)
        }
      });
      if (error.includes(true) || errorInfo.mr != '') {
        lgErrorFlag = true
      }
    }
  }
  if (apuErrorFlag || lgErrorFlag || airframeErrorFlag || engineErrorFlag) {
    this.setState({ financeError, apuErrorFlag, lgErrorFlag, airframeErrorFlag, engineErrorFlag });
  } else {
    this.setState({ pageLoader: true });
    globalPostService(`new-mr-calculator/simulate/${props.match.params.id}/`, payload)
      .then(response => {
        this.setState({ pageLoader: false, apuErrorFlag: false, lgErrorFlag: false, airframeErrorFlag: false, engineErrorFlag: false });
        if (checkApiStatus(response)) {
          this.setState({
            financeRptInfo: this.state.copyfinanceRptInfo,
            simulateFinanceRptInfo: response.data.data,
            simulationType: '3'
          });
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
  }
}

export function getCashflowCompactApi(props = {}, data = {}, loaderType){
  this.setState({ pageLoader: true });
  globalPostService(`new-mr-calculator/simulate/${props.match.params.id}/`, { ...data, monthly_cash_flow: true,  })
  .then(response => {
    this.setState({ pageLoader: false });
    if(checkApiStatus(response)){
      let resCv = JSON.parse(JSON.stringify(response.data.data.compact));
      resCv= resCv.sort((a, b) => parseInt(a.month) - parseInt(b.month)).slice(0, 40);
      this.setState({
        resAccDropdown: response.data.data.res_acc_drop_down.map(item => {return { label:item.reserve_account,value:item.reserve_account}}),
        yearDropdown: response.data.data.year_drop_down.map(item => {return { label:item.year.toString(),value:item.year}}),
        cashflowListCv: resCv,
        resultCopyCv: response.data.data.compact,
        originalCashflowListCv: response.data.data.compact,
        totalCountCv: response.data.data.compact.length,
        modal: true
      });
    }
    this.setState({ [loaderType]: false });
  })
}
export function getCashflowListApi(props = {}, data = {}, loaderType, filter = {}) {
  this.setState({ pageLoader: true });
  globalPostService(`new-mr-calculator/simulate/${props.match.params.id}/`, { ...data, monthly_cash_flow: true,  })
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        trackActivity('Monthly Cashflow Generated', { page_title: 'Analyze MR Cashflow', log_id: props.match.params.id })
        let res = JSON.parse(JSON.stringify(response.data.data.detailed));
        res = res.sort((a, b) => parseInt(a.month) - parseInt(b.month)).slice(0, 40);
        let mrAccounts = [], mrAccountsCv = [], years = [];
        let startYear = parseInt(response.data.data.detailed[0].month.slice(0, 4));
        let endYear = parseInt(response.data.data.detailed[response.data.data.detailed.length - 1].month.slice(0, 4));
        const kvArray = response.data.data.detailed.map(entry => {
          const key = ['name'].map(k => entry[k]).join('|');
          return [key, entry];
        });
        let arrs = response.data.data.detailed.map(item => item.month.slice(0, 4)).filter((item, i, ar) => ar.indexOf(item) === i).sort((a, b) => a - b).map(item => { return { label: item, value: item } });
        const map = new Map(kvArray);
        let filter = [];
        Array.from(map.values()).map(data => {
          mrAccounts.push({ label: data.name, value: data.name });
        });
        this.setState({
          cashflowList: res,
          resultCopy: response.data.data.detailed,
          originalCashflowList: response.data.data.detailed,
          totalCount: response.data.data.detailed.length,
          years: arrs,
          nameFilter: mrAccounts,
          modal: true
        });
      }
      this.setState({ [loaderType]: false });
    })
}
export function exportCashflowListApi(props = {}, data = {}, fileType = "", filter = {}, cmFilter = {}) {
  this.setState({ pageLoader: true });
  globalPostExportService(`new-mr-calculator/simulate/${props.match.params.id}/`, { ...data, download: true, monthly_cash_flow: true, year: filter.month, reserve_account: filter.name, reserve_account: filter.name, year: cmFilter.year ? cmFilter.year.value : null , reserve_account: cmFilter.reserve_account ? cmFilter.reserve_account.value : null })
    .then(response => {
      this.setState({ pageLoader: false });
      trackActivity('Monthly Cashflow Exported', { page_title: 'Analyze MR Cashflow', log_id: props.match.params.id })
      downloadFileType(response.data, (`Cashflow_LogID_${props.match.params.id}_${moment().format('YYYY-MM-DD')}`), fileType);
      this.props.enqueueSnackbar('Cashflow List exported successfully', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    })
}
export function deRateVariableApi(props = {}, query = {}) {
  this.setState({ pageLoader: true });
  globalGetService(`mr-calculator/variable-conditions/1/`)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({
          deRateList: response.data.data
        });
        trackActivity('LOI Table Viewed', { page_title: props.location.pathname.includes('financial-plots') ? 'Analyze MR Cashflow' : 'Intermediate', log_id: props.match.params.id })
      }
      this.setState({ pageLoader: false });
    })
}

export function loiMrApi(props = {}, query = {}) {
  this.setState({ pageLoader: true });
  const engineNos = Array.isArray(query.engine_no) ? query.engine_no : [query.engine_no];
  const promises = engineNos.map(engineNo => {
    return globalGetService(`new-mr-calculator/loi-mr/${props.log_id}/`, { engine_no: engineNo, recalculate :query.recalculate})
      .then(response => {
        if (checkApiStatus(response)) {
          return { [engineNo]: response.data.data }; 
        }
        return null; 
      })
    
      .catch(error => {
        console.error(error);
        return null;  
      });
  });
  Promise.all(promises)
    .then(results => {
      const filteredResults = results.filter(result => result !== null);
      const loiObject = Object.assign({}, ...filteredResults); 
      this.setState({
        loi: loiObject,
        pageLoader: false
      });
    })
    .catch(error => {
      console.error(error);
      this.setState({ pageLoader: false });
    });
}
 export function calculateDeRateApi(props = {}, data = {},engine_no,getResponseBack) {
   let payload = Object.assign({}, data);
   let validationInputs = {}
   validationInputs = {
     ...validationInputs,
     incRatio: deRateErrorCode['incRatio'][fieldValidation({ ...deRateErrorCode['incRatioObj'], fieldval: data.incRatio })],
    }
    if (!data.derates?.length) {
      validationInputs = {
        ...validationInputs,
        derates: 'Please select De-rate values for calculation'
      }
    }
    delete payload['incRatio'];
    payload = {
      ...payload,
      engine_no:engine_no.engine_no,
      derates: data.derates.sort(function (a, b) { return a - b })
    }
    if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
      if(data?.quick_mr){
        payload={
          ...payload,
          ratios: data?.incRatio?.map(item => {return item?.value})
        }
      }
      this.setState({ pageLoader: true });
     globalStaticPostService(`new-mr-calculator/create-loi-mr/${props.match.params.id}/`,payload,)
       .then(response => {
         if (checkApiStatus(response)) {
          if(data?.quick_mr === true){
            this.setState({quickMr:{data:response.data.data, flag:true}})
          }
           this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
           trackActivity('MR Rates Calculated in LOI', { page_title: props.location.pathname.includes('financial-plots') ? 'Analyze MR Cashflow' : 'Intermediate', log_id: props.match.params.id })
            getResponseBack()
         } else {
           this.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
         }
         this.setState({ pageLoader: false });      
       })
   } else {
     this.setState({
       error: validationInputs
     })
   }
 }

export function exportDeRateApi(props = {}, query = {}) {
  this.setState({ pageLoader: true });
  globalExportService(`new-mr-calculator/loi-mr/${props.match.params.id}/`, {engine_no:query.engine_no , download:true})
    .then(response => {
      this.setState({ pageLoader: false });
      trackActivity('MR Rates Exported in LOI', { page_title: props.location.pathname.includes('financial-plots') ? 'Analyze MR Cashflow' : 'Intermediate', log_id: props.match.params.id })
      downloadFileType(response?.data, (`LOI_Table_${props.match.params.id}_${moment().format('YYYY-MM-DD')}`), query.download);
      this.props.enqueueSnackbar('LOI Table exported successfully', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    })
}
export function exportGraphSummaryApi(params = {}, data = {}) {
  globalExportService(``, data)
    .then(response => {
      if (checkApiStatus(response)) {

      }
    })
}

export function intermediateFormValidationFn(data) {
  let validationInputs = {};
  validationInputs = {
    ...validationInputs,
    fundDate: genericErrorCode['fundDate'][fieldValidation({ ...genericErrorCode['fundDateObj'], fieldval: data.fundDate })],
    actualMR: genericErrorCode['actualMR'][fieldValidation({ ...genericErrorCode['actualMRObj'], fieldval: data.actualMR })],
    otherCostError:data.include_other_costs ? data.other_cost_details.filter(item=>item.include_in_mr).filter(item => !item.cost || !item.cost_type).length ? 'Please Enter Cost Type or Cost, which are included in MR':'': ''
  };
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
    return null
  } else {
    return validationInputs;
  }
}

export function intermediateEngineValidationFn(payload, tableType = "") {
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    hour_util: payload.hour_util <= 0 || payload.hour_util == "" || payload.hour_util == null || payload.hour_util == undefined ? 'Please enter Utilization' : '',
    fund_date: engineUsageErrorCode['fund_date'][fieldValidation({ ...engineUsageErrorCode['fund_dateObj'], fieldval: payload.fund_date })],
    llp_build_goal: payload.llp_build_goal && payload.llp_build_goal < 100 ? "Minimum build goal too low" : '',
    stub_life_limit: payload.stub_life_limit == "0" ? 'Please enter stub life limit greater than 0%'  :''
  }
  if (payload.lsv_date || payload.tsn_at_lsv || payload.csn_at_lsv) {
    validationInputs = {
      ...validationInputs,
      lsv_date: payload.lsv_date > payload.fund_date ? "Please enter Last Shop Visit Date less than Fund Date" : engineUsageErrorCode['lsv_date'][fieldValidation({ ...engineUsageErrorCode['lsv_dateObj'], fieldval: payload.lsv_date })],
      tsn_at_lsv: engineUsageErrorCode['tsn_at_lsv'][fieldValidation({ ...engineUsageErrorCode['tsn_at_lsvObj'], fieldval: payload.tsn_at_lsv })],
      csn_at_lsv: engineUsageErrorCode['csn_at_lsv'][fieldValidation({ ...engineUsageErrorCode['csn_at_lsvObj'], fieldval: payload.csn_at_lsv })],
      tsn: engineUsageErrorCode['tsn'][fieldValidation({ ...engineUsageErrorCode['tsnObj'], fieldval: payload.tsn })],
      csn: engineUsageErrorCode['csn'][fieldValidation({ ...engineUsageErrorCode['csnObj'], fieldval: payload.csn })],
    }
    if (payload.tsn_at_lsv && payload.tsn) {
      if (Number(payload.tsn_at_lsv) > Number(payload.tsn)) {
        validationInputs = {
          ...validationInputs,
          tsn_at_lsv: 'Entered TSN should be greater than TSN at Last SV'
        }
      }
    }

    if (payload.csn_at_lsv && payload.csn) {
      if (Number(payload.csn_at_lsv) > Number(payload.csn)) {
        validationInputs = {
          ...validationInputs,
          csn_at_lsv: 'Entered CSN should be greater than CSN at Last SV'
        }
      }
    }
  }
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
    return {}
  } else {
    return validationInputs
  }
}

export function validateUsageApi(props = {}) {
  this.setState({
    pageLoader: true
  })
  globalGetService(`new-mr-calculator/validate-usage/${props.match.params.id}/`)
    .then(response => {
      this.setState({
        pageLoader: false
      })
      if (checkApiStatus(response)) {
        props.history.push(`/financial-plots/${props.match.params.id}`)
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
export function workScopeLLpValidationFn(item, index) {
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    edited_list_price: errorCode['edited_list_price'][fieldValidation({ ...errorCode['edited_list_priceObj'], fieldval: item.edited_list_price })],
  }
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
    return ""
  } else {
    return validationInputs
  }
}

export function financialPlotsValidation(data) {
  let validationInputs = {}, eventErrorObj = {};
  validationInputs = {
    ...validationInputs,
    mr: financialPlotsErrorCode['mr'][fieldValidation({ ...financialPlotsErrorCode['mrObj'], fieldval: data.mr })],
  }
  Object.keys(data.events).map(label => {
    eventErrorObj = {
      ...eventErrorObj,
      [label]: {
        ...eventErrorObj[label],
        eventCost: financialPlotsErrorCode['eventCost'][fieldValidation({ ...financialPlotsErrorCode['eventCostObj'], fieldval: data.events[label].eventCost })],
        eventDate: financialPlotsErrorCode['eventDate'][fieldValidation({ ...financialPlotsErrorCode['eventDateObj'], fieldval: data.events[label].eventDate })]
      }
    }

    if (data.events) {
      validationInputs = {
        ...validationInputs,
        events: eventErrorObj
      }
    }
  })
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
    return null
  } else {
    return validationInputs
  }
}
export function financialPlotsEngineValidation(data) {
  let validationInputs = {}
  let shopVisitErrorObj = {}
  validationInputs = {
    ...validationInputs,
    engine_mr: financialPlotsEngineErrorCode['engine_mr'][fieldValidation({ ...financialPlotsEngineErrorCode['engine_mrObj'], fieldval: data.engine_mr })],
    llp_mr: financialPlotsEngineErrorCode['llp_mr'][fieldValidation({ ...financialPlotsEngineErrorCode['llp_mrObj'], fieldval: data.llp_mr })]
  }
  Object.keys(data.shop_visits).map(label => {
    Object.keys(data.shop_visits[label]).map(labelChild => {
      shopVisitErrorObj = {
        ...shopVisitErrorObj,
        [labelChild]: {
          ...shopVisitErrorObj[labelChild],
          mr: financialPlotsEngineErrorCode['mr'][fieldValidation({ ...financialPlotsEngineErrorCode['mrObj'], fieldval: data.shop_visits[label][labelChild].mr })],
          // eventCost:financialPlotsEngineErrorCode['eventCost'][fieldValidation({...financialPlotsEngineErrorCode['eventCostObj'], fieldval:data.shop_visits[label][labelChild].eventCost})],
        }
      }
    })
    if (data.shop_visits[label]) {
      validationInputs = {
        ...validationInputs,
        shop_visits: {
          ...validationInputs.shop_visits,
          [label]: shopVisitErrorObj
        }
      }
    }

  })
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
    return null
  } else {
    return validationInputs
  }
}
export function getEngineDerates (query){
  globalPostService(`console/regions_maps/`, query)
  .then(response =>{
          this.setState({deRates: response.data.data})
      })
}
export function getOutputDerateApi (query){
  globalPostService(`console/regions_maps/`, {variable:1 , engine_type: query?.general_info?.engine_type?.id , region_id:query?.general_info?.region?.id})
  .then(response =>{
          this.setState({outputDeRates: response.data.data})
      })
}
export function exportEolLogApi(props = {}, query = {}, name) {
  // this.setState({ pageLoader: true });
  globalExportService(`/new-mr-calculator/log/${props?.params?.id}/export`, {download:query})
  .then(response => {
      this.props.enqueueSnackbar('Eol log Exported successfully ', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      downloadFileType(response.data, (`EolLog_${moment().format('YYYY-MM-DD')}`), query);
      // this.setState({ pageLoader: false });
    })
}