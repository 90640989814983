import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Grid, TextField, Link, CircularProgress } from '@material-ui/core';
import {DropzoneArea} from 'material-ui-dropzone';
import { globalExportService, globalPostService } from '../../../utils/globalApiServices';
import { checkApiStatus, downloadFileType } from '../../../utils';
const ImportFleet = ({params, getResponseBack, moduleType, portfolio_slug}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [modal, setModal] = useState(false)
    const [files, setFiles] = useState([]);
    const handleDropzoneChange = (files) => {
        setFiles(files);
    }
    const getSampleTemplate = () => {
        globalExportService(`new-mr-calculator/sample-import-sheet/`)
        .then(response => {
            downloadFileType(response.data, 'Sample_Import_Report', 'xlsx');
        })
    }
    const onImportFleet = () => {
        setLoading(true)
        let formData = new FormData();
        formData.append('import_file', files[0]);
        formData.append('module_type', moduleType =='portfolio-list' ? 'portfolio':'fleet-level');
        if(moduleType === 'portfolio-list'){
            formData.append('portfolio_slug',portfolio_slug);
        }
        globalPostService('new-mr-calculator/fleet-portfolio-import/', formData)
        .then(response => {
            if(checkApiStatus(response)){
                setModal(false);setFiles([]);
                getResponseBack()
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
            setLoading(false)
        })
    }
    return(
        <>
            <Button color='primary' size='small' variant='contained' onClick={() => setModal(true)}>Import Assembly</Button>
            {modal ?
                <Dialog
                    open={modal}
                    onClose={() => setModal(false)}
                    className='console-management-modal'
                >
                    <DialogTitle id="scroll-dialog-title">
                        Import Assembly
                    </DialogTitle>
                    <DialogContent dividers={true}>
                    Refer the Sample Template - <Link component="button" variant="body2" onClick={getSampleTemplate}>Download</Link>
                    <div style={{width:'380px'}}>
                        <DropzoneArea
                            filesLimit={1}
                            maxFileSize={104857600}
                            showPreviewsInDropzone={false}
                            useChipsForPreview={true}
                            acceptedFiles={['.xlsx']}
                            showPreviews={ true }
                            dropzoneText={ <p style={{fontSize:'13px'}}>Drag & Drop Documents<br/> OR <br/> Click Here<br/> </p>}
                            dropzoneClass="drag-drop-cnt"
                            maxWidth="sm"
                            showAlerts={['info','error']}
                            alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                            clearOnUnmount={true}
                            onChange={handleDropzoneChange} 
                        />
                    </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={() => setModal(false)} color='primary' size='small' variant='outlined'>Cancel</Button>
                        {files.length ?
                            <Button disabled={isLoading} onClick={onImportFleet} color='primary' size='small' variant='contained'>
                                {isLoading ? <CircularProgress size={24}/>:'Save'}
                            </Button>:null
                        }
                    </DialogActions>
                </Dialog>:null
            }
        </>
    )
}
export default ImportFleet;