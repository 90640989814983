import { Button, Grid, Paper, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import CalanderViewCard from './CalanderViewCard'
import { Autocomplete, ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { ArrowBack, ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import { globalExportService } from '../../../utils/globalApiServices'
import { downloadFileType } from '../../../utils'
export default function CalanderView({calQuerry, calenderData, calViewMode, getCalanderData, calYearMenu, selectedYear, calPaginationdata, onCalanderItemClick }) {
    const [toggle, setToggle]= useState(true)
    useEffect(()=>{
        setToggle(false)
    },[])
    const exportCalanderView=()=>{
        globalExportService(`new-mr-calculator/fleet-calendar-view/`,{...calQuerry,download:'xlsx'})
        .then(response=>{
            downloadFileType(response.data, 'Calendar_export', 'xlsx')
        })
    }
    let callNextYear = calPaginationdata?.max_year >= calPaginationdata?.next_from_year ? calPaginationdata?.next_from_year : null
   let callPrevYear = calPaginationdata?.previous_to_year >=  calPaginationdata?.min_year ? calPaginationdata?.previous_to_year : null
    return (
        <div>
            <div>
                <div className='cal-view-heading'>Assets due for Shop Visits
                    <div style={{ float: 'right' }}>
                        <div style={{ display: "flex", alignItems: "center", height:'60px' }}>
                            <div style={{ width: "130px", marginRight:"10px", marginBottom:'3px' }}>
                                {
                                    calViewMode == 0 ?
                                        <Autocomplete
                                            options={calYearMenu}
                                            getOptionLabel={option => option.label}
                                            id="asset_type"
                                            disableClearable={true}
                                            value={calYearMenu.find(i => i.value == selectedYear.value)}
                                            onChange={(e, value) => { value ? getCalanderData({ month_view: value?.value }) : console.log('') }}
                                            renderInput={params => <TextField {...params} style={{margin:'0px'}} label="Year" placeholder="Select Year" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
                                        />
                                        : null}
                            </div>
                                <Button variant='outlined' size='small' color='primary' style={{marginRight:'10px'}} onClick={()=>exportCalanderView()}>
                                    Export
                                </Button>
                            <div>
                                <span className='cal-toggle-sw'>
                                    <span className={calViewMode == 0 ? 'active' : ''} onClick={() => getCalanderData({ month_view: selectedYear.value? selectedYear.value: calYearMenu[0].value })}>
                                        Monthly
                                    </span>
                                    <span className={calViewMode == 1 ? 'active' : ''} onClick={() => getCalanderData({ year_view: true })}>
                                        Yearly
                                    </span>
                                </span>
                            </div>
                            {
                                calViewMode == 1 ?
                                    <div style={{ width: "50px", marginLeft: "20px" }}>
                                        <ArrowBackIos style={{ cursor: 'pointer', fontSize:'17px', color: callPrevYear ? null :'#cfcfcf'}} onClick={() => callPrevYear? getCalanderData({ year_view: true, to_year:callPrevYear }) : console.log('')}  />
                                        <ArrowForwardIos style={{ cursor: 'pointer',  fontSize:'17px', color: callNextYear ? null :'#cfcfcf' }} onClick={() => callNextYear ? getCalanderData({ year_view: true, from_year:callNextYear }): console.log('')} />
                                    </div> :
                                    <div style={{ width: "50px", marginLeft: "20px" }}>
                                    <ArrowBackIos style={{color:'#cfcfcf',  fontSize:'17px'}}  />
                                    <ArrowForwardIos style={{color:'#cfcfcf',  fontSize:'17px'}} />
                                </div>   }
                        </div>
                    </div>
                </div>
            </div>
            <Grid container spacing={0}>
                {
                    calenderData?.list?.map(item =>
                        <CalanderViewCard item={item} calViewMode={calViewMode} getCalanderData={getCalanderData} onCalanderItemClick={onCalanderItemClick}/>
                    )
                }
            </Grid>
        </div>
    )
}
